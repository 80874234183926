import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobalContext } from '../AppContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { SERVER_URL, APP_NAME, IMAGE_URL } from '../App'
import MiniLoading from './utils/MiniLoading'
import { getProcessedDate } from '../functions'
import { BsDownload } from 'react-icons/bs'

export default function ClassRecords() {
    const { id } = useParams()
    if (id === undefined) window.history.back()

    const { appState } = useGlobalContext() //very IMPORTANT!!
    document.title = APP_NAME + " | View Examination Record"

    const [examination, setExamination] = useState("")
    const [examinations, setExaminations] = useState([])
    const [records, setRecords] = useState([])
    const [foundRecords, setFoundRecords] = useState([])
    const [subjects, setSubjects] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState("")
    const url = SERVER_URL + "records.php"

    const searchOnChange = (text) => {
        setQuery(text)
        search(text)
    }

    const search = (text) => {

        if (text === "" || records.length === 0) {// no need to search
            setFoundRecords(records)
            return
        }

        const foundRecords = records.filter((record) => {
            const name = record.firstname + " " + record.lastname
            return name.toLowerCase().includes(text.toLowerCase())
        })
        setFoundRecords(foundRecords)
    }

    const searchSubmit = (e) => {
        e.preventDefault()
        search(query)
    }

    const submit = (e) => {
        e.preventDefault()

        if (examination === "") {
            toast.error("Select examination", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //show loading spinner
        setIsLoading(true)
        axios.get(url, {
            params: {
                getExaminationRecord: examination,
                classId: id
            }
        })
            .then(function (response) {
                //hide loading spinner
                setIsLoading(false)
                if (response.data.response) {
                    let records = JSON.parse(response.data.records)
                    let subjects = JSON.parse(response.data.subjects)
                    setSubjects(subjects)

                    if (records.length === 0) {
                        setRecords([])
                        setFoundRecords([])
                        toast.info(`No examination record found`, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                        return
                    }
                    setRecords(records)
                    setFoundRecords(records)

                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsLoading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
    }

    //get examinations
    useEffect(() => {
        const url = SERVER_URL + "records.php"
        const fetchExams = () => {
            axios.get(url, {
                params: {
                    loadExaminations: "loadExaminations"
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let examinations = JSON.parse(response.data.examinations)
                        setExaminations(examinations)

                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve student and subject info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchExams()
    }, [id]);

    return (
        <div>
            <h1>{appState.classInfo.name} Exam Records</h1>
            <div className="divider"></div>

            <div className="">
                <form id='form' action="" onSubmit={submit}>
                    <fieldset>
                        <legend>Search Exam Records</legend>
                        <div className="grid">

                            <div className="form-div">
                                <label htmlFor="examination">Examination to Search</label>
                                <select id='examination' name='examination' value={examination} onChange={(e) => setExamination(e.target.value)} className='input' required
                                    title='Examination'>
                                    <option value=''>--Select Examination--</option>
                                    {examinations.map((item) => {
                                        const { id, name } = item
                                        return <option key={id} value={id}>{name} | {getProcessedDate(item.date)}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                                <button type='submit' className='submit'>Search Records</button>
                            </div>

                        </div>



                    </fieldset>

                </form>
            </div>

            <div className="content">

                {records.length > 0 &&
                    <>
                        <div className="top">
                            <form action="" className="" onSubmit={searchSubmit}>
                                <input type="search" className='input' placeholder='Search Students' value={query} onChange={e => searchOnChange(e.target.value)} />
                                <button type='submit' className='submit'>Search</button>
                            </form>

                            {records.length > 0 &&
                                <a href={`http://localhost/api/reports/records.php?cl=${id}&exam=${examination}`} target={'_blank'} rel={'noreferrer'}>
                                    <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
                                </a>
                            }

                        </div>

                        <div className="user_list">
                            <div className='flex'>
                                {/* <Link to={`/report/${id}/${examReportId}`}>
                            <button type='button' className='button'>Create Report</button>
                        </Link> */}

                            </div>

                            <table className=''>
                                <tbody>
                                    <tr className='smaller_ft'>
                                        <th></th>
                                        <th>Student</th>
                                        {subjects.map(subject => {
                                            let key = subject.subject_id
                                            return <th key={key}>{subject.name}</th>
                                        })}

                                    </tr>
                                    {foundRecords.map(record => {
                                        const { id, image, firstname, lastname, scores } = record
                                        let scoreTotal = 0

                                        return (
                                            <tr className='user_item' key={id}>
                                                <td className='image_cell'>
                                                    <img alt='student' className='user_image' src={IMAGE_URL + image} />
                                                </td>
                                                <td>{firstname} {lastname}</td>
                                                {scores.map((item, key) => {
                                                    scoreTotal += item.score * 1
                                                    return <td key={key} className='center'>{item.score}</td>
                                                })}
                                                <td className='bolder'>{scoreTotal}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>

                        </div>
                    </>
                }

            </div>

            {isLoading && <MiniLoading />}


        </div>
    )
}
