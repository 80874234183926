import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className='flex-body'>
      <h1>Page Not Found</h1>
      <h3>Sorry, the page you're looking for cannot be found</h3>
      <p>
        <Link to={"/"}>
            <button className='button'>Go to Dashboard</button>
        </Link>
      </p>
    </div>
  )
}
