import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import MiniLoading from '../utils/MiniLoading';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { SERVER_URL, APP_NAME } from '../../App';
import Student from '../Student';
import SubjectsModal from '../utils/SubjectsModal';
import { useGlobalContext } from '../../AppContext';
import { BsDownload } from 'react-icons/bs';

export default function ViewClass() {
  const { id } = useParams()
  if (id === undefined) window.history.back()

  const { appState, setClassStudents } = useGlobalContext() //very IMPORTANT!!

  const [query, setQuery] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [students, setStudents] = useState([])
  const [subjects, setSubjects] = useState([]) // all subjects
  const [class_subjects, setClassSubjects] = useState([]) // class subjects
  const [classId] = useState(id)
  const [className, setClassName] = useState("")
  const [teacher, setTeacher] = useState("")
  const [foundStudents, setfoundStudents] = useState([])// secondary students array, to help with search
  const [showAll, setShowAll] = useState(false)//

  const searchOnChange = (text) => {
    // console.log("on change ", text);
    setQuery(text)
    search(text)
  }

  const search = (text) => {
    // console.log("search submit "+text)

    if (text === "" || students.length === 0) {// no need to search
      setfoundStudents(students)
      return
    }

    const foundStudents = students.filter((student) => {
      const name = student.firstname + " " + student.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setfoundStudents(foundStudents)
  }

  const submit = (e) => {
    e.preventDefault()
    search(query)
  }

  const openModal = () => {
    document.getElementById("subjectModal").style.display = "block"
  }

  const changeClassStudents = () => {
    // console.log("change class students")
    setClassStudents(students)
  }

  const showChecked = (e) => {
    setShowAll(e.target.checked)
    if (students.length < 11) return

    if (e.target.checked) {
      setfoundStudents(students)
    } else {
      setfoundStudents(students.slice(0, 10))//only show 10
    }
  }

  useEffect(() => {
    // console.log(appState.classInfo)
    //if class info is not set, page has been reloaded. Go back
    if (appState.classInfo.id === "") {
      window.history.back()
      return
    }
    const url = SERVER_URL + "students.php"
    const fetchStudents = () => {
      axios.get(url, {
        params: {
          loadClassStudents: id,
          loadClassSubjects: id
        }
      })
        .then(function (response) {
          setIsLoading(false)
          if (response.data.response) {
            let students = JSON.parse(response.data.students)
            let class_subjects = JSON.parse(response.data.class_subjects)
            let subjects = JSON.parse(response.data.subjects)
            let teacher = JSON.parse(response.data.teacher)
            setTeacher(teacher)

            let subs = subjects.map(subject => {
              return { ...subject, checked: class_subjects.includes(subject.id) }
            })
            setSubjects(subs)
            setClassSubjects(class_subjects)

            // console.log(subjects) 
            setClassName(response.data.class_name)
            document.title = `${APP_NAME} | ${response.data.class_name}`
            if (students.length === 0) {
              setStudents([])
              setfoundStudents([])
              toast.info("No students in this class", {
                hideProgressBar: true,
                theme: "colored"
              })
              return
            }
            setStudents([...students])
            setfoundStudents([...students])

          } else {

          }

        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve students", {
            hideProgressBar: true,
            theme: "colored"
          })
        });

    }

    fetchStudents()
  }, [id, appState]);

  useEffect(() => {

    // console.log(appState.classInfo)

  }, [appState.classInfo]);

  return (
    <div>
      <h1>{className}</h1>
      <div className="divider"></div>

      <div className="top">
        <form action="" className="" onSubmit={submit}>
          <input type="search" className='input' placeholder='Search' value={query} onChange={e => searchOnChange(e.target.value)} />
          <button type='submit' className='submit'>Search</button>
        </form>

        {students.length > 0 &&
          <a href={`http://localhost/api/reports/students.php?cl=${id}`}>
            <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
          </a>
        }


        <div style={{ display: "flex", gap: "10px" }}>
          {students.length > 0 &&
            <>
              <Link to={`/classes/attendance/${id}`}>
                <button type='button' className='button set_atn_btn' onClick={changeClassStudents}>Set Attendance</button>
              </Link>
              <Link to={`/classes/view-attendance/${id}`}>
                <button type='button' className='button view_atn_btn' onClick={changeClassStudents}>View Attendance</button>
              </Link>
            </>
          }
          <Link to={`/classes/records/${id}`}>
            <button type='button' className='button'>Records</button>
          </Link>
          <button type='button' className='button' onClick={openModal}>Edit Subjects</button>
        </div>
      </div>

      <div className="content">

        {students.length > 0 &&
          <div className="user_list">

            <h4 className='grey'>
              {students.length} {students.length === 1 ? "Student" : "Students"} in Class
              <span className="smaller_ft"> (showing {foundStudents.length})</span> |
              <span> Class Teacher: {teacher}</span>
            </h4>
            <input type="checkbox" value={showAll} onChange={e => showChecked(e)} /> Show All

            <table className='user_list_table'>
              <tbody>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Date of Birth</th>
                  <th>Gender</th>
                  <th>Class</th>
                  <th>Date Enrolled</th>
                  <th>Actions</th>
                </tr>

                {foundStudents.map((student) => {
                  return <Student key={student.id} student={student} />
                })}
              </tbody>

            </table>
          </div>
        }


        {isLoading && <MiniLoading />}

      </div>

      <SubjectsModal
        values={{
          class_name: className,
          subjects,
          class_subjects,
          setClassSubjects,
          class_id: classId,
          setSubjects
        }} />
      <ToastContainer />

    </div>
  )
}
