import React, {useState, useEffect} from 'react'
import '../css/form.css'
import axios from 'axios'
import AlertDialog from './utils/AlertDialog' 
import { ToastContainer, toast } from 'react-toastify'
import Loading from './Loading'
import { SERVER_URL, APP_NAME } from '../App'
import MiniLoading from './utils/MiniLoading'
import { getProcessedDate2 } from '../functions'

export default function Exams() {
  document.title = APP_NAME+" | Examinations"

    const [inputs, setInputs] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [examType, setExamType] = useState([])
  const [examinations, setExaminations] = useState([])
  const [foundExaminations, setFoundExaminations] = useState([])
  const [query, setQuery] = useState("")
  const url = SERVER_URL+"examinations.php"

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(values => ({...values, [name]: value}))
    // console.log(name, value)
  }

  const searchOnChange = (text) => {
    // console.log("on change ", text);
     setQuery(text)
     search(text)
  }

  const search = (text) => {
    // console.log("search submit "+text)

    if(text === "" || examinations.length === 0){// no need to search
      setFoundExaminations(examinations.slice(0, 20)) // show 20 max
      return
    }

    const foundExaminations = examinations.filter((exam)=>{
      const name = exam.name
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setFoundExaminations(foundExaminations)
  }

  const searchSubmit = (e) => {
    e.preventDefault()
    search(query)
  }

  const submit = (e) => {
    e.preventDefault()

    if(!inputs.hasOwnProperty('examtype')){
        toast.error("Select an examtype",{
            hideProgressBar: true,
            theme: "colored"
        })
        return
    }

    //validate all inputs
    for ( var key in inputs ) {
        if(inputs[key] === ""){
            toast.error(key + " cannot be empty",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
    }

    //confirm examtype exam
    setAlertTitle("Confirm Exam")
    setAlertMessage("Are you sure you entered the right information for this exam?")
    setAlertOpen(true);
    //submit in alert handleclose
    

  }

  const reset = () => {
    setInputs({})
    document.getElementById('form').reset()
  }

  const handleClose = (option) => {
    //if there are multiple alert scenarios, will have to use another variable to determine action
    setAlertOpen(false);
    if(option){
      //show loading spinner
      setIsUploading(true)
      const formData = new FormData()
      for ( var key in inputs ) {
          formData.append(key, inputs[key]);
      }
    
      axios.post(url, formData)
        .then(function (response) {
          //hide loading spinner
          setIsUploading(false)
          if(response.data.response){
              reset()
              toast.success("New Exam Created",{
                  hideProgressBar: true,
                  theme: "colored"
              })
          }else{
              toast.error(response.data.message,{
                  hideProgressBar: true,
                  theme: "colored"
              })
          }
          
        })
        .catch(function (error) {
          //hide loading spinner
          setIsUploading(false)
          toast.error("An Error Occured",{
              hideProgressBar: true,
              theme: "colored"
          })
          console.log(error);
        });
    }
  };

  //fetch both exam types and current exams
  useEffect(() => {
    const fetchExamType = () => {
      axios.get(url, {
          params: {
            loadExamType: "loadExamType"
          }
        })
        .then(function (response) {
          
          if(response.data.response){ 
            let examType = JSON.parse(response.data.examTypes)
            setExamType(examType)
          }else{ 
              
          }
          
        })
        .catch(function (error) {
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve exam types",{
              hideProgressBar: true,
              theme: "colored"
          })
        });
      
    }

    fetchExamType()
    
  }, [url]);

  useEffect(() => {
    const fetchExaminations = () => {
        axios.get(url, {
            params: {
              loadExaminations: "loadExaminations"
            }
          })
          .then(function (response) {
            setIsLoading(false)
            if(response.data.response){ 
              let examinations = JSON.parse(response.data.examinations)
              setExaminations(examinations)
              setFoundExaminations(examinations)
            }else{ 
                
            }
            
          })
          .catch(function (error) {
              setIsLoading(false)
            console.log(error)
            toast.error("An Error Occured. Couldn't retrieve examinations",{
                hideProgressBar: true,
                theme: "colored"
            })
          });
        
    }
    fetchExaminations()
  }, [isUploading, url]);


  return (
    <div>
        <h1>Examinations</h1>
        <div className="divider"></div>

        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Create New Exam</legend>
                    <div className="grid">
                        
                        
                        <div className="form-div">
                            <label htmlFor="examtype">Exam Type</label>
                            <select id='examtype' name='examtype' value={inputs.examtype || ""} onChange={handleInputChange} className='input' required
                            title='Exam Type'>
                                <option value=''>--Select Exam Type--</option>
                                {examType.map((item) =>{
                                    const {id, name} = item
                                    return <option key={id} value={id}>{name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="examname">Exam Date <span className='mini'>(*starting date of exam)</span></label>
                            <input id='date' type="date" name='date' value={inputs.date || ""} onChange={handleInputChange} className='input' minLength={2} maxLength={25} required
                            placeholder='Exam Date' title='Exam Date'
                             />
                        </div>
                        <div className="form-div" style={{display: "flex", alignItems: 'flex-end'}}>
                            <button type='submit' className='submit'>Create Exam</button>
                        </div>
                        
                    </div>
    
                    

                </fieldset>
            
            </form>

            <form action="" className="" onSubmit={searchSubmit}>
              <input type="search" className='input' placeholder='Search' value={query} onChange={e => searchOnChange(e.target.value)} />
              <button type='submit' className='submit'>Search</button>
            </form>

            <div className="user_list">

                {examinations.length > 0 && <h4 className='grey'>{examinations.length} Total {examinations.length === 1 ? "Examination" : "Examinations"}</h4>}

                <table className='user_list_table'>
                    <tbody>
                    {examinations.length > 0 && 
                    <tr>
                        <th>Examination Type</th> 
                        <th>Examination Date</th> 
                    </tr>
                    } 
                    
                    {foundExaminations.map((examination) => {
                        const date = getProcessedDate2(examination.date)
                        return (
                            <tr className='user_item' key={examination.id}>
                                <td>{examination.name}</td> 
                                <td>{date}</td> 
                            </tr>
                        )
                    })}
                    </tbody>
                    
                </table>
            </div>

            {isLoading && <MiniLoading/>}

        </div>

        <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage}/>

        <ToastContainer />
        {isUploading && <Loading/>}
      
    </div>
  )
}
