import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { SERVER_URL, APP_NAME, IMAGE_URL, LOGO_URL } from '../App'
import MiniLoading from './utils/MiniLoading'
import { getProcessedDate, getAge } from '../functions'
import { useGlobalContext } from '../AppContext'

export default function Report() {
    const { id, examId } = useParams()
    if (id === undefined || examId === undefined) window.history.back()

    const { appState } = useGlobalContext() //very IMPORTANT!!

    document.title = APP_NAME + " | Reports"

    const [isLoading, setIsLoading] = useState(true)
    const [student, setStudent] = useState({})
    const [info, setInfo] = useState({})
    const [records, setRecords] = useState([])
    const [remarks, setRemarks] = useState("")
    const [examType, setExamType] = useState("")
    const [examDate, setExamDate] = useState("")



    const print = () => {
        if (remarks) saveRemarks()

        // console.log(window.document.head.innerHTML)
        var printwin = window.open("");
        printwin.addEventListener("load", function () {
            printwin.console.log("window loaded")
            printwin.print();
        })
        let script = `
        <title>Examination Report</title>
        <script>
            document.addEventListener("click", function(){
                printPage()
            })
            setTimeout(printPage, 500);
            function printPage(){
                // window.history.pushState("object or string", "Title", "http://localhost");
                window.print()
                window.close()
            }
        </script>
        `

        // printwin.document.head.innerHTML = window.document.head.innerHTML
        // console.log(printwin.document.head.innerHTML)
        printwin.document.write(window.document.head.innerHTML + " " + script + document.getElementById("print_page").innerHTML);
        printwin.document.title = "Examination Report"

    }

    const saveRemarks = () => {

        const formData = new FormData()
        formData.append("remarks", remarks)
        formData.append("examId", examId)//exam id
        formData.append("studentId", id)//student id

        const url = SERVER_URL + "report.php"
        axios.post(url, formData)
            .then(function (response) {

                if (response.data.response) {
                    // toast.success("Remarks Saved", {
                    //     hideProgressBar: true,
                    //     theme: "colored"
                    // })
                } else {
                    // toast.error(response.data.message, {
                    //     hideProgressBar: true,
                    //     theme: "colored"
                    // })
                }

            })
            .catch(function (error) {
                // toast.error("An Error Occured", {
                //     hideProgressBar: true,
                //     theme: "colored"
                // })
            });
    }

    //get student info on load, and examination records, (and remarks if any)
    useEffect(() => {
        const url = SERVER_URL + "report.php"
        const fetchStudentInfo = () => {
            axios.get(url, {
                params: {
                    loadStudentInfo: id,
                    getExaminationRecord: examId,
                    studentType: appState.studentType
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let students = JSON.parse(response.data.students)
                        let records = JSON.parse(response.data.records)
                        let exam_type = JSON.parse(response.data.exam_type)
                        let exam_date = JSON.parse(response.data.exam_date)
                        let info = JSON.parse(response.data.info)
                        let teacherRemarks = JSON.parse(response.data.teacherRemarks)

                        setStudent(students[0])
                        setInfo(info[0])
                        setRecords(records)
                        setExamType(exam_type)
                        setExamDate(exam_date)
                        setRemarks(teacherRemarks)

                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve student and subject info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchStudentInfo()
    }, [id, examId, appState.studentType]);

    // const styles = {
    //     report_page:{
    //         minWidth: "300px",
    //         maxWidth: "600px",
    //         backgroundColor: "white",
    //         margin: "auto",
    //         padding: "10px",
    //     }
    // }

    return (
        <div>
            <h1>Examination Report</h1>
            <div className="divider"></div>

            <div className="content">
                <div>
                    <label htmlFor="remarks">Class Teacher Remarks</label><br />
                    <textarea id='remarks' value={remarks} onChange={(e) => setRemarks(e.target.value)} className="input"
                        style={{ width: "50%" }} rows="2" placeholder='Class Teacher Remarks' maxLength={500}></textarea>
                </div>
                <button className='button' onClick={print}>Print</button>

                <div id="print_page">
                    <div id='report_page' className="report_page">
                        <div className="page_top">
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start' }}>
                                {info.logo && <img src={LOGO_URL + info.logo} width={100} height={100} alt="" />}
                            </div>
                            <div style={{ display: "flex", textAlign: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <h2>{info.name || "School"} Examination Report</h2>
                                <p className="grey smaller_ft">{info.address} | {info.phone} | {info.phone2}</p>
                            </div>
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                                {info.logo && <img src={LOGO_URL + info.logo} width={100} height={100} alt="" />}
                            </div>
                        </div>
                        <br />
                        <div className="page_body">

                            <div className="page_info">
                                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                    {student.image && <img src={IMAGE_URL + student.image} width={100} height={100} alt="" />}
                                </div>
                                <div style={{ display: "flex", justifyContent: 'flex-start', flexDirection: 'column' }}>
                                    <div>
                                        <p><span><b>Name:</b></span> {student.firstname} {student.lastname}</p>
                                        <p><span><b>Age:</b></span> <span>{getAge(student.dob)} yrs</span></p>
                                        <p><span><b>Gender:</b></span> <span>{student.gender}</span></p>
                                        <p><span><b>Date of Birth:</b></span> <span>{getProcessedDate(student.dob)}</span></p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'flex-start', flexDirection: 'column' }}>
                                    <div>
                                        <p><span><b>Class:</b></span> {student.class}</p>
                                        <p><span><b>Exam:</b></span> <span>{examType}</span></p>
                                        <p><span><b>Exam Date:</b></span> <span>{getProcessedDate(examDate)}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="divider"></div> <br />
                            <div className="page_subjects">
                                <table className=''>
                                    <tbody>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Marks</th>
                                            <th>Total (%)</th>
                                            <th>Grading</th>
                                        </tr>
                                        {/* <tr>
                                    <td>Mathematics</td>
                                    <td>94</td>
                                    <td>100</td>
                                    <td>Excellent</td>
                                </tr>
                                <tr>
                                    <td>Pre-Technical Skills</td>
                                    <td>94</td>
                                    <td>100</td>
                                    <td>Excellent</td>
                                </tr> */}
                                        {records.map(record => {
                                            const { id, name, score, total, grading } = record

                                            return (
                                                <tr key={id}>
                                                    <td>{name}</td>
                                                    <td>{score}</td>
                                                    <td>{total}</td>
                                                    <td>{grading}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="divider"></div><br />
                            <div className='page_footer'>
                                <div>
                                    <p><b>Class Teacher Remarks: </b></p>
                                    <p><q>{remarks}</q></p>
                                </div>
                                <br />
                                <div>
                                    <p><b>Class Teacher's Signature: </b></p>
                                </div>
                                <br />
                                <div>
                                    <p><b>Head Teacher's Signature: </b></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {isLoading && <MiniLoading />}
        </div>
    )
}
