import Sidebar from './components/Sidebar'
import Login from './components/Login'
import { useGlobalContext } from './AppContext'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Staff from './components/Staff'
import Students from './components/Students'
import Classes from './components/Classes'
import Dashboard from './components/Dashboard'
import NewStudent from './components/forms/NewStudent'
import NewClass from './components/forms/NewClass'
import NewStaff from './components/forms/NewStaff'
import ViewStudent from './components/views/ViewStudent'
import ViewStaff from './components/views/ViewStaff'
import ViewClass from './components/views/ViewClass'
import Subjects from './components/Subjects'
import Exams from './components/Exams'
import AddRecord from './components/AddRecord'
import ViewRecord from './components/ViewRecord'
import Report from './components/Report'
import Settings from './components/Settings'
import Archive from './components/Archive'
import NotFound from './components/NotFound'
import Attendance from './components/Attendance'
import ViewAttendance from './components/ViewAttendance'
import StudentUpload from './components/uploads/StudentUpload'
import StaffAttendance from './components/StaffAttendance'
import ClassRecords from './components/ClassRecords'
import Footer from './components/Footer'
import SMS from './components/SMS'
import Fees from './components/Fees'
import SetClassFees from './components/forms/SetClassFees'
import FeeRecords from './components/views/FeeRecords'
import AddFeeRecord from './components/views/AddFeeRecord'
import ViewFeeRecord from './components/views/ViewFeeRecord'

export const VERSION_NUMBER = "v1.0.0"
// export const ROOT_URL = "http://localhost/"
export const ROOT_URL = "https://schoms.heliosgh.com/"
export const SERVER_URL = ROOT_URL + "api/"

export const IMAGE_URL = ROOT_URL + "photos/" 
export const ID_URL = ROOT_URL + "ids/"
export const LOGO_URL = ROOT_URL + "logo/"
export const ATTACHMENT_URL = ROOT_URL + "attachments/"
export const APP_NAME = "SCHOMS"

export const HUBTEL_CLIENT_KEY = "fstftmtl"
export const HUBTEL_CLIENT_SECRET = "opmfkano"

export const CROP_TYPE = {
  ID: {
    aspect: 16 / 9, crop: {
      unit: '%', // Can be 'px' or '%'
      x: 0,
      y: 25,
      width: 100,
      height: 50
    }
  },
  PROFILE: {
    aspect: 1, crop: {
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 50,
      height: 50
    }
  }
}

function App() {
  const { appState } = useGlobalContext()

  if (appState.isLoggedIn) {
    return (
      <div>
        <Sidebar />
        <section className="home-section">
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/staff'>
              <Route index element={<Staff />} />
              <Route path='new-staff' element={<NewStaff />} />
              <Route path='edit/:id' element={<NewStaff />} />
              <Route path=':id' element={<ViewStaff />} />
              <Route path='attendance/:option' element={<StaffAttendance />} />
            </Route>
            <Route path='/students' >
              <Route index element={<Students />} />
              <Route path='new-student' element={<NewStudent />} />
              <Route path='edit/:id' element={<NewStudent />} />
              <Route path=':id/' element={<ViewStudent />} />
              <Route path='upload' element={<StudentUpload />} />
            </Route>
            <Route path='/classes'>
              <Route index element={<Classes />} />
              <Route path='new-class' element={<NewClass />} />
              <Route path=':id' element={<ViewClass />} />
              <Route path='attendance/:id' element={<Attendance />} />
              <Route path='view-attendance/:id' element={<ViewAttendance />} />
              <Route path='records/:id' element={<ClassRecords />} />
            </Route>
            <Route path='/subjects'>
              <Route index element={<Subjects />} />
            </Route>
            <Route path='/examinations'>
              <Route index element={<Exams />} />
            </Route>
            <Route path='/add-record/:id/:classId'>
              <Route index element={<AddRecord />} />
            </Route>
            <Route path='/view-record/:id'>
              <Route index element={<ViewRecord />} />
            </Route>
            <Route path='/report/:id/:examId'>
              <Route index element={<Report />} />
            </Route>
            <Route path='/settings'>
              <Route index element={<Settings />} />
            </Route>
            <Route path='/fees'>
              <Route index element={<FeeRecords />} />
              <Route path='set-fees' element={<SetClassFees />} />
              <Route path='search-fees' element={<Fees />} />
              <Route path='add-fee-record' element={<AddFeeRecord />} />
              <Route path='view-fee-record' element={<ViewFeeRecord />} />
            </Route>
            <Route path='/archive'>
              <Route index element={<Archive />} />
              <Route path='student/:id/:isarchive?' element={<ViewStudent />} />
              <Route path='staff/:id/:isarchive?' element={<ViewStaff />} />
            </Route>
            <Route path='/sms'>
              <Route index element={<SMS />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </section>
        <Footer />
        <ToastContainer />
      </div>
    );
  } else {
    return (
      <div>
        <Login />
        <ToastContainer />
      </div>
    );
  }

}

export default App;
