import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { SERVER_URL } from '../../App'
import { excelTypes } from '../../functions'
import Loading from '../Loading'

export default function StudentUpload() {

    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState(null)
    const [isUploading, setIsUploading] = useState(false)


    const pickFile = () => {
        document.getElementById("excel_upload").click()
    }

    const fileChange = (e) => {
        const file = e.target.files[0]
        checkFile(file)
    }

    const dragOver = (e) => {
        e.preventDefault()
    }

    const dropHandler = (e) => {
        e.preventDefault()
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            const file = e.dataTransfer.items[0].getAsFile();
            checkFile(file);


            // [...e.dataTransfer.items].forEach((item, i) => {
            //     // If dropped items aren't files, reject them
            //     if (item.kind === "file") {
            //         const file = item.getAsFile();
            //     } else {
            //         toast.info("Only excel files can be uploaded", {
            //             hideProgressBar: true,
            //             theme: "colored"
            //         })
            //     }
            // });
        } else {
            const file = e.dataTransfer.files[0]
            checkFile(file);

            // Use DataTransfer interface to access the file(s)
            // [...e.dataTransfer.files].forEach((file, i) => {
            // });
        }
    }

    const checkFile = (file) => {
        console.log(file.size)
        if (excelTypes.indexOf(file.type) === -1) {
            toast.info("Only Excel files can be uploaded", {
                hideProgressBar: true,
                theme: "colored"
            })
            setFileName("")
            setFile(null)
            return;
        }
        if (file.size > 1000000) {//1MB
            toast.info("File should be less than 1MB", {
                hideProgressBar: true,
                theme: "colored"
            })
            setFileName("")
            setFile(null)
            return;
        }

        setFileName(file.name)
        setFile(file)
    }

    const submit = (e) => {
        e.preventDefault()
        if (file === null) {
            toast.error("No File Selected", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
        setIsUploading(true)

        const formData = new FormData()
        formData.append("studentUpload", file, file.name)
        const url = SERVER_URL + "excel.php"
        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    // reset()
                    toast.success("File uploaded", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
    }

    useEffect(() => {

        document.addEventListener('dragover', (e) => {
            e.preventDefault()
        })
        document.addEventListener('drop', (e) => {
            e.preventDefault()
        })

        return () => {
            document.removeEventListener('dragover', (e) => {
                e.preventDefault()
            })
            document.removeEventListener('drop', (e) => {
                e.preventDefault()
            })
        }
    }, []);

    return (
        <div>
            <h1>Upload Students</h1>
            <div className="divider"></div>
            <p>Select excel file sheet containing student data</p>

            <form id='form' action="" onSubmit={submit}>
                <div className="file_picker pick" onClick={pickFile} onDragOver={dragOver} onDrop={dropHandler}>
                    <p>Drag file here or click to upload</p>
                    <button type='button' className="button pick">Choose File</button>
                    <p>{fileName}</p>
                </div>
                <input type="file" accept='.xlsx,.xls' id='excel_upload' onChange={fileChange} className='none' />
                <div className="form-div center">
                    <button type='submit' className="button">Upload File</button>
                </div>
            </form>

            {isUploading && <Loading />}

        </div>
    )
}
