import React, { useState } from 'react'
import { SERVER_URL } from '../../App'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function EmailModal({message, setMessage, subject, setSubject}) {
    const [email, setEmail] = useState("")
    const [isUploading, setIsUploading] = useState(false)

    const submit = async (e) => {
        e.preventDefault()

        setIsUploading(true)

        const url = SERVER_URL + "sms.php"
        axios.get(url, {
            params: {
                sendEmail: message,
                subject: subject,
                email : email
            }
        })
        .then(function (response) {
            setIsUploading(false)
            if (response.data.response) {
                closeModal()
                toast.success(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }else{
                toast.info(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
        })
        .catch(function (error) {
            setIsUploading(false)
            console.log(error)
        });
        
    }

    const closeModal = () => {
        document.getElementById("emailModal").style.display = "none"
    }

  return (
    <div id="emailModal" className="modal">
        <div className="modal-content">
            <div className="modal-header">
                <span onClick={closeModal} className="close">&times;</span>
                <h3>Send Email</h3>
            </div>
            
            <div className="">
                <form action="" onSubmit={submit}>
                    <div className='form-div'>
                        <label htmlFor="email">Email Address</label>
                        <input id='email' type="email" name='email' className='input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                    <div className="form-div">
                        <label htmlFor="subject">Email Subject</label>
                        <input id='subject' type="text" className="input" placeholder='Email Subject' value={subject} onChange={e => setSubject(e.target.value)} required />
                    </div>
                    
                    <div className='form-div'>
                        <label htmlFor="emessage">Message</label>
                        <textarea id='emessage' name='emessage' className='input' placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} rows={10} minLength={10} maxLength={1000} required/>
                    </div>
                
                <div className="center" style={{display: "flex", justifyContent: 'center', gap:'10px'}}>
                    <button id='mg_btn' className='file_button button' type='submit'>Send Sample</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
                </form>
                
                
            </div>
            
        </div>

    </div>
  )
}
