import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { BsDownload } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVER_URL } from '../App'
import { useGlobalContext } from '../AppContext'
import { getProcessedDate2 } from '../functions'
import Loading from './Loading'
import Student from './Student'

export default function ViewAttendance() {
    const { id } = useParams()
    if (id === undefined) window.history.back()

    const { appState } = useGlobalContext() //very IMPORTANT!!
    const [students, setStudents] = useState([])
    const [foundStudents, setfoundStudents] = useState([])// secondary students array, to help with search
    const [date, setDate] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState("")

    const searchOnChange = (text) => {
        setQuery(text)
        search(text)
    }

    const search = (text) => {

        if (text === "" || students.length === 0) {// no need to search
            setfoundStudents(students)
            return
        }

        const foundStudents = students.filter((student) => {
            const name = student.firstname + " " + student.lastname
            return name.toLowerCase().includes(text.toLowerCase())
        })
        setfoundStudents(foundStudents)
    }

    const dateChanged = (val) => {
        setDate(val)
        setStudents([])
        setfoundStudents([])
    }

    const submit = (e) => {
        e.preventDefault()
        if (!date) {
            return
        }

        setIsLoading(true)
        const url = SERVER_URL + "attendance.php"
        axios.get(url, {
            params: {
                getAttendance: appState.classInfo.id,
                date: date
            }
        })
            .then(function (response) {
                setIsLoading(false)
                if (response.data.response) {
                    let students = JSON.parse(response.data.students)
                    if (students.length === 0) {
                        toast.info("No students match your search", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                        return
                    }
                    setStudents(students)
                    setfoundStudents(students)

                } else {
                    toast.info(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                setIsLoading(false)
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve students", {
                    hideProgressBar: true,
                    theme: "colored"
                })
            });

    }

    const submitSearch = (e) => {
        e.preventDefault()
        search(query)
    }

    useEffect(() => {
        //if class info is not set, page has been reloaded. Go back
        if (appState.classInfo.id === "") {
            window.history.back()
            return
        }

        let today = new Date()
        setDate(today.toISOString().split("T")[0])

    }, [appState]);

    return (
        <div>
            <h1>View Attendance - {appState.classInfo.name}</h1>
            <div className="divider"></div>

            <form action="" id="form" onSubmit={submit}>
                <div className="grid">
                    <div className="form-div">
                        <label htmlFor="date">Select Date</label>
                        <input id='date' type="date" name='date' value={date} onChange={e => dateChanged(e.target.value)} className='input' required />
                    </div>
                    <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                        <button type='submit' className='submit'>Search</button>
                    </div>
                </div>

            </form>


            <div className="content">
                <div className="user_list">

                    <p>
                        Attendance on <b>{getProcessedDate2(date)}</b>
                    </p>
                    {students.length > 0 &&
                        <div className='top'>
                            <form action="" className="" onSubmit={submitSearch}>
                                <input type="search" className='input' placeholder='Search Student' value={query} onChange={e => searchOnChange(e.target.value)} />
                                <button type='submit' className='submit'>Search</button>
                            </form>

                            <a href={`http://localhost/api/reports/attendance.php?cl=${appState.classInfo.id}&date=${date}`} target={'_blank'} rel={'noreferrer'}>
                                <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
                            </a>
                        </div>
                    }

                    <table className='user_list_table' style={{ width: "auto" }}>
                        <tbody>
                            {students.length > 0 &&
                                <tr>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Present</th>

                                </tr>
                            }

                            {foundStudents.map((student) => {

                                return <Student key={student.id} student={student} viewAttendance={true} />
                            })}
                        </tbody>

                    </table>

                </div>
            </div>

            {isLoading && <Loading />}

        </div>
    )
}
