import React from 'react'
import { Link } from 'react-router-dom';
import { getProcessedDate, getAge } from '../functions';
import { IMAGE_URL } from '../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'


export default function SingleStaff({ staff, isArchive, removeStaff, archiveStaff, isAttendance, inputs, handleInputChange, viewAttendance }) {

  const date = getProcessedDate(staff.dob)
  const e_date = getProcessedDate(staff.date_employed)
  const age = getAge(staff.dob)

  const link = isArchive ? `/archive/staff/${staff.id}/s` : `/staff/${staff.id}`

  if (isAttendance) {
    return (
      <>
        <tr className='user_item'>
          <td>
            <input type="checkbox" className='checkbox' name={staff.id} defaultChecked={inputs[staff.id]} onChange={handleInputChange} />
          </td>
          <td className='image_cell'>
            <img alt='staff' className='user_image' src={IMAGE_URL + staff.image} />
          </td>
          <td>
            <span className='bolder'>{staff.firstname} {staff.lastname}</span>
          </td>
          <td>
            <span className=''>{staff.role}</span>
          </td>

        </tr>
      </>
    )
  }

  if (viewAttendance) {
    const attended = staff.attended === "yes" ? "YES" : "NO"
    return (
      <>
        <tr className='user_item'>

          <td className='image_cell'>
            <img alt='staff' className='user_image' src={IMAGE_URL + staff.image} />
          </td>
          <td>
            <span className='bolder'>{staff.firstname} {staff.lastname}</span>
          </td>
          <td>
            <span className=''>{staff.role}</span>
          </td>
          <td className={attended}>{attended}</td>

        </tr>
      </>
    )
  }

  return (
    <>
      <tr className='user_item'>
        <td className='image_cell'>
          <img alt='staff' className='user_image' src={IMAGE_URL + staff.image} />
        </td>
        <td>
          <span className='bolder'>{staff.firstname} {staff.lastname}</span>
        </td>
        <td>
          <span>{staff.email}</span>
        </td>
        <td>
          <span>{staff.phone}</span>
        </td>
        <td>
          <span>{age} yrs</span>
        </td>
        <td>
          <span>{date}</span>
        </td>
        <td>
          <span>{staff.gender}</span>
        </td>
        <td>
          <span>{e_date}</span>
        </td>

        <td>
          <div className="flex_space">
            <span>
              <Link to={link}>
                <button className='action_btn button'>View</button>
              </Link>
            </span>
            <span className={isArchive ? 'none' : ''} title='Edit Staff'>
              <Link to={`/staff/edit/${staff.id}`}>
                <FontAwesomeIcon icon={faPenToSquare} size="lg" />
              </Link>
            </span>
            <span className='none'><FontAwesomeIcon icon={faTrashCan} size="lg" /></span>
            <span className={isArchive ? 'none' : ''} title='Archive Staff' onClick={() => archiveStaff(staff.id)}>
              <FontAwesomeIcon icon={faBoxArchive} size="lg" />
            </span>
          </div>

        </td>
      </tr>

    </>

  )
}
