import React, {useState, useRef} from 'react'
import ReactCrop, {makeAspectCrop, centerCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {image64toCanvasRef} from '../../functions'

export default function CropModal({ src, type, canvasRef }) {

  let {aspect} = type
  const [crop, setCrop] = useState(type.crop)
  const [percentCrop, setpercentCrop] = useState()
  const [cropChange, setCropChange] = useState(false)
  const imgRef = useRef()

  const onCropComplete = (crop, percentCrop) => {
    setCrop(crop)
    setpercentCrop(percentCrop)
    // image64toCanvasRef(canvasRef.current, src, crop, percentCrop)
    // console.log(crop, percentCrop)
  }

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop)
    setpercentCrop(percentCrop)
    setCropChange(true)
  }

  const onImageLoad = (e) => {
    //dynamic aspect should be changed on image load
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const cropImage = () => {
    // console.log("crop image")
    //only perform crop if user has moved crop section
    if(cropChange){
      // let extension = extractImageFileExtensionFromBase64(src)
      // console.log(extension)
      // console.log(canvasRef.current.toDataURL('image/'+ extension))
      image64toCanvasRef(canvasRef.current, src, crop, percentCrop)
      closeModal();
      
    }
  }

  function centerAspectCrop( mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  // window.onclick = function(event) {
  //   var modal = document.getElementById("cropModal");
  //   if (event.target === modal) {
  //       modal.style.display = "none";
  //   }
  // }

  const closeModal = () => {
    setCropChange(false)
    document.getElementById("cropModal").style.display = "none"
  }

  return (
    <div>
        <div id="cropModal" className="modal">
          <div className="modal-content">
          <span onClick={closeModal} className="close">&times;</span>
            <div className="center">
              {/* <p>Some text in the Modal..</p> */}
              <ReactCrop crop={crop} onChange={onCropChange} aspect={aspect} onComplete={onCropComplete}
              minWidth={200} minHeight={200}>
                  <img src={src} ref={imgRef} onLoad={onImageLoad} alt=""/>
              </ReactCrop>
              <br />
              {cropChange && <button className='file_button button' onClick={cropImage}>Crop</button>}
            </div>
              
          </div>

        </div>
    </div>
  )
}
