import React, {useState} from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { SERVER_URL } from '../../App'

export default function SubjectsModal({values}) {

    const {class_name="Class", subjects, class_subjects, setClassSubjects, class_id, setSubjects} = values

    const [isUploading, setIsUploading] = useState(false)

    const submit = (e) => {
        e.preventDefault()
        setIsUploading(true)

        const url = SERVER_URL+"new-subject.php"
        const formData = new FormData()
        formData.append("editSubjects", class_id)
        formData.append("subjects", JSON.stringify(class_subjects))

        axios.post(url, formData)
        .then(function (response) {
            //hide loading spinner
            setIsUploading(false)
            if(response.data.response){
                setClassSubjects(class_subjects)
                toast.success("Subjects Saved",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }else{
                toast.error(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
            
        })
        .catch(function (error) {
            //hide loading spinner
            setIsUploading(false)
            toast.error("An Error Occured",{
                hideProgressBar: true,
                theme: "colored"
            })
            console.log(error);
        });
    }

    const closeModal = () => {
        document.getElementById("subjectModal").style.display = "none"
    }

    const onChange = (e) => {
        let subject_id = e.target.value
        let subs = subjects.map(subject =>{
            if(subject_id === subject.id){
                return {...subject, checked: e.target.checked}
            }
            return subject
        })
        setSubjects(subs) 

        let cs = class_subjects
        if(e.target.checked){// add
            cs.push(e.target.value)
        }else{// remove
            let cs = class_subjects
            const index = cs.indexOf(subject_id);
            if (index > -1) { // only splice array when item is found
                cs.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setClassSubjects(cs)
    }

  return (
    <div>
        <div id="subjectModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
                <span onClick={closeModal} className="close">&times;</span>
                <h3>{class_name} Subjects</h3>
                <p className="grey smaller_ft">(Check all subjects that apply to class)</p>
            </div>
          
            <div className="">
              <form action="" onSubmit={submit}>
                {subjects.map(subject => {
                    return(
                        <div key={subject.id}>
                            <input type="checkbox" value={subject.id} defaultChecked={subject.checked} onChange={onChange}/> {subject.name}
                        </div>
                    )
                    
                })}
                
                <div className="center">
                    <button className='file_button button' type='submit'>Save</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
              </form>
              
              
            </div>
              
          </div>

        </div>

    </div>
  )
}
