import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { APP_NAME, SERVER_URL } from '../../App'
import Loading from '../Loading'
import Student from '../Student'
import { useGlobalContext } from '../../AppContext'
import { Link } from 'react-router-dom'

export default function FeeRecords() {
    document.title = APP_NAME + " | Fees & Accounts"
    const { appState:{classInfo, classStudents}, setClassInfo, setClassStudents} = useGlobalContext() //to persist class students!!

    const [isUploading, setIsUploading] = useState(false)
    const [classes, setClasses] = useState([])
    const [students, setStudents] = useState(classStudents | [])
    const [foundStudents, setFoundStudents] = useState([])
    const [classId, setClassId] = useState(classInfo.id | "")
    const [query, setQuery] = useState("")
    const [showAll, setShowAll] = useState(false)//


    const searchOnChange = (text) => {
        // console.log("on change ", text);
        setQuery(text)
        search(text)
    }
    
    const search = (text) => {

        if (text === "" || students.length === 0) {// no need to search
            setFoundStudents(students.slice(0, 10)) // show 10 max
            return
        }

        const foundStudents = students.filter((student) => {
            const name = student.firstname + " " + student.lastname
            return name.toLowerCase().includes(text.toLowerCase())
        })
        setFoundStudents(foundStudents)
    }

    const showChecked = (e) => {
        setShowAll(e.target.checked)
        if (students.length < 11) return
    
        if (e.target.checked) {
          setFoundStudents(students)
        } else {
          setFoundStudents(students.slice(0, 10))//only show 10
        }
    }

    const submitSearch = (e) => {
        e.preventDefault()
        search(query)
    }

    const handleClassChange = (e) => {
        setClassId(e.target.value)
        let classItem = classes.find(item => {
            return item.id === e.target.value
        })
        setClassInfo(classItem)
    }

    const submit = (e) => {
        e.preventDefault()
    
        //validate all inputs
        if(!classId) return
        
        //show loading spinner
        setIsUploading(true)
        // const formData = new FormData()
       
        const url = SERVER_URL+"students.php"
        axios.get(url, {
            params: {
                loadClassStudents: classId
            }
        })
        .then(function (response) {
            //hide loading spinner
            setIsUploading(false)
            if(response.data.response){
                let students = JSON.parse(response.data.students)
                setStudents(students)
                setFoundStudents(students.slice(0, 10))//only show 10 on load
                setClassStudents(students)// for persistency
                if(students.length === 0)
                    toast.info("No students in class",{
                        hideProgressBar: true,
                        theme: "colored"
                    })
            }else{
                toast.error(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
            
        })
        .catch(function (error) {
            //hide loading spinner
            setIsUploading(false)
            toast.error("An Error Occured",{
                hideProgressBar: true,
                theme: "colored"
            })
            console.log(error);
        });
        
    }

    useEffect(() => {
        const fetchClasses = () => {
            const url = SERVER_URL+"classes.php"
            axios.get(url, {
                params: {
                loadClasses: "loadClasses"
                }
            })
            .then(function (response) {
                
                if(response.data.response){ 
                    let classes = JSON.parse(response.data.classes)
                    setClasses([...classes])
                }else{ 
                    
                }
                
            })
            .catch(function (error) {
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve classes",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            });
            
        }
        fetchClasses()
    }, []);

    useEffect(() => {
        if(classStudents.length > 0){
            setStudents(classStudents)
            setFoundStudents(classStudents.slice(0,10))
            setClassId(classInfo.id)
        }
            
    }, [classStudents, classInfo]);

  return (
    <div>
        <h1>Fees & Accounts</h1>
        <h3 className="grey">View Payment Records</h3>
        <div className="divider"></div>

        <div className="top">
            <div></div>
            <div style={{ display: "flex", gap: "10px" }}>
                <Link to="set-fees">
                    <button type='button' className='button'>Set Class Fees</button>
                </Link>
                <Link to="search-fees">
                    <button type='button' className='button'>Search Class Fees</button>
                </Link>
            </div>
            
        </div>

        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Filter Students By Class</legend>
                    <div className="grid">
                        <div className="form-div">
                            <label htmlFor="class">Class</label>
                            
                            <select id='class' name='class' value={classId} onChange={handleClassChange} className='input' required>
                                <option value=''>--Select Class--</option>
                                {classes.map((item) =>{
                                    const {id, name} = item
                                    return <option key={id} value={id}>{name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div" style={{display: "flex", alignItems: 'flex-end'}}>
                            <button type='submit' className='submit'>Search</button>
                        </div>
                    </div>
    
                </fieldset>
            
            </form>

            <div className="user_list">
                {students.length > 0 &&
                <>
                    <div>
                        <form action="" className="" onSubmit={submitSearch}>
                            <input type="search" className='input' placeholder='Search Student' value={query} onChange={e => searchOnChange(e.target.value)} />
                            <button type='submit' className='submit'>Search</button>
                        </form>
                    </div>
                    
                    <h4 className='grey'>
                        {students.length} Total {students.length === 1 ? "Student" : "Students"}
                        <span className="smaller_ft"> (showing {foundStudents.length})</span>
                    </h4>
                    <input type="checkbox" value={showAll} onChange={e => showChecked(e)} /> Show All
                
                
                
                    <table className='user_list_table auto_width'>
                    <tbody>
                        <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        
                        <th>Actions</th>
                        </tr>

                        {foundStudents.map((student) => {
                            return <Student key={student.id} student={student} isFeeRecords={true} />
                        })}
                    </tbody>

                    </table>
                </>
                }
            </div>

        </div>

        {isUploading && <Loading/>}
    </div>
  )
}
