import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { SERVER_URL } from '../../App'

export default function ClassTeacherModal({class_id, class_name, setUpdated}) {

    const [tutors, setTutors] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [tutor, setTutor] = useState("")

    const closeModal = () => {
        document.getElementById("teacherModal").style.display = "none"
    }

    useEffect(() => {
        const url = SERVER_URL+"staff.php"
        const fetchTutors = () => {
          axios.get(url, {
              params: {
                loadTutors: ""
              }
            })
            .then(function (response) {
              if(response.data.response){ 
                let tutors = JSON.parse(response.data.tutors)
                
                setTutors(tutors)
                
              }else{ 
                  
              }
              
            })
            .catch(function (error) {
              toast.error("An Error Occured. Couldn't retrieve tutors",{
                  hideProgressBar: true,
                  theme: "colored"
              })
            });
         
        }
    
        fetchTutors()
    }, []);

    const submit = (e) => {
        e.preventDefault()
        if(!class_id) return

        setIsUploading(true)

        const url = SERVER_URL+"classes.php"
        const formData = new FormData()
        formData.append("editClass", class_id)
        formData.append("tutor", tutor)

        axios.post(url, formData)
        .then(function (response) {
            //hide loading spinner
            setIsUploading(false)
            if(response.data.response){
                setUpdated(true)
                toast.success("Tutor saved as class teacher",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                closeModal()
            }else{
                toast.error(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
            
        })
        .catch(function (error) {
            //hide loading spinner
            setIsUploading(false)
            toast.error("An Error Occured",{
                hideProgressBar: true,
                theme: "colored"
            })
            console.log(error);
        });
    }
  return (
    <div>
        <div id="teacherModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
                <span onClick={closeModal} className="close">&times;</span>
                <h3>Select a Tutor for {class_name}</h3>
                <p className="grey smaller_ft">(Only Tutors are eligible)</p>
            </div>
          
            <div className="">
              <form action="" onSubmit={submit}>
                <div className="form-div">
                    <select id='tutor' name='tutor' value={tutor} onChange={(e) => setTutor(e.target.value)} className='input' required
                    title='Tutor'>
                        <option value=''>--Select Tutor--</option>
                        {tutors.map((tutor) =>{
                            const {id, firstname, lastname} = tutor
                            return <option key={id} value={id}>{firstname} {lastname}</option>
                        })}
                    </select>
                </div>
                
                <div className="center">
                    <button className='file_button button' type='submit'>Save</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
              </form>
              
              
            </div>
              
          </div>

        </div>

        <ToastContainer />

    </div>
  )
}
