import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { SERVER_URL } from '../App';
import Loading from './Loading';
import { formatter, schoolTerms } from '../functions';

export default function Fees() {

    const [inputs, setInputs] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [classes, setClasses] = useState([])
    const [schoolYears, setSchoolYears] = useState([])
    const [fees, setFees] = useState([])
    const url = SERVER_URL+"fees.php"

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const submit = (e) => {
        e.preventDefault()
    
        //validate all inputs
        for ( var key in inputs ) {
            if(inputs[key] === ""){
                toast.error(key + " cannot be empty",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }
    
        //show loading spinner
        setIsUploading(true)
        // const formData = new FormData()
        var params = {}
        for ( key in inputs ) {
            // formData.append(key, inputs[key]);
            params = {...params, [key]: inputs[key]}
        }
    
        axios.get(url, {
            params: params
        })
        .then(function (response) {
            //hide loading spinner
            setIsUploading(false)
            if(response.data.response){
                // reset()
                // console.log(JSON.parse(response.data.fees))
                let fees = JSON.parse(response.data.fees)
                setFees(fees)
                if(fees.length === 0)
                    toast.info("Fees not set",{
                        hideProgressBar: true,
                        theme: "colored"
                    })
            }else{
                toast.error(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
            
        })
        .catch(function (error) {
            //hide loading spinner
            setIsUploading(false)
            toast.error("An Error Occured",{
                hideProgressBar: true,
                theme: "colored"
            })
            console.log(error);
        });
        
    }

    // const reset = () => {
    //     setInputs({})
    //     document.getElementById('form').reset()
    // }

    useEffect(() => {
        const fetchClasses = () => {
            const url = SERVER_URL+"fees.php"
            axios.get(url, {
                params: {
                loadClasses: "loadClasses",
                loadSchoolYears: "loadSchoolYears"
                }
            })
            .then(function (response) {
                
                if(response.data.response){ 
                    let classes = JSON.parse(response.data.classes)
                    let schoolYears = JSON.parse(response.data.schoolYears)
                    setClasses([...classes])
                    setSchoolYears(schoolYears)
                }else{ 
                    
                }
                
            })
            .catch(function (error) {
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve classes",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            });
            
        }
        fetchClasses()
    }, []);

  return (
    <div>
        <h1>Search Class Fees</h1>
        <div className="divider"></div>

        
        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Find Class Fees</legend>
                    <div className="grid">
                        
                        <div className="form-div">
                            <label htmlFor="class">Class</label>
                            <select id='class' name='class' value={inputs.class || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Class--</option>
                                {classes.map((item) =>{
                                const {id, name} = item
                                return <option key={id} value={id}>{name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="school_year">School Year</label>
                            <select id='school_year' name='school_year' value={inputs.school_year || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select School Year--</option>
                                {schoolYears.map((item) =>{
                                const {id, year} = item
                                return <option key={id} value={id}>{year}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="term">Term</label>
                            <select id='term' name='term' value={inputs.term || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Term--</option>
                                {schoolTerms.map((item) =>{
                                    return <option key={item} value={item}>{item}</option>
                                })}
                                
                            </select>
                        </div>
                        
                        
                    </div>
    
                </fieldset>
            

                <div className="form-div center">
                    <button type='submit' className='submit'>Search</button>
                </div>
            </form>

            <div className="user_list">

                <table className='user_list_table auto_width'>
                    <tbody>
                    {fees.length > 0 && 
                    <tr>
                        <th>Class</th> 
                        <th>School Year</th> 
                        <th>Term</th>
                        <th className=''>Amount</th>
                    </tr>
                    } 
                    
                    {fees.map((item) => {
                        return(
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.year}</td>
                                <td>{item.term}</td>
                                <td>{formatter.format(item.amount)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    
                </table>
            </div>

        </div>

        {isUploading && <Loading/>}
    </div>
  )
}
