import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { SERVER_URL, APP_NAME } from '../App'
import MiniLoading from './utils/MiniLoading'
import Loading from './Loading'
import AlertDialog from './utils/AlertDialog'
import { getProcessedDate } from '../functions'
import { useGlobalContext } from '../AppContext'

export default function ViewRecord() {
    const { id } = useParams()
    if (id === undefined) window.history.back()

    const { appState } = useGlobalContext() //very IMPORTANT!!

    document.title = APP_NAME + " | View Examination Record"

    const [examination, setExamination] = useState("")
    const [examReportId, setExamReportId] = useState("") // for linking to report page (user could change dropdown)
    const [student, setStudent] = useState({})
    const [examinations, setExaminations] = useState([])
    const [records, setRecords] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [open, setAlertOpen] = useState(false);
    const [markTotal, setMarkTotal] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);
    const url = SERVER_URL + "records.php"

    const submit = (e) => {
        e.preventDefault()

        if (examination === "") {
            toast.error("Select examination", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //show loading spinner
        setIsLoading(true)
        axios.get(url, {
            params: {
                getExaminationRecord: examination,
                studentId: id
            }
        })
            .then(function (response) {
                //hide loading spinner
                setIsLoading(false)
                if (response.data.response) {
                    let records = JSON.parse(response.data.records)
                    if (records.length === 0) {
                        setRecords([])
                        toast.info(`No matching examination record for ${student.firstname} ${student.lastname}`, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                        return
                    }
                    setExamReportId(examination)// could use examination but user could change dropdown without searching
                    setRecords(records)

                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsLoading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
    }

    const deleteRecord = () => {
        if (examination) {
            //confirm preceding class
            setAlertTitle("Confirm Record Delete")
            let message = "Are you sure you want to delete this record? This action cannot be reversed"
            setAlertMessage(message)
            setAlertOpen(true);
            //submit in alert handleclose 
        }

    }

    //to handle deleting of record
    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        console.log("close")
        if (option) {
            //show loading spinner
            setIsUploading(true)
            const formData = new FormData()

            formData.append("deleteRecord", examination)//exam id
            formData.append("studentId", id)//student id

            axios.post(url, formData)
                .then(function (response) {
                    //hide loading spinner
                    setIsUploading(false)
                    if (response.data.response) {
                        setRecords([])
                        toast.success("Examination Record Deleted", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    //hide loading spinner
                    setIsUploading(false)
                    toast.error("An Error Occured", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                    console.log(error);
                });
        }
    };

    //get student info on load, and examinations
    useEffect(() => {
        const url = SERVER_URL + "records.php"
        const fetchStudentInfo = () => {
            axios.get(url, {
                params: {
                    loadStudentInfo: id,
                    loadExaminations: "loadExaminations",
                    studentType: appState.studentType
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let students = JSON.parse(response.data.students)
                        let examinations = JSON.parse(response.data.examinations)
                        setStudent(students[0])
                        setExaminations(examinations)

                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve student and subject info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchStudentInfo()
    }, [id, appState.studentType]);

    useEffect(() => {

        const sumTotal = () => {
            if (records.length > 0) {
                let marks = 0
                let final = 0
                records.forEach(record => {
                    const { score, total } = record
                    marks += score * 1
                    final += total * 1
                });
                setMarkTotal(marks)
                setFinalTotal(final)
            }
        }

        sumTotal()
    }, [records]);

    return (
        <div>
            <h1>Exam Records for '{student.firstname} {student.lastname}'</h1>
            <div className="divider"></div>

            <div className="content">
                <form id='form' action="" onSubmit={submit}>
                    <fieldset>
                        <legend>Examination Information</legend>
                        <div className="grid">

                            <div className="form-div">
                                <label htmlFor="examination">Examination to Search</label>
                                <select id='examination' name='examination' value={examination} onChange={(e) => setExamination(e.target.value)} className='input' required
                                    title='Examination'>
                                    <option value=''>--Select Examination--</option>
                                    {examinations.map((item) => {
                                        const { id, name } = item
                                        return <option key={id} value={id}>{name} | {getProcessedDate(item.date)}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                                <button type='submit' className='submit'>Search</button>
                            </div>

                        </div>



                    </fieldset>

                </form>

                {records.length > 0 &&
                    <div className="user_list">
                        <div className='flex'>
                            <button type='button' className='button del' onClick={deleteRecord}>Delete Record</button>
                            <Link to={`/report/${id}/${examReportId}`}>
                                <button type='button' className='button'>Create Report</button>
                            </Link>

                        </div>

                        <table className=''>
                            <tbody>
                                <tr>
                                    <th>Subject</th>
                                    <th>Marks</th>
                                    <th>Total (%)</th>
                                    <th>Grading</th>
                                </tr>
                                {records.map(record => {
                                    const { id, name, score, total, grading } = record

                                    return (
                                        <tr className='user_item' key={id}>
                                            <td>{name}</td>
                                            <td>{score}</td>
                                            <td>{total}</td>
                                            <td>{grading}</td>
                                        </tr>
                                    )
                                })}
                                <tr className='bolder'>
                                    <td>Total</td>
                                    <td>{markTotal}</td>
                                    <td>{finalTotal}</td>
                                    <td></td>
                                </tr>


                            </tbody>
                        </table>

                    </div>
                }

            </div>

            {isLoading && <MiniLoading />}

            <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
            {isUploading && <Loading />}

        </div>
    )
}
