import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../AppContext'
import Student from './Student';
import { SERVER_URL } from '../App';
import Loading from './Loading';
import AlertDialog from './utils/AlertDialog';

export default function Attendance() {
    const { id } = useParams()
    if (id === undefined) window.history.back()

    const { appState } = useGlobalContext() //very IMPORTANT!!
    const [students] = useState(appState.classStudents)
    const [inputs, setInputs] = useState({})
    const [date, setDate] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [open, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [foundStudents, setfoundStudents] = useState(appState.classStudents)// secondary students array, to help with search
    const [query, setQuery] = useState("")

    const searchOnChange = (text) => {
        setQuery(text)
        search(text)
    }

    const search = (text) => {

        if (text === "" || students.length === 0) {// no need to search
            setfoundStudents(students)
            return
        }

        const foundStudents = students.filter((student) => {
            const name = student.firstname + " " + student.lastname
            return name.toLowerCase().includes(text.toLowerCase())
        })
        setfoundStudents(foundStudents)
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setInputs(values => ({ ...values, [name]: value }))
        if (!value) document.getElementById("select_all").checked = false

    }

    const selectAllStudents = useCallback(() => {
        let inputs = {}
        try {
            let checkedState = document.getElementById("select_all").checked
            students.forEach((student) => {
                inputs = { ...inputs, [student.id]: checkedState }
            })
            setInputs(inputs)

            var checkboxes = document.getElementsByClassName("checkbox");
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = checkedState;
            }
        } catch (error) {

        }


    }, [students])

    const submit = (e) => {
        e.preventDefault()
        if (students.length === 0) return

        if (!date) {
            toast.error("Please select the attendance date", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //confirm
        setAlertTitle("Confirm Class Attendance and Date")
        setAlertMessage("Are you sure you checked the right students and selected the right date? This action cannot be undone")
        setAlertOpen(true);
        //submit in alert handleclose


    }

    const submitSearch = (e) => {
        e.preventDefault()
        search(query)
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            setIsUploading(true)

            const formData = new FormData()
            formData.append("setAttendance", appState.classInfo.id);
            formData.append("date", date);
            formData.append("students", JSON.stringify(inputs));
            // formData.append("students", inputs);
            // for (var key in inputs) {
            //     formData.append(key, inputs[key]);
            // }
            const url = SERVER_URL + "attendance.php"
            axios.post(url, formData)
                .then(function (response) {
                    //hide loading spinner
                    setIsUploading(false)
                    if (response.data.response) {
                        toast.success("Attendance Saved", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    } else {
                        toast.error(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    //hide loading spinner
                    setIsUploading(false)
                    toast.error("An Error Occured", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        // console.log(appState.classInfo)
        //if class info is not set, page has been reloaded. Go back
        if (appState.classInfo.id === "") {
            window.history.back()
            // window.location.assign("/classes")
            return
        }

        let today = new Date()
        // document.getElementById("date").value = today.toISOString().split("T")[0]
        setDate(today.toISOString().split("T")[0])
        // console.log(id)
        // console.log(appState.classInfo)
        // console.log(appState.classStudents.length)

        if (appState.classStudents.length > 0)//will throw an error when selecting student check boxes
            selectAllStudents()
    }, [appState, selectAllStudents]);

    return (
        <div>
            <h1>Attendance - {appState.classInfo.name}</h1>
            <div className="divider"></div>

            <form action="" id="form" onSubmit={submit}>
                <div className="grid">
                    <div className="form-div">
                        <label htmlFor="date">Attendance Date</label>
                        <input id='date' type="date" name='date' value={date} onChange={e => setDate(e.target.value)} className='input' required />
                    </div>
                    <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                        <button type='submit' className='submit'>Submit Attendance</button>
                    </div>
                </div>

            </form>


            <div className="content">
                <div className="user_list">

                    <p>
                        Mark students as present by ticking the checkbox beside them
                    </p>
                    {students.length > 0 &&
                        <div>
                            <form action="" className="" onSubmit={submitSearch}>
                                <input type="search" className='input' placeholder='Search Student' value={query} onChange={e => searchOnChange(e.target.value)} />
                                <button type='submit' className='submit'>Search</button>
                            </form>
                        </div>
                    }
                    <table className='user_list_table' style={{ width: "auto" }}>
                        <tbody>
                            {students.length > 0 &&
                                <tr>
                                    <th> <input id='select_all' type="checkbox" defaultChecked onChange={selectAllStudents} /> Select All</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                </tr>
                            }

                            {foundStudents.map((student) => {

                                return <Student key={student.id} student={student} isAttendance={true} inputs={inputs}
                                    handleInputChange={handleInputChange} />
                            })}
                            {/* <Student/> */}
                        </tbody>

                    </table>

                </div>
            </div>

            {isUploading && <Loading />}
            <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
        </div>
    )
}
