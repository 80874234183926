import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import '../../css/form.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { isValidPhoneNumber, imageTypes, isCanvasBlank } from '../../functions'
import Loading from '../Loading'
import CropModal from '../utils/CropModal';
import { SERVER_URL, CROP_TYPE, IMAGE_URL, ID_URL } from '../../App'

export default function NewStaff() {

    let editing = false
    const { id } = useParams()// for editing staff
    if (id && id !== "") editing = true

    const [inputs, setInputs] = useState({})
    const [files, setFiles] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [showCanvas, setShowCanvas] = useState({ id_photo: false, profile_photo: false })
    const [imgSrc, setImgSrc] = useState(null)
    const [cropType, setCropType] = useState(CROP_TYPE.ID)
    const idCanvasRef = useRef()
    const profileCanvasRef = useRef()
    const canvasRef = useRef()

    const [isEditing, setIsEditing] = useState(editing) //whether editing or new staff
    const [staffId] = useState(id)
    const [staff, setStaff] = useState({})
    const [title, setTitle] = useState("New Staff")

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        // console.log(name, value)
    }

    const handleFileChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        //validate image type
        if (imageTypes.indexOf(file.type) === -1) {
            toast.error("Only png and jpeg image files can be uploaded", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }
        //validate image size
        if (file.size > 1000000) {
            toast.error("Image size should be less than 1MB", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }

        // readImage(value, e.target.getAttribute('data-id'))

        if (e.target.id === "id_photo") {
            setCropType(CROP_TYPE.ID)
            canvasRef.current = idCanvasRef.current
        } else {
            setCropType(CROP_TYPE.PROFILE)
            canvasRef.current = profileCanvasRef.current
        }
        var reader = new FileReader();
        reader.addEventListener("load", function (e) {

            //read image to check dimensions
            var image = new Image();
            image.onload = function () {
                // console.log(this.width, this.height)
                if (this.width < 200 || this.height < 200) {
                    toast.error("Image width and height should be at least 200px", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    //show corresponding image canvas
                    setShowCanvas(values => ({ ...values, [name]: true }))
                    //only add to state after passed all eval
                    setFiles(values => ({ ...values, [name]: file }))
                    setImgSrc(reader.result)
                    document.getElementById("cropModal").style.display = "block";

                }

            }
            image.src = reader.result;

        })
        reader.readAsDataURL(file);
        // console.log(e.target.getAttribute('data-id'))
        // console.log(name, value)
    }

    const idUpload = () => {
        document.getElementById("id_photo").click()
    }

    const profileUpload = () => {
        document.getElementById("profile_photo").click()
    }

    const submit = (e) => {
        e.preventDefault()

        if (!isValidPhoneNumber(inputs.phone)) {
            //phone number is invalid
            toast.error("Phone number is invalid", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (!inputs.hasOwnProperty('gender')) {
            //no gender selected
            toast.error("Select a gender", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (!inputs.hasOwnProperty('role')) {
            //no gender selected
            toast.error("Select staff role", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //validate all inputs
        for (var key in inputs) {
            if (inputs[key] === "") {
                toast.error(key + " cannot be empty", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }

        //only if not editing
        if (!editing) {
            if (Object.keys(files).length < 2 ||
                (isCanvasBlank(idCanvasRef.current) || isCanvasBlank(profileCanvasRef.current))) {
                toast.error("Select all required images", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }

        //show loading spinner
        setIsUploading(true)
        const formData = new FormData()
        for (key in inputs) {
            formData.append(key, inputs[key]);
        }
        //get canvas image data
        if (!isCanvasBlank(idCanvasRef.current)) {
            const idBase64Data = idCanvasRef.current.toDataURL()
            formData.append("idBase64Data", idBase64Data)
        }
        if (!isCanvasBlank(profileCanvasRef.current)) {
            const profileBase64Data = profileCanvasRef.current.toDataURL()
            formData.append("profileBase64Data", profileBase64Data)
        }

        let url = SERVER_URL + "new-staff.php"
        if (editing) formData.append("isEditing", staffId) //VERY IMPORTANT !!

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    if (!editing) reset()

                    let message = editing ? "Staff Info Updated" : "New Staff Saved"
                    toast.success(message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

                // console.log(response.data);
            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });

        // console.log("add staff")
    }

    const reset = () => {
        setInputs({})
        setFiles({})
        document.getElementById('form').reset()
        clearCanvas()
        //hide canvas
        setShowCanvas({ id_photo: false, profile_photo: false })
    }

    const clearCanvas = () => {
        let canvas = idCanvasRef.current
        let ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        canvas = profileCanvasRef.current
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)
    }

    useEffect(() => {
        setIsEditing(editing) //VERY IMPORTANT
        let today = new Date()

        let eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(today.getFullYear() - 18)
        // console.log(eighteenYearsAgo.toISOString().split("T")[0]);
        document.getElementById("dob").max = eighteenYearsAgo.toISOString().split("T")[0]
        document.getElementById("date_employed").max = today.toISOString().split("T")[0]
        document.getElementById("id_issued").max = today.toISOString().split("T")[0]
        document.getElementById("id_expiry").min = today.toISOString().split("T")[0]
        //=============editing
        if (isEditing) {
            setIsUploading(true) //just to show the loading view
            const url = SERVER_URL + "staff.php"

            const fetchStaff = () => {
                axios.get(url, {
                    params: {
                        loadStaffInfo: staffId
                    }
                })
                    .then(function (response) {
                        setIsUploading(false)
                        if (response.data.response) {
                            let staff = JSON.parse(response.data.staff)
                            // console.log(staff) 
                            if (staff.length === 0) {
                                setStaff({})
                                toast.info("No staff in database", {
                                    hideProgressBar: true,
                                    theme: "colored"
                                })
                                return
                            }
                            setStaff(staff[0])
                            setStaffFields(staff[0])

                        } else {
                            toast.info(response.data.message, {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                        }

                    })
                    .catch(function (error) {
                        setIsUploading(false)
                        console.log(error)
                        toast.error("An Error Occured. Couldn't retrieve staff info", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    });

            }

            fetchStaff()
        }
    }, [isEditing, staffId, editing]);

    //if canvas state changes, clear image from editing
    useEffect(() => {
        if (showCanvas.id_photo) {
            document.getElementById("id_display").className = " none"
        }
        if (showCanvas.profile_photo) {
            document.getElementById("profile_display").className = " none"
        }

    }, [showCanvas]);

    //function for editing a user. Populate fields with current user info
    const setStaffFields = (staff) => {
        setTitle(staff.firstname + " " + staff.lastname)
        const staffInfo = {
            firstname: staff.firstname,
            lastname: staff.lastname,
            dob: staff.dob,
            address: staff.address,
            gender: staff.gender,
            role: staff.role,
            date_employed: staff.date_employed,
            phone: staff.phone,
            email: staff.email,
            id_number: staff.id_number,
            id_issued: staff.id_issued,
            id_expiry: staff.id_expiry,
        }
        setInputs(staffInfo)
    }

    return (
        <div>
            <h1>{title}</h1>
            <div className="divider"></div>

            <div className="content">
                <form id='form' action="" onSubmit={submit}>
                    <h3>All fields required unless stated otherwise</h3>
                    <fieldset>
                        <legend>Staff Information</legend>
                        <div className="grid">
                            <div className="form-div">
                                <label htmlFor="firstname">First Name </label>
                                <input id='firstname' type="text" name='firstname' value={inputs.firstname || ""} onChange={handleInputChange} className='input' placeholder='First Name' minLength={2} maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="lastname">Last Name </label>
                                <input id='lastname' type="text" name='lastname' value={inputs.lastname || ""} onChange={handleInputChange} className='input' placeholder='Last Name' minLength={2} maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="dob">Date of Birth </label>
                                <input id='dob' type="date" name='dob' value={inputs.dob || ""} onChange={handleInputChange} className='input' max="2004-01-01" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="email">Email Address </label>
                                <input id='email' type="email" name='email' value={inputs.email || ""} onChange={handleInputChange} className='input' placeholder='Email Address' maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="phone">Phone Number</label>
                                <input id='phone' type="phone" name='phone' value={inputs.phone || ""} onChange={handleInputChange} className='input' placeholder='Phone Number' minLength={10} maxLength={10} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="address">Home Address</label>
                                <textarea id='address' type="address" name='address' value={inputs.address || ""} onChange={handleInputChange} className='input' rows={1} placeholder='Home Address' minLength={2} maxLength={200} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="gender">Gender</label>
                                <select id='gender' name='gender' value={inputs.gender || ""} onChange={handleInputChange} className='input' required>
                                    <option value=''>--Select Gender--</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="form-div">
                                <label htmlFor="date_employed">Date Employed in School </label>
                                <input id='date_employed' type="date" name='date_employed' value={inputs.date_employed || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="role">Staff Role</label>
                                <select id='role' name='role' value={inputs.role || ""} onChange={handleInputChange} className='input' required>
                                    <option value=''>--Select Role--</option>
                                    <option value="Management">Management</option>
                                    <option value="Administration">Administration</option>
                                    <option value="Tutor">Tutor</option>
                                    <option value="Janitor">Janitor</option>
                                </select>
                            </div>
                        </div>

                    </fieldset>

                    <fieldset>
                        <legend>ID Card</legend>
                        <div className="grid">
                            <div className="form-div">
                                <label htmlFor="id_number">ID Number</label>
                                <input id='id_number' type="text" name='id_number' value={inputs.id_number || ""} onChange={handleInputChange} className='input' placeholder="ID Number" maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_issued">Date of Issue</label>
                                <input id='id_issued' type="date" name='id_issued' value={inputs.id_issued || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_expiry">Date of Expiry</label>
                                <input id='id_expiry' type="date" name='id_expiry' value={inputs.id_expiry || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_photo">Upload ID Photo</label>
                                <input id='id_photo' type="file" name='id_photo' onChange={handleFileChange} className='none' data-id="id_display" accept='.png, .jpg, .jpeg' />
                                <button type="button" className="file_button button" onClick={idUpload}>Click to Upload</button>
                                <div id='id_display' className='img_preview'>
                                    {staff.id_image && <img src={ID_URL + staff.id_image} alt="" />}
                                </div>
                                <canvas ref={idCanvasRef} className={showCanvas.id_photo ? '' : 'none'}></canvas>
                            </div>
                            <div className="form-div">
                                <label htmlFor="profile_photo">Passport Size Photo</label>
                                <input id='profile_photo' type="file" name='profile_photo' onChange={handleFileChange} className='none' data-id="profile_display" accept='.png, .jpg, .jpeg' />
                                <button type='button' className="file_button button" onClick={profileUpload}>Click to Upload</button>
                                <div id='profile_display' className='img_preview'>
                                    {staff.image && <img src={IMAGE_URL + staff.image} alt="" />}
                                </div>
                                <canvas ref={profileCanvasRef} className={showCanvas.profile_photo ? '' : 'none'}></canvas>
                            </div>
                        </div>
                    </fieldset>

                    <div className="form-div center">
                        <button type='submit' className='submit'>{editing ? "Save" : "Add Staff"}</button>
                    </div>
                </form>
            </div>

            <ToastContainer />
            {isUploading && <Loading />}
            <CropModal src={imgSrc} type={cropType} canvasRef={canvasRef} />

        </div>
    )
}
