import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../AppContext'
import axios from 'axios'
import { ATTACHMENT_URL, SERVER_URL } from '../../App'
import { toast } from 'react-toastify'
import { formatter, getProcessedDate } from '../../functions'

export default function ViewFeeRecord() {
    const { appState:{activeStudent}} = useGlobalContext() //very IMPORTANT!!
    const [student] = useState(activeStudent)
    const [records, setRecords] = useState([])

    useEffect(() => {
        if(student.id === "") return

        const fetchPaymentRecord = () => {
            const url = SERVER_URL+"fees.php"
            axios.get(url, {
                params: {
                    fetchPaymentRecord: student.id
                }
            })
            .then(function (response) {
                
                if(response.data.response){ 
                    let records = JSON.parse(response.data.records)
                    setRecords(records)
                }else{ 
                    
                }
                
            })
            .catch(function (error) {
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve classes",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            });
            
        }
        fetchPaymentRecord()
    }, [student]);

  return (
    <div>
        <h1>{student.firstname ? `${student.firstname} ${student.lastname}` : "Add Fee Record"}</h1>
        <h3 className='grey'>Viewing fee payment record</h3>
        <div className="divider"></div>
      
      <div className="content">
        <div className="user_list">
            <table className='user_list_table auto_width small_ft'>
                <tbody>
                    <tr>
                        <th>Class of Payment</th>
                        <th>School Year</th>
                        <th>Term</th>
                        <th>Amount Paid</th>
                        <th>Fee At The Time</th>
                        <th>Arrears</th>
                        <th>Date Paid</th>
                        <th>Recorded By</th>
                        <th>Date Recorded</th>
                        <th>Attachment</th>
                    </tr>

                    {records.map((record) => {
                        const {amount_paid, fee_at_time, attachment} = record
                        // let status = amount_paid >= fee_at_time ? "success" : "error" //for paid
                        let arrears = fee_at_time - amount_paid
                        let status = arrears === 0 ? "success" : "error" // for arrears
                        
                        return (
                            <tr key={record.id}>
                                <td>{record.name}</td>
                                <td>{record.year}</td>
                                <td>{record.term}</td>
                                <td >{formatter.format(amount_paid)}</td>
                                <td>{formatter.format(fee_at_time)}</td>
                                <td className={status}>{formatter.format(arrears)}</td>
                                <td>{getProcessedDate(record.date_of_payment) }</td>
                                <td className='grey'>{record.recorded_by}</td>
                                <td>{record.date}</td>
                                <td>
                                    {attachment && 
                                    <a href={ATTACHMENT_URL+attachment} target='_blank' rel="noopener noreferrer">
                                        <button className='action_btn button'>View Attachment</button>
                                    </a>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>

            </table>
        </div>
      </div>
    </div>
  )
}
