import React, {useState, useEffect} from 'react'
import '../../css/form.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../Loading'
import {SERVER_URL} from '../../App'
import AlertDialog from '../utils/AlertDialog'
//

export default function NewClass() {

  const [inputs, setInputs] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [open, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [precedingClasses, setprecedingClasses] = useState([])
  const url = SERVER_URL+"new-class.php"

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(values => ({...values, [name]: value}))
    // console.log(name, value)
  }

  const submit = (e) => {
    e.preventDefault()

    if(!inputs.hasOwnProperty('preceding')){
        toast.error("Select a preceding class",{
            hideProgressBar: true,
            theme: "colored"
        })
        return
    }

    //validate all inputs
    for ( var key in inputs ) {
        if(inputs[key] === ""){
            toast.error(key + " cannot be empty",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
    }

    //confirm preceding class
    setAlertTitle("Confirm Preceding Class")
    setAlertMessage("Are you sure you selected the right preceding class?")
    setAlertOpen(true);
    //submit in alert handleclose
    

  }

  const reset = () => {
    setInputs({})
    document.getElementById('form').reset()
  }

  const handleClose = (option) => {
    //if there are multiple alert scenarios, will have to use another variable to determine action
    setAlertOpen(false);
    if(option){
      //show loading spinner
      setIsUploading(true)
      const formData = new FormData()
      for ( var key in inputs ) {
          formData.append(key, inputs[key]);
      }
    
      axios.post(url, formData)
        .then(function (response) {
          //hide loading spinner
          setIsUploading(false)
          if(response.data.response){
              reset()
              toast.success("New Class Created",{
                  hideProgressBar: true,
                  theme: "colored"
              })
          }else{
              toast.error(response.data.message,{
                  hideProgressBar: true,
                  theme: "colored"
              })
          }
          
        })
        .catch(function (error) {
          //hide loading spinner
          setIsUploading(false)
          toast.error("An Error Occured",{
              hideProgressBar: true,
              theme: "colored"
          })
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const fetchClasses = () => {
      axios.get(url, {
          params: {
            loadClasses: "loadClasses"
          }
        })
        .then(function (response) {
          
          if(response.data.response){ 
            let classes = JSON.parse(response.data.classes)
            setprecedingClasses([...classes])
          }else{ 
              
          }
          
        })
        .catch(function (error) {
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve classes",{
              hideProgressBar: true,
              theme: "colored"
          })
        });
      // let preceding = [
      //   {id: "aoiuhfuafuaf", name: "Primary 1"},
      //   {id: "aoiuhfuaffaf", name: "Primary 2"},
      //   {id: "aoiuhfuafuaf", name: "Primary 3"}
      // ]
      // setprecedingClasses([...preceding])
    }
    fetchClasses()
  }, [isUploading, url]);

  
  return (
    <div>
      <h1>New Class</h1>
      <div className="divider"></div>

      <div className="content">
        <form id='form' action="" onSubmit={submit}>
            <fieldset>
                <legend>Class Information</legend>
                <div className="grid">
                    <div className="form-div">
                        <label htmlFor="classname">Class Name</label>
                        <input id='classname' type="text" name='classname' value={inputs.classname || ""} onChange={handleInputChange} className='input' minLength={2} maxLength={25} required
                        placeholder='Class Name eg. Primary 1, Junior High 2'
                        title='Class Name eg. Primary 1, Primary 2, Junior High 2, etc' />
                    </div>
                    
                    <div className="form-div">
                        <label htmlFor="preceding">Preceding Class <span className='mini'>(*Base Class is the starting class)</span></label>
                        <select id='preceding' name='preceding' value={inputs.preceding || ""} onChange={handleInputChange} className='input' required
                        title='This is the class that comes before this new class'>
                            <option value=''>--Select Preceding Class--</option>
                            <option value='base'>Base Class*</option>
                            {precedingClasses.map((item) =>{
                              const {id, name} = item
                              return <option key={id} value={id}>{name}</option>
                            })}
                        </select>
                    </div>
                    
                    
                </div>
   
            </fieldset>
           

            <div className="form-div center">
                <button type='submit' className='submit'>Create Class</button>
            </div>
        </form>
      </div>

      <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage}/>

      <ToastContainer />
      {isUploading && <Loading/>}

    </div>
  )
}
