import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SingleStaff from './SingleStaff'
import MiniLoading from './utils/MiniLoading'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { SERVER_URL, APP_NAME } from '../App'
import AlertDialog from './utils/AlertDialog'
import Loading from './Loading'
import { BsDownload } from 'react-icons/bs'
import { useGlobalContext } from '../AppContext'
import { BiUserPlus } from 'react-icons/bi'

export default function Staff() {
  document.title = APP_NAME + " | Staff"

  const { setGlobalStaff } = useGlobalContext() //very IMPORTANT!!
  const [query, setQuery] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [staff, setStaff] = useState([])
  const [foundStaff, setFoundStaff] = useState([])// secondary staff array, to help with search
  const [open, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [isUploading, setIsUploading] = useState("")
  const [staffToArchive, setStaffToArchive] = useState("")
  const [runOnce, setRunOnce] = useState(false)
  const [showAll, setShowAll] = useState(false)//


  const searchOnChange = (text) => {
    setQuery(text)
    search(text)
  }

  const search = (text) => {

    if (text === "" || staff.length === 0) {// no need to search
      setFoundStaff(staff.slice(0, 10))
      return
    }

    const foundStaff = staff.filter((single_staff) => {
      const name = single_staff.firstname + " " + single_staff.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setFoundStaff(foundStaff)
  }

  const submit = (e) => {
    e.preventDefault()
    search(query)
  }

  const removeStaff = (staffId) => {
    //when the archive button is clicked in single_staff
    let filteredStaff = staff.filter((single_staff) => {
      return single_staff.id !== staffId
    })
    setStaff(filteredStaff)
    filteredStaff = foundStaff.filter((single_staff) => {
      return single_staff.id !== staffId
    })
    setFoundStaff(filteredStaff)
  }

  const archive = (staffId) => {
    setStaffToArchive(staffId)
    //confirm
    setAlertTitle("Archive Staff!")
    let message = `Archiving a staff is like deleting the staff, but their data will still be available
    in the archives. This action is not reversible. Do you still want to proceed?`
    setAlertMessage(message)
    setAlertOpen(true);
    //submit in alert handleclose
  }

  const handleClose = (option) => {
    setAlertOpen(false);
    if (!staffToArchive) return
    if (option) {
      //show loading spinner
      setIsUploading(true)
      const url = SERVER_URL + "archive.php"
      const formData = new FormData()
      formData.append("archiveStaff", staffToArchive);

      axios.post(url, formData)
        .then(function (response) {
          //hide loading spinner
          setIsUploading(false)
          if (response.data.response) {
            removeStaff(staffToArchive) // called to parent view
            setStaffToArchive("")
            toast.success("Staff has been permanently archived", {
              hideProgressBar: true,
              theme: "colored"
            })
          } else {
            toast.error(response.data.message, {
              hideProgressBar: true,
              theme: "colored"
            })
          }

        })
        .catch(function (error) {
          //hide loading spinner
          setIsUploading(false)
          toast.error("An Error Occured", {
            hideProgressBar: true,
            theme: "colored"
          })
          console.log(error);
        });
    }
  }

  const showChecked = (e) => {
    setShowAll(e.target.checked)
    if (staff.length < 11) return

    if (e.target.checked) {
      setFoundStaff(staff)
    } else {
      setFoundStaff(staff.slice(0, 10))//only show 10
    }
  }

  useEffect(() => {
    const url = SERVER_URL + "staff.php"
    const fetchStaff = () => {
      axios.get(url, {
        params: {
          loadStaff: "loadStaff"
        }
      })
        .then(function (response) {
          setIsLoading(false)
          if (response.data.response) {

            let staff = JSON.parse(response.data.staff)
            // console.log(staff) 
            if (staff.length === 0) {
              setStaff([])
              setFoundStaff([])

              toast.info("No staff in database", {
                hideProgressBar: true,
                theme: "colored"
              })
              return
            }
            setStaff([...staff])
            setFoundStaff(staff.slice(0, 10))

          } else {

          }

        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve staff", {
            hideProgressBar: true,
            theme: "colored"
          })
        });

    }

    fetchStaff()
  }, []);

  useEffect(() => {
    if (staff.length > 0 && !runOnce) {
      setRunOnce(true)
      setGlobalStaff(staff)
    }

  }, [staff, setGlobalStaff, runOnce]);

  return (
    <div>
      <h1>Staff</h1>
      <div className="divider"></div>

      <div className="top">

        <form action="" className="" onSubmit={submit}>
          <input type="search" className='input' placeholder='Search' value={query} onChange={e => searchOnChange(e.target.value)} />
          <button type='submit' className='submit'>Search</button>
        </form>

        {staff.length > 0 &&
          <a href={`http://localhost/api/reports/staff.php`} target={'_blank'} rel={'noreferrer'}>
            <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
          </a>
        }


        <div style={{ display: "flex", gap: "10px" }}>
          <Link to={`attendance/set`}>
            <button type='button' className='button set_atn_btn'>Set Attendance</button>
          </Link>
          <Link to={`attendance/view`}>
            <button type='button' className='button view_atn_btn'>View Attendance</button>
          </Link>
          <Link to="new-staff">
            <button type='button' className='button'><BiUserPlus /> Add New Staff</button>
          </Link>
        </div>

      </div>

      <div className="content">
        {staff.length > 0 &&
          <div className="user_list">

            <h4 className='grey'>
              {staff.length} Total Staff
              <span className="smaller_ft"> (showing {foundStaff.length})</span>
            </h4>
            <input type="checkbox" value={showAll} onChange={e => showChecked(e)} /> Show All

            <table className='user_list_table'>
              <tbody>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Age</th>
                  <th>Date of Birth</th>
                  <th>Gender</th>
                  <th>Date Employed</th>
                  <th>Action</th>
                </tr>
                {foundStaff.map((single_staff) => {
                  return <SingleStaff key={single_staff.id} staff={single_staff} removeStaff={removeStaff} archiveStaff={archive} />
                })}
              </tbody>

            </table>
          </div>
        }

        {isLoading && <MiniLoading />}

      </div>

      {isUploading && <Loading />}
      <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
      <ToastContainer />

    </div>
  )
}
