import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { SERVER_URL } from '../../App'


export default function MigrationModal() {

    const [isUploading, setIsUploading] = useState(false)
    const [finalClassName, setFinalClassName] = useState("")
    const [finalClassId , setFinalClassId] = useState("")
    const [moveClasses , setMoveClasses] = useState(false)

    const submit = (e) => {
        e.preventDefault()
        if(!finalClassId){
            toast.info("Could not retrieve Graduating class info. Please try again later",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
        document.getElementById("mg_btn").disabled = true;
        setIsUploading(true)

        const url = SERVER_URL+"settings.php"
        const formData = new FormData()
        formData.append("migrateClasses", finalClassId)
        formData.append("moveClasses", moveClasses)

        axios.post(url, formData)
        .then(function (response) {
            //hide loading spinner
            setIsUploading(false)
            document.getElementById("mg_btn").disabled = false;
            if(response.data.response){
                closeModal()
                toast.success("Migration Completed",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }else{
                toast.error(response.data.message,{
                    hideProgressBar: true,
                    theme: "colored"
                })
            }
            
        })
        .catch(function (error) {
            //hide loading spinner
            setIsUploading(false)
            document.getElementById("mg_btn").disabled = false;
            toast.error("An Error Occured",{
                hideProgressBar: true,
                theme: "colored"
            })
            console.log(error);
        });
    }

    const closeModal = () => {
        document.getElementById("migrateModal").style.display = "none"
    }

    //fetch final year class
    useEffect(() => {
        
        const fetchInfo = () => {
            const url = SERVER_URL+"settings.php"
            axios.get(url, {
              params: {
                getFinalClass: "getFinalClass"
              }
            })
            .then(function (response) {
              
              if(response.data.response){ 
                setFinalClassId(response.data.class.id)
                setFinalClassName(response.data.class.name)
                
              }else{ 
                
                // toast.info("No info to retrieve",{
                //     hideProgressBar: true,
                //     theme: "colored"
                // })
              }
              
            })
            .catch(function (error) {
              console.log(error)
              toast.error("An Error Occured. Couldn't retrieve info",{
                  hideProgressBar: true,
                  theme: "colored"
              })
            });
          
        }
        fetchInfo()

    }, []);

  return (
    <div id="migrateModal" className="modal">
        <div className="modal-content">
            <div className="modal-header">
                <span onClick={closeModal} className="close">&times;</span>
                <h3>Migrate Students</h3>
                <h5>Final Year Class: {finalClassName}</h5>
                <p className="grey smaller_ft">
                    (If the final year class is empty, no students will be moved as graduates)
                </p>
            </div>
            
            <div className="">
                <form action="" onSubmit={submit}>
                    <div>
                        <input type="checkbox" name='grads' disabled value={1} defaultChecked={true}/> Graduates
                    </div>
                    <div>
                        <input type="checkbox" name='classes' value={2} onChange={(e) => setMoveClasses(e.target.checked)}/> Class {'->'} Next Class
                    </div>
                
                <div className="center">
                    <button id='mg_btn' className='file_button button' type='submit'>Migrate</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
                </form>
                
                
            </div>
            
        </div>

    </div>
  )
}
