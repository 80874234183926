import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { isValidPhoneNumber, convertPhoneNumber } from '../../functions'
import { HUBTEL_CLIENT_KEY, HUBTEL_CLIENT_SECRET, SERVER_URL } from '../../App'
import axios from 'axios'

export default function SMSModal({message, setMessage}) {

    const [number, setNumber] = useState("")
    const [rate, setRate] = useState(0.02)//sms rate
    const [isUploading, setIsUploading] = useState(false)

    const submit = async (e) => {
        e.preventDefault()
        if(!isValidPhoneNumber(number)) {
            toast.error("Invalid Phone Number",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        setIsUploading(true)
        let phone = convertPhoneNumber(number)
        const query = new URLSearchParams({
            clientid: HUBTEL_CLIENT_KEY,
            clientsecret: HUBTEL_CLIENT_SECRET,
            from: 'Helios',
            to: phone,
            content: message
          }).toString();
        
        const resp = await fetch(
        `https://smsc.hubtel.com/v1/messages/send?${query}`,
        {method: 'GET'}
        );
        // console.log(resp, resp.message, resp.responseCode)
        if(resp.status === 201){
            toast.success("Message Sent",{
                hideProgressBar: true,
                theme: "colored"
            })
            closeModal()
            const data = await resp.text();
            setRate(data.rate)
            recordSMS()// save sms transaction in database
        }else{
            toast.error("Message Could Not Be Sent",{
                hideProgressBar: true,
                theme: "colored"
            })
        }
        // const data = await resp.text();
        // console.log(data);

        setIsUploading(false)
        
    }

    const closeModal = () => {
        document.getElementById("smsModal").style.display = "none"
    }

    const recordSMS = () => {
        const url = SERVER_URL+"sms.php"
        const formData = new FormData()
        formData.append("recordSMS", number)
        formData.append("rate", rate)
        formData.append("message", message)
        formData.append("num_recipients", 1)

        axios.post(url, formData)
        .then(function (response) {
            
            if(response.data.response){
                console.log("record saved")
                
            }else{
                console.log("record not saved")
            }
            
        })
        .catch(function (error) {
            
            console.log(error);
        }); 
    }

  return (
    <div id="smsModal" className="modal">
        <div className="modal-content">
            <div className="modal-header">
                <span onClick={closeModal} className="close">&times;</span>
                <h3>Send SMS</h3>
            </div>
            
            <div className="">
                <form action="" onSubmit={submit}>
                    <div className='form-div'>
                        <label htmlFor="phone">Phone Number</label>
                        <input id="phone" type="text" name='number' className='input' placeholder='Phone Number e.g 0241234567' value={number} onChange={(e) => setNumber(e.target.value)} minLength={10} maxLength={10} required/>
                    </div>
                    <div className='form-div'>
                        <label htmlFor="message">Message</label>
                        <textarea id='message' className='input' placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} rows={5} required/>
                    </div>
                
                <div className="center" style={{display: "flex", justifyContent: 'center', gap:'10px'}}>
                    <button id='mg_btn' className='file_button button' type='submit'>Send Sample</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
                </form>
                
                
            </div>
            
        </div>

    </div>
  )
}
