import React, { useState, useEffect } from 'react'
import '../css/students.css'
import { Link } from 'react-router-dom'
import MiniLoading from './utils/MiniLoading'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { SERVER_URL, APP_NAME } from '../App'
import Student from './Student'
import AlertDialog from './utils/AlertDialog'
import Loading from './Loading'
import { useGlobalContext } from '../AppContext'
import { BsDownload } from "react-icons/bs";
import { BiUserPlus } from 'react-icons/bi'


export default function Students() {
  document.title = APP_NAME + " | Students"
  // const {setLoggedIn} = useGlobalContext()

  const { setStudentType } = useGlobalContext() //very IMPORTANT!!

  const [query, setQuery] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [students, setStudents] = useState([])
  const [foundStudents, setfoundStudents] = useState([])// secondary students array, to help with search
  const [open, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [isUploading, setIsUploading] = useState("")
  const [studentToArchive, setStudentToArchive] = useState("")
  const [typeRun, setTypeRun] = useState(false)// this is all to beat react warning of useeffect dependency
  const [showAll, setShowAll] = useState(false)//

  const searchOnChange = (text) => {
    // console.log("on change ", text);
    setQuery(text)
    search(text)
  }

  const search = (text) => {
    // console.log("search submit "+text)

    if (text === "" || students.length === 0) {// no need to search
      setfoundStudents(students.slice(0, 10)) // show 10 max
      return
    }

    const foundStudents = students.filter((student) => {
      const name = student.firstname + " " + student.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setfoundStudents(foundStudents)
  }

  const submit = (e) => {
    e.preventDefault()
    search(query)
  }

  const removeStudent = (studentId) => {
    //when the archive button is clicked in student
    let filteredStudents = students.filter((student) => {
      return student.id !== studentId
    })
    setStudents(filteredStudents)
    filteredStudents = foundStudents.filter((student) => {
      return student.id !== studentId
    })
    setfoundStudents(filteredStudents)
  }

  const archive = (studentId) => {
    setStudentToArchive(studentId)
    //confirm
    setAlertTitle("Archive Student!")
    let message = `Archiving a student is like deleting the student, but their data will still be available
    in the archives. This action is not reversible. Do you still want to proceed?`
    setAlertMessage(message)
    setAlertOpen(true);
    //submit in alert handleclose
  }

  const handleClose = (option) => {
    setAlertOpen(false);
    if (!studentToArchive) return
    if (option) {
      //show loading spinner
      setIsUploading(true)
      const url = SERVER_URL + "archive.php"
      const formData = new FormData()
      formData.append("archiveStudent", studentToArchive);

      axios.post(url, formData)
        .then(function (response) {
          //hide loading spinner
          setIsUploading(false)
          if (response.data.response) {
            removeStudent(studentToArchive) // called to parent view
            setStudentToArchive("")
            toast.success("Student has been permanently archived", {
              hideProgressBar: true,
              theme: "colored"
            })
          } else {
            toast.error(response.data.message, {
              hideProgressBar: true,
              theme: "colored"
            })
          }

        })
        .catch(function (error) {
          //hide loading spinner
          setIsUploading(false)
          toast.error("An Error Occured", {
            hideProgressBar: true,
            theme: "colored"
          })
          console.log(error);
        });
    }
  }

  const showChecked = (e) => {
    setShowAll(e.target.checked)
    if (students.length < 11) return

    if (e.target.checked) {
      setfoundStudents(students)
    } else {
      setfoundStudents(students.slice(0, 10))//only show 10
    }
  }
  // const setType = useCallback(() => {
  //   console.log("call back changes")
  //   setStudentType("student")
  // }, [setStudentType])

  useEffect(() => {
    if (!typeRun) {// this is all to beat react warning of useeffect dependency
      setStudentType("student")
      setTypeRun(true)
    }


    const url = SERVER_URL + "students.php"
    const fetchStudents = () => {
      axios.get(url, {
        params: {
          loadStudents: "loadStudents"
        }
      })
        .then(function (response) {
          setIsLoading(false)
          if (response.data.response) {
            let students = JSON.parse(response.data.students)
            // console.log(students) 
            if (students.length === 0) {
              setStudents([])
              setfoundStudents([])
              toast.info("No students in database", {
                hideProgressBar: true,
                theme: "colored"
              })
              return
            }
            setStudents(students)
            setfoundStudents(students.slice(0, 10))//only show 10 on load

          } else {

          }

        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve students", {
            hideProgressBar: true,
            theme: "colored"
          })
        });

    }

    fetchStudents()
  }, [setStudentType, setTypeRun, typeRun]);



  return (
    <div>
      <h1>Students</h1>
      <div className="divider"></div>

      <div className="top">

        <form action="" className="" onSubmit={submit}>
          <input type="search" className='input' placeholder='Search' value={query} onChange={e => searchOnChange(e.target.value)} />
          <button type='submit' className='submit'>Search</button>
        </form>

        {students.length > 0 &&
          <a href="http://localhost/api/reports/students.php" target={'_blank'} rel={'noreferrer'}>
            <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
          </a>
        }


        <div style={{ display: "flex", gap: "10px" }}>


          <Link to="upload">
            <button type='button' className='button none'>Upload</button>
          </Link>

          <Link to="new-student">
            <button type='button' className='button'><BiUserPlus /> Add New Student</button>
          </Link>
        </div>

      </div>

      <div className="content">

        {students.length > 0 &&
          <div className="user_list">

            <h4 className='grey'>
              {students.length} Total {students.length === 1 ? "Student" : "Students"}
              <span className="smaller_ft"> (showing {foundStudents.length})</span>
            </h4>
            <input type="checkbox" value={showAll} onChange={e => showChecked(e)} /> Show All

            <table className='user_list_table'>
              <tbody>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Date of Birth</th>
                  <th>Gender</th>
                  <th>Class</th>
                  <th>Date Enrolled</th>
                  <th>Actions</th>
                </tr>

                {foundStudents.map((student) => {
                  return <Student key={student.id} student={student} removeStudent={removeStudent} archiveStudent={archive} />
                })}
                {/* <Student/> */}
              </tbody>

            </table>
          </div>
        }


        {isLoading && <MiniLoading />}

      </div>

      {isUploading && <Loading />}
      <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
      <ToastContainer />

    </div>
  )
}
