import React from 'react'

export default function Loading() {
  return (
    <div>
      <div id="loader" className="">
        <div className="loading"></div>
        </div>
    </div>
  )
}
