import React from 'react'
import { Link } from 'react-router-dom';
import { getProcessedDate, getAge } from '../functions';
import { IMAGE_URL } from '../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../AppContext';

export default function Student({ student, isArchive, isGraduate, archiveStudent, isAttendance, inputs, handleInputChange, viewAttendance, isFeeRecords }) {

  const { setActiveStudent } = useGlobalContext()

  const date = getProcessedDate(student.dob)
  const e_date = getProcessedDate(student.date_enrolled)
  const age = getAge(student.dob)

  let student_class = student.class ? student.class : "Not Assigned"
  student_class = isGraduate ? `Graduate (${student.grad_year})` : student_class
  let link = `/students/${student.id}`
  if (isArchive) {
    link = `/archive/student/${student.id}/a`
  } else if (isGraduate) {
    link = `/archive/student/${student.id}/g`
  }

  const click = () => {
    console.log("view clicked")
    setActiveStudent(student)
  }

  const addFeeRecord = () => {
    setActiveStudent(student)
  }

  if (isAttendance) {
    return (
      <>
        <tr className='user_item'>
          <td>
            <input type="checkbox" className='checkbox' name={student.id} defaultChecked={inputs[student.id]} onChange={handleInputChange} />
          </td>
          <td className='image_cell'>
            <img alt='student' className='user_image' src={IMAGE_URL + student.image} />
          </td>
          <td>
            <span className='bolder'>{student.firstname} {student.lastname}</span>
          </td>

        </tr>
      </>
    )
  }

  if (viewAttendance) {
    const attended = student.attended === "yes" ? "YES" : "NO"
    return (
      <>
        <tr className='user_item'>

          <td className='image_cell'>
            <img alt='student' className='user_image' src={IMAGE_URL + student.image} />
          </td>
          <td>
            <span className='bolder'>{student.firstname} {student.lastname}</span>
          </td>
          <td className={attended}>{attended}</td>

        </tr>
      </>
    )
  }

  if (isFeeRecords) {
    return (
      <>
        <tr className='user_item'>
          <td className='image_cell'>
            <img alt='student' className='user_image' src={IMAGE_URL + student.image} />
          </td>
          <td>
            <span className='bolder'>{student.firstname} {student.lastname}</span>
          </td>
          <td>
            <div className="flex_space">
              <span className=''>
                <Link to={`add-fee-record`} onClick={addFeeRecord}>
                  <button className='action_btn button'>Add Record</button>
                </Link>
              </span>
              <span>
                <Link to={`view-fee-record`} onClick={addFeeRecord}>
                  <button className='action_btn button'>View Records</button>
                </Link>
              </span>
            </div>
          </td>

        </tr>
      </>
    )
  }

  return (
    <>

      <tr className='user_item'>
        <td className='image_cell'>
          <img alt='student' className='user_image' src={IMAGE_URL + student.image} />
        </td>
        <td>
          <span className='bolder'>{student.firstname} {student.lastname}</span>
        </td>
        <td>
          <span>{age} yrs</span>
        </td>
        <td>
          <span>{date}</span>
        </td>
        <td>
          <span>{student.gender}</span>
        </td>
        <td>
          <span>{student_class}</span>
        </td>
        {/* <td>
          <span>B357/7 Kaneshie Second Nim Tree Close</span>
        </td> */}
        <td>
          <span>{e_date}</span>
        </td>

        <td>
          <div className="flex_space">
            <span>
              <Link to={link} onClick={click}>
                <button className='action_btn button'>View</button>
              </Link>
            </span>
            <span className='none'>
              <Link to={`/add-record/${student.id}/${student.class_id}`}>
                <button className='action_btn button'>Add Record</button>
              </Link>
            </span>
            <span className='none'>
              <Link to={`/view-record/${student.id}`}>
                <button className='action_btn button'>View Records</button>
              </Link>
            </span>
            <span className={isArchive || isGraduate ? 'none' : ''} title='Edit Student'>
              <Link to={`/students/edit/${student.id}`}>
                <FontAwesomeIcon icon={faPenToSquare} size="lg" />
              </Link>
            </span>
            <span className='none'>
              <FontAwesomeIcon icon={faTrashCan} size="lg" />
            </span>
            <span className={isArchive || isGraduate ? 'none' : ''} title='Archive Student' onClick={() => archiveStudent(student.id)}>
              <FontAwesomeIcon icon={faBoxArchive} size="lg" />
            </span>
          </div>

        </td>

      </tr>

    </>


  )
}
