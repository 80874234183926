import React, { useState } from 'react'
import placeholder from '../placeholder.png'
import logo from '../logos/white.png'
import { useGlobalContext } from '../AppContext'
import { NavLink } from 'react-router-dom'
import AlertDialog from './utils/AlertDialog'
import { APP_NAME, VERSION_NUMBER } from '../App'
import { BiPieChartAlt2, BiUser, BiUserPin, BiBuildings, BiBookOpen,
     BiFolderOpen, BiCog, BiArchive, BiLogOut, BiMessage, BiMoney } from "react-icons/bi";

export default function Sidebar() {
    const { setLoggedIn, setAdminInfo, appState } = useGlobalContext()
    const [open, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const { firstname, lastname, username } = appState.adminInfo

    const logOut = () => {
        //confirm preceding class
        setAlertTitle("Confirm Log Out")
        setAlertMessage("Are you sure you want to log out?")
        setAlertOpen(true);
        //submit in alert handleclose
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            window.history.pushState("string", "Title", "/")
            setAdminInfo({})
            setLoggedIn(false)
        }
    };

    // following are the code to change sidebar button(optional)
    function menuBtnChange() {
        let sidebar = document.getElementById("sidebar");
        let closeBtn = document.getElementById("btn");
        sidebar.classList.toggle("open");

        if (sidebar.classList.contains("open")) {
            closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
        } else {
            closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");//replacing the iocns class
        }
    }

    //   function navClick(e, num) {
    //     switchView(num)
    //     // Get all elements with class="tablinks" and remove the class "active"
    //     var tablinks = document.getElementsByClassName("tab");
    //     for (var i = 0; i < tablinks.length; i++) {
    //         tablinks[i].className = tablinks[i].className.replace(" active", "");
    //     }
    //     // add an "active" class to the button that opened the tab
    //     e.currentTarget.className += " active";

    //   }

    return (
        <div className="sidebar open" id="sidebar">
            <div className="logo-details">
                {/* <i className='bx bxl-c-plus-plus icon'></i> */}
                <img src={logo} alt="" className="sidebar_logo" />
                <div className="logo_name">
                    {APP_NAME}
                    <p className='version_ft'>{VERSION_NUMBER}</p>
                </div>

                <i className='bx bx-menu' id="btn" onClick={menuBtnChange} ></i>
            </div>
            <ul className="nav-list">
                {/* <li>
                <i className='bx bx-search' onClick={menuBtnChange}></i>
                <input type="text" placeholder="Search..."></input>
                <span className="tooltip">Search</span>
            </li> */}
                <li>
                    <NavLink to="/" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-pie-chart-alt-2'></i> */}
                            <BiPieChartAlt2 className='box-icon' />
                            <span className="links_name">Dashboard</span>
                        </button>
                        <span className="tooltip">Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/students" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button className='tab'>
                            {/* <i className='bx bx-user' ></i> */}
                            <BiUser className='box-icon' />
                            <span className="links_name">Students</span>
                        </button>
                        <span className="tooltip">Students</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/staff" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button className='tab' >
                            {/* <i className='bx bx-user-pin' ></i> */}
                            <BiUserPin className='box-icon' />
                            <span className="links_name">Staff</span>
                        </button>
                        <span className="tooltip">Staff</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/classes" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button className='tab' >
                            {/* <i className='bx bx-building' ></i> */}
                            <BiBuildings className='box-icon' />
                            <span className="links_name">Classes</span>
                        </button>
                        <span className="tooltip">Classes</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/subjects" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-folder' ></i> */}
                            <BiBookOpen className='box-icon' />
                            <span className="links_name">Subjects</span>
                        </button>
                        <span className="tooltip">Subjects</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/examinations" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-file' ></i> */}
                            <BiFolderOpen className='box-icon' />
                            <span className="links_name">Examinations</span>
                        </button>
                        <span className="tooltip">Exams</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/fees" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-file' ></i> */}
                            <BiMoney className='box-icon' />
                            <span className="links_name">Fees</span>
                        </button>
                        <span className="tooltip">Fees</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to="/settings" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-cog' ></i> */}
                            <BiCog className='box-icon' />
                            <span className="links_name">Settings</span>
                        </button>
                        <span className="tooltip">Settings</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/archive" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-archive' ></i> */}
                            <BiArchive className='box-icon' />
                            <span className="links_name">Archive</span>
                        </button>
                        <span className="tooltip">Archive</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/sms" className={({ isActive }) => { return isActive ? "active" : "" }}>
                        <button>
                            {/* <i className='bx bx-archive' ></i> */}
                            <BiMessage className='box-icon' />
                            <span className="links_name">SMS & Email</span>
                        </button>
                        <span className="tooltip">SMS & Email</span>
                    </NavLink>
                </li>

                <li className="profile">
                    <div className="profile-details">
                        <img src={placeholder} alt="profileImg" />
                        <div className="name_job">
                            <div className="name" title={`${firstname} ${lastname}`}>{firstname} {lastname}</div>
                            <div className="job" title={`${username}`}>{username}</div>
                        </div>
                    </div>
                    {/* <i className='bx bx-log-out' id="log_out" title='Logout' onClick={logOut}></i> */}
                    <div className="log_out_box">
                        <BiLogOut className='box-icon' title='Logout' onClick={logOut} />
                    </div>

                </li>

            </ul>

            <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
        </div>
    )
}
