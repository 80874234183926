import React, { useEffect, useState } from 'react'
import AlertDialog from '../utils/AlertDialog';
import Loading from '../Loading';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../App';
import axios from 'axios';
import { schoolTerms } from '../../functions';

export default function ClassFees() {
    const [inputs, setInputs] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [open, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [classes, setClasses] = useState([])
    const [schoolYears, setSchoolYears] = useState([])
    const url = SERVER_URL+"fees.php"

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const submit = (e) => {
        e.preventDefault()
    
        //validate all inputs
        for ( var key in inputs ) {
            if(inputs[key] === ""){
                toast.error(key + " cannot be empty",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }
        if(isNaN(inputs.amount)){
            toast.error("Invalid amount value",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
    
        //confirm preceding class
        setAlertTitle("Set Fees for Class")
        setAlertMessage("Proceed with fee amount?")
        setAlertOpen(true);
        //submit in alert handleclose
        
    
    }

    const reset = () => {
        setInputs({})
        document.getElementById('form').reset()
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if(option){
            //show loading spinner
            setIsUploading(true)
            const formData = new FormData()
            for ( var key in inputs ) {
                formData.append(key, inputs[key]);
            }
        
            axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if(response.data.response){
                    reset()
                    toast.success(response.data.message,{
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }else{
                    toast.error(response.data.message,{
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }
                
            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
        }
    };

    useEffect(() => {
        const fetchClasses = () => {
            const url = SERVER_URL+"fees.php"
            axios.get(url, {
                params: {
                loadClasses: "loadClasses",
                loadSchoolYears: "loadSchoolYears"
                }
            })
            .then(function (response) {
                
                if(response.data.response){ 
                    let classes = JSON.parse(response.data.classes)
                    let schoolYears = JSON.parse(response.data.schoolYears)
                    setClasses([...classes])
                    setSchoolYears(schoolYears)
                }else{ 
                    
                }
                
            })
            .catch(function (error) {
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve classes",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            });
            
        }
        fetchClasses()
    }, []);

  return (
    <div>
        <h1>Set Fees for Class</h1>
        <div className="divider"></div>

        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Set Class Fees</legend>
                    <div className="grid grid4">
                        
                        <div className="form-div">
                            <label htmlFor="class">Class</label>
                            <select id='class' name='class' value={inputs.class || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Class--</option>
                                {classes.map((item) =>{
                                const {id, name} = item
                                return <option key={id} value={id}>{name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="school_year">School Year</label>
                            <select id='school_year' name='school_year' value={inputs.school_year || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select School Year--</option>
                                {schoolYears.map((item) =>{
                                const {id, year} = item
                                return <option key={id} value={id}>{year}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="term">Term</label>
                            <select id='term' name='term' value={inputs.term || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Term--</option>
                                {schoolTerms.map((item) =>{
                                    return <option key={item} value={item}>{item}</option>
                                })}
                                
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="amount">Amount (GHS)</label>
                            <input id='amount' type="number" name='amount' value={inputs.amount || ""} onChange={handleInputChange} className='input' minLength={1} maxLength={10} required
                            placeholder='Amount' />
                        </div>
                        
                        
                    </div>
    
                </fieldset>
            

                <div className="form-div center">
                    <button type='submit' className='submit'>Save</button>
                </div>
            </form>
        </div>
      
        <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage}/>
        {isUploading && <Loading/>}
    </div>
  )
}
