import React, {useState, useEffect} from 'react'
import '../css/form.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Loading from './Loading'
import {SERVER_URL, APP_NAME} from '../App'
import AlertDialog from './utils/AlertDialog'
import MiniLoading from './utils/MiniLoading';

export default function Subjects() {

    document.title = APP_NAME+" | Subjects"

    const [inputs, setInputs] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(true) //fetching subjects
    const [open, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [subjects, setSubjects] = useState([])
    const url = SERVER_URL+"new-subject.php"

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const submit = (e) => {
        e.preventDefault()
    
        //validate all inputs
        for ( var key in inputs ) {
            if(inputs[key] === ""){
                toast.error(key + " cannot be empty",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }
    
        //confirm preceding subject
        setAlertTitle("Confirm Subject")
        setAlertMessage("Are you sure you typed the right subject name?")
        setAlertOpen(true);
        //submit in alert handleclose
        
    
    }
    
    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if(option){
          //show loading spinner
          setIsUploading(true)
          const formData = new FormData()
          for ( var key in inputs ) {
              formData.append(key, inputs[key]);
          }
        
          axios.post(url, formData)
            .then(function (response) {
              //hide loading spinner
              setIsUploading(false)
              if(response.data.response){
                let id = response.data.id
                setSubjects(values => ([...values, {id, name: inputs.subjectname}]))
                reset()
                toast.success("New Subject Created",{
                    hideProgressBar: true,
                    theme: "colored"
                })
              }else{
                  toast.error(response.data.message,{
                      hideProgressBar: true,
                      theme: "colored"
                  })
              }
              
            })
            .catch(function (error) {
              //hide loading spinner
              setIsUploading(false)
              toast.error("An Error Occured",{
                  hideProgressBar: true,
                  theme: "colored"
              })
              console.log(error);
            });
        }
    };

    const reset = () => {
        setInputs({})
        document.getElementById('form').reset()
    }

    useEffect(() => {
        // const url = SERVER_URL+"subjects.php"
        const fetchSubjects = () => {
          axios.get(url, {
              params: {
                loadSubjects: "loadSubjects"
              }
            })
            .then(function (response) {
              setIsLoading(false)
              if(response.data.response){ 
                let subjects = JSON.parse(response.data.subjects)
                // console.log(subjects) 
                if(subjects.length === 0){
                  setSubjects([])
                  toast.info("No subjects in database",{
                    hideProgressBar: true,
                    theme: "colored"
                  })
                  return
                }
                setSubjects([...subjects])
                
              }else{ 
                  
              }
              
            })
            .catch(function (error) {
              setIsLoading(false)
              console.log(error)
              toast.error("An Error Occured. Couldn't retrieve subjects",{
                  hideProgressBar: true,
                  theme: "colored"
              })
            });
         
        }
    
        fetchSubjects()
    }, [url]);

  return (
    <div>
        <h1>Subjects</h1>
        <div className="divider"></div>
      
        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Add New Subject</legend>
                    <div className="grid">
                        <div className="form-div">
                            <label htmlFor="subjectname">Subject Name</label>
                            <input id='subjectname' type="text" name='subjectname' value={inputs.subjectname || ""} onChange={handleInputChange} className='input' minLength={2} maxLength={25} required
                            placeholder='Subject Name eg. English, Mathematics, Science'
                            title='Subject Name eg. English, Mathematics, Science, etc' />
                        </div>
                        <div className="form-div" style={{display: "flex", alignItems: 'flex-end'}}>
                            <button type='submit' className='submit'>Create Subject</button>
                        </div>
                    </div>
    
                </fieldset>
            
            </form>

            <div className="user_list">

                {subjects.length > 0 && <h4 className='grey'>{subjects.length} Total {subjects.length === 1 ? "Subject" : "Subjects"}</h4>}

                <table className='user_list_table'>
                    <tbody>
                    {subjects.length > 0 && 
                    <tr>
                        <th>Subject</th> 
                    </tr>
                    } 
                    
                    {subjects.map((subject) => {
                        return (
                            <tr className='user_item' key={subject.id}>
                                <td>{subject.name}</td> 
                            </tr>
                        )
                    })}
                    </tbody>
                    
                </table>
            </div>

            {isLoading && <MiniLoading/>}

        </div>

        <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage}/>

        <ToastContainer />
        {isUploading && <Loading/>}

    </div>
  )
}
