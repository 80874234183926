import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/Poppins/Poppins-Regular.ttf'
import './fonts/Poppins/Poppins-Bold.ttf'
import './fonts/Roboto/Roboto-Regular.ttf'
import './fonts/Roboto/Roboto-Bold.ttf'
import './css/index.css';
import './css/style.css';
import './css/modal.css';
import './css/user.css';
import './css/form.css'
import 'react-toastify/dist/ReactToastify.css'; // for toast notifications
import App from './App';
import AppContext from './AppContext'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContext>
        <App />
      </AppContext>
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
