import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { SERVER_URL, APP_NAME } from '../App'
import MiniLoading from './utils/MiniLoading'
import Loading from './Loading'
import AlertDialog from './utils/AlertDialog'
import { getProcessedDate } from '../functions'

export default function AddRecord() {
    const { id, classId } = useParams()
    if (id === undefined || classId === undefined) window.history.back()

    document.title = APP_NAME + " | New Examination Record"

    const [inputs, setInputs] = useState({})
    const [student, setStudent] = useState({})
    const [class_subjects, setClassSubjects] = useState([])
    const [examinations, setExaminations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isUploading, setIsUploading] = useState(false)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [open, setAlertOpen] = React.useState(false);
    const url = SERVER_URL + "records.php"

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    useEffect(() => {
        const url = SERVER_URL + "records.php"
        const fetchClassSubjects = () => {
            axios.get(url, {
                params: {
                    loadClassSubjects: classId,
                    loadStudentInfo: id,
                    loadExaminations: "loadExaminations"
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let students = JSON.parse(response.data.students)
                        let class_subjects = JSON.parse(response.data.class_subjects)
                        let examinations = JSON.parse(response.data.examinations)

                        // console.log(students, class_subjects)
                        setStudent(students[0])
                        setClassSubjects(class_subjects)
                        setExaminations(examinations)


                    } else {

                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve student and subject info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchClassSubjects()
    }, [id, classId]);

    const submit = (e) => {
        e.preventDefault()
        if (!inputs.hasOwnProperty('examination')) {
            toast.error("Select examination", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (Object.keys(inputs).length < 2) {
            //at least 1 mark required + examination field
            toast.info("No marks entered", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //validate all inputs
        for (var key in inputs) {
            if (inputs[key] === "") {
                toast.error(key + " cannot be empty", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }

        //confirm preceding class
        setAlertTitle("Confirm Examination and Marks")
        let message = "Are you sure you selected the right examination and entered the right marks for each subject?"
        setAlertMessage(message)
        setAlertOpen(true);
        //submit in alert handleclose
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            //show loading spinner
            setIsUploading(true)
            const formData = new FormData()
            for (var key in inputs) {
                formData.append(key, inputs[key]);
            }
            //create array of subject ids
            let subjects = []
            class_subjects.forEach(subject => {
                subjects.push(subject.id)
            });
            formData.append("subjects", JSON.stringify(subjects))
            formData.append("saveRecord", id)//student id
            formData.append("classId", classId)//student id

            axios.post(url, formData)
                .then(function (response) {
                    //hide loading spinner
                    setIsUploading(false)
                    if (response.data.response) {
                        reset()
                        toast.success("Examination Record Saved", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    } else {
                        toast.error(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    //hide loading spinner
                    setIsUploading(false)
                    toast.error("An Error Occured", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                    console.log(error);
                });
        }
    };

    const reset = () => {
        setInputs({})
        document.getElementById('form').reset()
    }

    return (
        <div>
            <h1>New Exam Record ({student.firstname} {student.lastname})</h1>
            <div className="divider"></div>

            <div className="content">
                <form id='form' action="" onSubmit={submit}>
                    <fieldset>
                        <legend>Examination Information</legend>
                        <div className="grid">

                            <div className="form-div">
                                <label htmlFor="examination">Examination</label>
                                <select id='examination' name='examination' value={inputs.examination || ""} onChange={handleInputChange} className='input' required
                                    title='Examination'>
                                    <option value=''>--Select Examination--</option>
                                    {examinations.map((item) => {
                                        const { id, name } = item
                                        return <option key={id} value={id}>{name} | {getProcessedDate(item.date)}</option>
                                    })}
                                </select>
                            </div>


                        </div>

                        <div className="mini">*Leave optional subjects blank</div>
                        <table className=''>
                            <tbody>
                                <tr>
                                    <th>Subject</th>
                                    <th>Marks</th>
                                    <th>Total (%)</th>
                                    <th>Grading</th>
                                </tr>
                                {class_subjects.map(subject => {
                                    const { id, name } = subject
                                    const mark_name = `${id}-mark`
                                    const total_name = `${id}-total`
                                    const grading_name = `${id}-grading`
                                    return (
                                        <tr className='user_item' key={id}>
                                            <td>{name}</td>
                                            <td>
                                                <input type="number" name={mark_name} value={inputs[mark_name] || ""}
                                                    onChange={handleInputChange} min="0" max="100" className="input" placeholder='Marks' />
                                            </td>
                                            <td>
                                                <input type="number" name={total_name} value={inputs[total_name] || "100"}
                                                    onChange={handleInputChange} min="0" max="100" className="input" placeholder='Total' disabled />
                                            </td>
                                            {/* <td>
                                            <input type="text" name={grading_name} value={inputs[grading_name] || ""}
                                             onChange={handleInputChange} minLength="2" maxLength={20} className="input" placeholder='e.g. Excellent, Very Good' />
                                        </td> */}
                                            <td>
                                                <select name={grading_name} value={inputs[grading_name] || ""}
                                                    onChange={handleInputChange} minLength="2" maxLength={20} className="input">
                                                    <option value={""}>--Select---</option>
                                                    <option value={"Excellent"}>Excellent</option>
                                                    <option value={"Very Good"}>Very Good</option>
                                                    <option value={"Good"}>Good</option>
                                                    <option value={"Above Average"}>Above Average</option>
                                                    <option value={"Average"}>Average</option>
                                                    <option value={"Fair"}>Fair</option>
                                                    <option value={"Poor"}>Poor</option>
                                                    <option value={"Fail"}>Fail</option>
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                    </fieldset>


                    <div className="form-div center">
                        <button type='submit' className='submit'>Save Record</button>
                    </div>
                </form>
            </div>

            {isLoading && <MiniLoading />}

            <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
            <ToastContainer />
            {isUploading && <Loading />}

        </div>
    )
}
