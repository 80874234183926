import {useState, useEffect} from 'react'
import SMSModal from './utils/SMSModal'
import axios from 'axios'
import { APP_NAME, SERVER_URL } from '../App'
import AlertDialog from './utils/AlertDialog'
import { toast } from 'react-toastify'
import Loading from './Loading'
import EmailModal from './utils/EmailModal'

export default function SMS() {
    document.title = APP_NAME + " | SMS & Email"
    const [group, setGroup] = useState("")
    const [message, setMessage] = useState("")
    const [subject, setSubject] = useState("")
    const [parents, setParents] = useState([]) //phone numbers
    const [staff, setStaff] = useState([]) //phone numbers
    const [runOnce, setRunOnce] = useState(false) //phone numbers
    const [isSMS, setISSMS] = useState(false) //sms or email 
    const [isUploading, setIsUploading] = useState(false)
    const [open, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [tab, setTab] = useState(1) //tabs

    const sendSample = () => {
        document.getElementById("smsModal").style.display = "block"
    }
    const sendSampleEmail = () => {
        document.getElementById("emailModal").style.display = "block"
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            //show loading spinner
            setIsUploading(true)

            if(isSMS){//sending sms
                let numbers = []
                if(group === "parents") numbers = parents
                else if(group === "staff") numbers = staff
                else if(group === "both") numbers = parents.concat(staff)
    
                numbers.forEach((number) =>{
                    //loop and send sms, convert numbers
                    // let phone = convertPhoneNumber(number)
                    // console.log(number)
                })
                setIsUploading(false)
            }else{//sending email
                /**
                 * Send directly to remote server to send email
                 * rather than send to localhost then to remote
                 */
                const url = SERVER_URL + "sms.php"
                axios.get(url, {
                    params: {
                        sendEmail: message,
                        group: group,
                        subject: subject
                    }
                })
                .then(function (response) {
                    setIsUploading(false)
                    if (response.data.response) {
                        
                        toast.success(response.data.message,{
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }else{
                        toast.info(response.data.message,{
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }
                })
                .catch(function (error) {
                    setIsUploading(false)
                    console.log(error)
                });
            }
            
            
        }
    };

    const submit = (e) =>{
        e.preventDefault()
        //confirm 
        setISSMS(true)
        setAlertTitle("Confirm Message")
        const message = `Sending text message to ${group.toUpperCase()}. Proceed?`
        setAlertMessage(message)
        setAlertOpen(true);
    }

    const submitEmail = (e) =>{
        e.preventDefault()
        //confirm 
        setISSMS(false)
        setAlertTitle("Confirm Email")
        const message = `Sending email to ${group.toUpperCase()}. Proceed?`
        setAlertMessage(message)
        setAlertOpen(true);
    }

    useEffect(() => {

        const fetchNumbers = () => {
            setRunOnce(true)

            const url = SERVER_URL + "sms.php"
            axios.get(url, {
                params: {
                    fetchNumbers: "fetchNumbers"
                }
            })
                .then(function (response) {

                    if (response.data.response) {
                        let parents = JSON.parse(response.data.parents)
                        let staff = JSON.parse(response.data.staff)
                        setParents(parents)
                        setStaff(staff)
                    
                        // console.log(parents, staff)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    
                });

        }
        if(!runOnce) fetchNumbers()
        
    }, [runOnce]);

  return (
    <div>
      <h1>SMS & Email</h1>
      <div className="divider"></div>
      <div className="content">

        <div className="tabs">
            <button className={tab === 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>SMS</button>
            <button className={tab === 2 ? "tab active" : "tab"} onClick={() => setTab(2)}>Email</button>
        </div>
        
        <div className={tab === 1 ? "tab_content" : "tab_content none"}>
            <form id='form' action="" onSubmit={submit}>
                <h3>SMS</h3>
                <fieldset>
                    <legend>Send text messages to parents or staff</legend>
                    <div className="">
                        
                        <div className="form-div" style={{maxWidth: "500px"}}>
                            <label htmlFor="group">Send to Group</label>
                            <select id='group' name='group' value={group} onChange={e => setGroup(e.target.value)} className='input' required
                            title='Group'>
                                <option value=''>--Select--</option>
                                <option value='parents'>Parents</option>
                                <option value='staff'>Staff</option>
                                <option value='both'>Both</option>
                            </select>
                        </div>
                        <div className="form-div" style={{maxWidth: "500px"}}>
                            <label htmlFor="message">Message</label>
                            <textarea id='message' name='message' value={message} onChange={e => setMessage(e.target.value)} className='input' minLength={2} maxLength={280} required
                            placeholder='Message' title='Message' rows={5}/>
                            <span className='grey smaller_ft'>{message.length} / 280</span>
                        </div>
                        <div className="form-div" style={{display: "flex", alignItems: 'flex-end', gap:'10px'}}>
                            <button type='button' className='submit' onClick={sendSample}>Send Sample</button>
                            <button type='submit' className='submit exl_btn'>Send SMS Message</button>
                            <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                                <div className="form_loading"></div>
                            </div>
                        </div>
                        
                    </div>
                </fieldset>
            
            </form>
        </div>
        <div className={tab === 2 ? "tab_content" : "tab_content none"}>
            <form id='form' action="" onSubmit={submitEmail}>
                <h3>Email</h3>
                <fieldset>
                    <legend>Send emails to parents or staff</legend>
                    <div className="">
                        
                        <div className="form-div" style={{maxWidth: "500px"}}>
                            <label htmlFor="group">Send to Group</label>
                            <select id='group' name='group' value={group} onChange={e => setGroup(e.target.value)} className='input' required
                            title='Group'>
                                <option value=''>--Select--</option>
                                <option value='parents'>Parents</option>
                                <option value='staff'>Staff</option>
                                <option value='both'>Both</option>
                            </select>
                        </div>
                        <div className="form-div" style={{maxWidth: "500px"}}>
                            <label htmlFor="subject">Email Subject</label>
                            <input id='subject' type="text" className="input" placeholder='Email Subject' value={subject} onChange={e => setSubject(e.target.value)} required />
                        </div>
                        <div className="form-div" style={{maxWidth: "500px"}}>
                            <label htmlFor="emessage">Message</label>
                            <textarea id='emessage' name='emessage' value={message} onChange={e => setMessage(e.target.value)} className='input' minLength={10} maxLength={1000} required
                            placeholder='Type Message' title='Message' rows={10}/>
                            <span className='grey smaller_ft'>{message.length} / 1000</span>
                        </div>
                        <div className="form-div" style={{display: "flex", alignItems: 'flex-end', gap:'10px'}}>
                            <button type='button' className='submit' onClick={sendSampleEmail}>Send Sample</button>
                            <button type='submit' className='submit exl_btn'>Send Email</button>
                            <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                                <div className="form_loading"></div>
                            </div>
                        </div>
                        
                    </div>
                </fieldset>
            
            </form>
        </div>

        
      </div>

      {isUploading && <Loading />}
      <SMSModal message={message} setMessage={setMessage} />
      <EmailModal message={message} setMessage={setMessage} subject={subject} setSubject={setSubject} />
      <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />

    </div>
  )
}
