import React, { useState, useRef, useEffect } from 'react'
import '../css/form.css'
import axios from 'axios'
import { toast } from 'react-toastify';
import { isValidPhoneNumber, imageTypes, isCanvasBlank, uniqid } from '../functions'
import Loading from './Loading'
import CropModal from './utils/CropModal';
import { SERVER_URL, APP_NAME, CROP_TYPE, LOGO_URL } from '../App'
import MiniLoading from './utils/MiniLoading';
import MigrationModal from './utils/MigrationModal';
import { BsArrowRight } from 'react-icons/bs'
import AlertDialog from './utils/AlertDialog';
import Admin from './Admin';
import ResetPassword from './utils/ResetPassword';
const ALERT_ACTIONS = {
    MIGRATE: "MIGRATE",
    SCHOOL_YEAR: "SCHOOL_YEAR"
}

export default function Settings() {
    document.title = APP_NAME + " | Settings"

    const [inputs, setInputs] = useState({})
    const [adminInputs, setAdminInputs] = useState({})
    const [files, setFiles] = useState({})
    const [info, setInfo] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false) //for admin fetch
    const [showCanvas, setShowCanvas] = useState({ logo_photo: false })
    const [imgSrc, setImgSrc] = useState(null)
    const [cropType, setCropType] = useState(CROP_TYPE.PROFILE)
    const canvasRef = useRef()
    const [isEditing, setIsEditing] = useState(false) //whether isEditing or new info
    const [years, setYears] = useState([]) //whether isEditing or new info
    const [schoolYears, setSchoolYears] = useState([]) 
    const [newSchoolYear, setNewSchoolYear] = useState("") 
    const [admins, setAdmins] = useState([]) //admins
    const [admin, setAdmin] = useState({})// admin selected for reset or delete
    const [tab, setTab] = useState(1) //tabs
    const [adminLoaded, setAdminLoaded] = useState(false) //track if admin has been loaded already
    const [classesLoaded, setClassesLoaded] = useState(false) //track if classes has been loaded already
    const [classes, setClasses] = useState([])
    const [fromClass, setFromClass] = useState("")
    const [toClass, setToClass] = useState("")
    const [gradSchoolYear, setGradSchoolYear] = useState("") //year students are graduating
    const [open, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [alertAction, setAlertActions] = useState("")
    

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleAdminInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAdminInputs(values => ({ ...values, [name]: value }))
    }

    const handleFileChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        //validate image type
        if (imageTypes.indexOf(file.type) === -1) {
            toast.error("Only png and jpeg image files can be uploaded", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }

        //validate image size
        if (file.size > 1000000) {
            toast.error("Image size should be less than 1MB", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }

        setCropType(CROP_TYPE.PROFILE)
        // canvasRef.current = canvasRef.current

        var reader = new FileReader();
        reader.addEventListener("load", function (e) {

            //read image to check dimensions
            var image = new Image();
            image.onload = function () {
                // console.log(this.width, this.height)
                if (this.width < 200 || this.height < 200) {
                    toast.error("Image width and height should be at least 200px", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    //show corresponding image canvas
                    setShowCanvas(values => ({ ...values, [name]: true }))
                    //only add to state after passed all eval
                    setFiles(values => ({ ...values, [name]: file }))
                    setImgSrc(reader.result)
                    document.getElementById("cropModal").style.display = "block";
                }

            }
            image.src = reader.result;

        })
        reader.readAsDataURL(file);
    }

    const profileUpload = () => {
        document.getElementById("logo_photo").click()
    }

    const submit = (e) => {
        e.preventDefault()

        if (!isValidPhoneNumber(inputs.phone)) {
            //phone number is invalid
            toast.error("Phone number is invalid", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (!inputs.hasOwnProperty('year')) {
            //no year selected
            toast.error("Select a year", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //only if not Editing
        if (!isEditing) {
            if (Object.keys(files).length < 1 ||
                (isCanvasBlank(canvasRef.current))) {
                toast.info("Select school logo", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }

        //show loading spinner
        setIsUploading(true)
        const formData = new FormData()
        for (var key in inputs) {
            formData.append(key, inputs[key]);
        }

        //get canvas image data

        if (!isCanvasBlank(canvasRef.current)) {
            const logoBase64Data = canvasRef.current.toDataURL()
            formData.append("logoBase64Data", logoBase64Data)
        }

        let url = SERVER_URL + "settings.php"
        if (isEditing) formData.append("isEditing", "isEditing") //VERY IMPORTANT !!

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    setIsEditing(true) // after first saved, anything else is editing
                    toast.success("School Info Updated", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });

    }

    //submit new admin
    const adminsubmit = (e) => {
        e.preventDefault()
        let url = SERVER_URL + "settings.php"

        //show loading spinner
        setIsUploading(true)
        const formData = new FormData()
        for (var key in adminInputs) {
            formData.append(key, adminInputs[key]);
        }

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    setAdminInputs({})
                    setAdminLoaded(false) // to allow the fetching of admins in admins tab
                    document.getElementById("adminform").reset()
                    toast.success("New Admin Created", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
    }

    const submitNewYear = (e) => {
        e.preventDefault() 
        if(!newSchoolYear) return

        //confirm 
        setAlertActions(ALERT_ACTIONS.SCHOOL_YEAR)
        setAlertTitle("Create School Year")
        const message = `Creating school year ${newSchoolYear}. Proceed?`
        setAlertMessage(message)
        setAlertOpen(true);
        
    }

    // const migrate = () => {
    //     document.getElementById("migrateModal").style.display = "block"
    // }

    const migrateSubmit = (e) => {
        e.preventDefault()
        if (!fromClass || !toClass) {
            toast.error("Select classes", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
        const classItem = classes.find(item => item.id === fromClass)
        let toClassName = "Graduates"
        if (toClass !== "graduates") {
            const toClassItem = classes.find(item => item.id === toClass)
            toClassName = toClassItem.name
        }else if(!gradSchoolYear){
            toast.error("Select graduating school year", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
        //confirm 
        setAlertActions(ALERT_ACTIONS.MIGRATE)
        setAlertTitle("Confirm Migration")
        const message = `Are you sure you want to move students from ${classItem.name} to ${toClassName}?
        If there are students already in ${toClassName}, they will be added to`
        setAlertMessage(message)
        setAlertOpen(true);
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            if(alertAction === ALERT_ACTIONS.MIGRATE){
                //show loading spinner
                setIsUploading(true)
                const formData = new FormData()
                formData.append("moveFromClass", fromClass);
                formData.append("moveToClass", toClass);
                formData.append("schoolYear", gradSchoolYear);

                const url = SERVER_URL + "settings.php"
                axios.post(url, formData)
                .then(function (response) {
                    //hide loading spinner
                    setIsUploading(false)
                    if (response.data.response) {
                        // reset()
                        setFromClass("")
                        setToClass("")
                        document.getElementById("mg_form").reset()
                        toast.success(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    } else {
                        toast.error(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    //hide loading spinner
                    setIsUploading(false)
                    toast.error("An Error Occured", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                    console.log(error);
                });
            }else if(alertAction === ALERT_ACTIONS.SCHOOL_YEAR){
                let url = SERVER_URL + "settings.php"

                //show loading spinner
                setIsUploading(true)
                const formData = new FormData()
                formData.append("schoolYear", newSchoolYear);
        
                axios.post(url, formData)
                    .then(function (response) {
                        //hide loading spinner
                        setIsUploading(false)
                        if (response.data.response) {
                            document.getElementById("yearform").reset()
                            toast.success("School Year Created", {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                            setSchoolYears(years => [...years, {"id": uniqid(), "year":newSchoolYear}])
                        } else {
                            toast.error(response.data.message, {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                        }
        
                    })
                    .catch(function (error) {
                        //hide loading spinner
                        setIsUploading(false)
                        toast.error("An Error Occured", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                        console.log(error);
                    });
            }
            
        }
    };


    //function for editing info. Populate fields with current info
    const setFields = (info) => {

        const schoolInfo = {
            schoolname: info.name,
            email: info.email,
            address: info.address,
            year: info.year_founded,
            phone: info.phone,
            phone2: info.phone2,
            website: info.website

        }
        //in case some info is set before this, don't want previous removed
        setInputs(values => ({ ...values, ...schoolInfo }))

    }

    const resetPass = (admin) => {
        setAdmin(admin)
        document.getElementById("resetModal").style.display = "block"
    }

    //if canvas state changes, clear image from isEditing
    useEffect(() => {

        if (showCanvas.logo_photo) {
            document.getElementById("logo_display").className = " none"
        }

    }, [showCanvas]);

    //fetch school info, and school years
    useEffect(() => {
        const year = new Date().getFullYear()
        let yearOptions = []
        for (let i = year; i >= year - 150; i--) {
            yearOptions.push(i)
        }
        setYears(yearOptions)

        const fetchInfo = () => {
            const url = SERVER_URL + "settings.php"
            axios.get(url, {
                params: {
                    loadInfo: "loadInfo"
                }
            })
                .then(function (response) {

                    if (response.data.response) {
                        let info = JSON.parse(response.data.info)
                        let schoolYears = JSON.parse(response.data.schoolYears)
                        setInfo(info[0])
                        setSchoolYears(schoolYears)

                        setIsEditing(true)
                        setFields(info[0])

                    } else {
                        setInfo({})
                        setIsEditing(false)
                        // toast.info("No info to retrieve",{
                        //     hideProgressBar: true,
                        //     theme: "colored"
                        // })
                    }

                })
                .catch(function (error) {
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }
        fetchInfo()
    }, []);

    //fetch admins if tab changes for first time
    useEffect(() => {

        const fetchAdmins = () => {
            setAdminLoaded(true)

            const url = SERVER_URL + "settings.php"
            axios.get(url, {
                params: {
                    loadAdmins: "loadAdmins"
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let admins = JSON.parse(response.data.admins)
                        setAdmins(admins)

                    } else {

                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve info", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        const fetchClasses = () => {

            setClassesLoaded(true)

            const url = SERVER_URL + "classes.php"
            axios.get(url, {
                params: {
                    loadClasses: "loadClasses"
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let classes = JSON.parse(response.data.classes)
                        // console.log(classes) 
                        if (classes.length === 0) {
                            setClasses([])
                            toast.info("No classes in database", {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                            return
                        }
                        setClasses([...classes])

                    } else {

                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve classes", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        //adding tab condition may fail to load first time. state is a little slow
        if (!adminLoaded)
            fetchAdmins()

        if (!classesLoaded)
            fetchClasses()
    }, [tab, adminLoaded, classesLoaded]);

    return (
        <div>
            <h1>Settings</h1>
            <h3 className='grey'>{info.name}</h3>
            <div className="divider"></div>

            <div className="content">
                <div className="tabs">
                    <button className={tab === 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>School Info</button>
                    <button className={tab === 2 ? "tab active" : "tab"} onClick={() => setTab(2)}>School Years</button>
                    <button className={tab === 3 ? "tab active" : "tab"} onClick={() => setTab(3)}>Administrators</button>
                    <button className={tab === 4 ? "tab active" : "tab"} onClick={() => setTab(4)}>New Admin</button>
                    <button className={tab === 5 ? "tab active" : "tab"} onClick={() => setTab(5)}>Class Progression</button>
                </div>

                <div className={tab === 1 ? "tab_content" : "tab_content none"}>
                    <form id='form' action="" onSubmit={submit}>
                        <fieldset>
                            <legend>School Information</legend>
                            <div className="grid grid2">
                                <div className="form-div">
                                    <label htmlFor="schoolname">School Name</label>
                                    <input id='schoolname' type="text" name='schoolname' value={inputs.schoolname || ""} onChange={handleInputChange} className='input' placeholder='School Name' minLength={2} maxLength={100} required />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="email">Email</label>
                                    <input id='email' type="email" name='email' value={inputs.email || ""} onChange={handleInputChange} className='input' placeholder='School Email' minLength={2} maxLength={50} required />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input id='phone' type="phone" name='phone' value={inputs.phone || ""} onChange={handleInputChange} className='input' placeholder='Phone Number' minLength={10} maxLength={10} required />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="phone2">Phone Number 2 <span className="grey smaller_ft">(not required)</span></label>
                                    <input id='phone2' type="phone" name='phone2' value={inputs.phone2 || ""} onChange={handleInputChange} className='input' placeholder='Phone Number (optional)' minLength={10} maxLength={10} />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="address">School Address</label>
                                    <textarea id='address' type="address" name='address' value={inputs.address || ""} onChange={handleInputChange} className='input' rows={1} placeholder='School Address' minLength={2} maxLength={200} required />
                                </div>

                                <div className="form-div">
                                    <label htmlFor="year">Year Founded</label>
                                    <select id='year' name='year' value={inputs.year || ""} onChange={handleInputChange} className='input' required={true}>
                                        <option value="">--Year Founded--</option>
                                        {years.map(year => {
                                            return <option key={year} value={year}>{year}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-div">
                                    <label htmlFor="website">Website Address <span className="grey smaller_ft">(not required)</span></label>
                                    <input id='website' type="url" name='website' value={inputs.website || ""} onChange={handleInputChange} className='input' placeholder='Website Address' minLength={4} maxLength={50} />
                                </div>

                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Logo</legend>
                            <div className="grid">

                                <div className="form-div">
                                    <label htmlFor="logo_photo">School Logo/Emblem</label>
                                    <input id='logo_photo' type="file" name='logo_photo' onChange={handleFileChange} className='none' data-id="logo_display" accept='.png, .jpg, .jpeg' />
                                    <button type='button' className="file_button button" onClick={profileUpload}>Click to Upload</button>
                                    <div id='logo_display' className='img_preview'>
                                        {info.logo && <img src={LOGO_URL + info.logo} alt="" width={200} height={200} />}
                                    </div>
                                    <canvas ref={canvasRef} className={showCanvas.logo_photo ? '' : 'none'}></canvas>
                                </div>
                            </div>
                        </fieldset>

                        <div className="form-div center">
                            <button type='submit' className='submit'>Update Info</button>
                        </div>
                    </form>
                </div>

                <div className={tab === 2 ? "tab_content" : "tab_content none"}>
                    <form id='yearform' action="" onSubmit={submitNewYear}>
                        <fieldset>
                            <legend>Create New School Year</legend>
                            <div className="grid">
                               
                                <div className="form-div">
                                    <label htmlFor="year">School Year</label>
                                    <input id='year' type="text" name='year' value={newSchoolYear} onChange={e => setNewSchoolYear(e.target.value)} className='input' minLength={4} maxLength={10} required
                                    placeholder='School Year e.g. 2021/2022' title='School Year'
                                    />
                                </div>
                                <div className="form-div" style={{display: "flex", alignItems: 'flex-end'}}>
                                    <button type='submit' className='submit'>Create Year</button>
                                </div>
                                
                            </div>
            
                        </fieldset>
                       
                    </form>

                    <div className="user_list">

                        {schoolYears.length > 0 && <h4 className='grey'>{schoolYears.length} School {schoolYears.length === 1 ? "Year" : "Years"}</h4>}

                        <table className='user_list_table'>
                            <tbody>
                            {schoolYears.length > 0 && 
                            <tr>
                                <th>School Years</th> 
                            </tr>
                            } 
                            
                            {schoolYears.map((year) => {
                                return (
                                    <tr className='user_item' key={year.id}>
                                        <td>{year.year}</td> 
                                    </tr>
                                )
                            })}
                            </tbody>
                            
                        </table>
                    </div>
                </div>

                <div className={tab === 3 ? "tab_content" : "tab_content none"}>
                    {isLoading ? MiniLoading :
                        <table>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Action</th>
                                </tr>
                                {admins.map((admin, key) => {
                                    return (
                                        <Admin admin={admin} key={key} count={key} resetPass={resetPass} />
                                    )
                                })}
                            </tbody>
                        </table>
                    }
                </div>

                <div className={tab === 4 ? "tab_content" : "tab_content none"}>
                    <form id='adminform' action="" onSubmit={adminsubmit}>
                        <fieldset>
                            <legend>Create New Admin</legend>
                            <div className="grid">
                                <div className="form-div">
                                    <label htmlFor="firstname">First Name</label>
                                    <input id='firstname' type="text" name='firstname' value={adminInputs.firstname || ""} onChange={handleAdminInputChange} className='input' placeholder='First Name' minLength={2} maxLength={25} required />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input id='lastname' type="text" name='lastname' value={adminInputs.lastname || ""} onChange={handleAdminInputChange} className='input' placeholder='Last Name' minLength={2} maxLength={25} required />
                                </div>

                            </div>
                            <div className="grid">
                                <div className="form-div">
                                    <label htmlFor="username">Username</label>
                                    <input id='username' type="text" name='username' value={adminInputs.username || ""} onChange={handleAdminInputChange} className='input' placeholder='Username' minLength={3} maxLength={25} required />
                                </div>
                                <div className="form-div">
                                    <label htmlFor="password">Password</label>
                                    <input id='password' type="password" name='password' value={adminInputs.password || ""} onChange={handleAdminInputChange} className='input' placeholder='Password' minLength={5} maxLength={20} required />
                                </div>
                            </div>

                        </fieldset>

                        <div className="form-div center">
                            <button type='submit' className='submit'>Create Admin</button>
                        </div>
                    </form>
                </div>

                <div className={tab === 5 ? "tab_content" : "tab_content none"}>
                    <h3>End of Year Class Progression</h3>
                    <div style={{ maxWidth: "650px" }}>
                        <p>
                            Class Progression is a <b>once</b> in a school year action that migrates
                            students from a class to the <b>next</b> superceding class in the institution.
                        </p><br />
                        <p>
                            <b>Always</b> move students from the <b>highest</b> class levels first
                            and work your way down from there.<br></br>
                            Example, JHS 2 to JHS 3, then JHS 1 to JHS 2, then
                            Class 6 to JHS 1, etc.
                        </p><br />
                        {/* <p>
                            This also migrates the students in the final year (last class) to the
                            graduates database in the archive section
                        </p><br />
                        <p>
                            You can choose to migrate the graduates only, or the graduates along with
                            the other classes
                        </p><br />
                        <p>
                            This action is <b>not</b> reversible so be certain it needs to be done before proceeding
                        </p> */}
                    </div>
                    {/* <div>
                        <button className="button" onClick={migrate}>Perform Migration</button>
                    </div> */}
                    <form id='mg_form' action="" onSubmit={migrateSubmit}>
                        <fieldset>
                            <legend>Student Class Migration</legend>
                            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                <div className="form-div">
                                    <label htmlFor="from_class">Students in Class:</label>
                                    <select id='from_class' name='from_class' value={fromClass} onChange={(e) => setFromClass(e.target.value)} className='input' required>
                                        <option value=''>--Select Class--</option>
                                        {classes.map((item) => {
                                            const { id, name } = item
                                            return <option key={id} value={id}>{name}</option>
                                        })}
                                    </select>

                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    To
                                    <BsArrowRight />
                                </div>

                                <div className="form-div">
                                    <label htmlFor="to_class">New Class/Graduates</label>
                                    <select id='to_class' name='to_class' value={toClass} onChange={(e) => setToClass(e.target.value)} className='input' required>
                                        <option value=''>--Select New Class--</option>
                                        {classes.map((item) => {
                                            const { id, name } = item
                                            return <option key={id} value={id}>{name}</option>
                                        })}
                                        <option value={"graduates"}>{"Graduates"}</option>
                                    </select>
                                </div>
                                <div className={toClass==="graduates" ? 'form-div' : 'form-div none'}>
                                    <label htmlFor="grad_school_year">Graduation Year</label>
                                    <select id='grad_school_year' name='grad_school_year' value={gradSchoolYear} onChange={(e) => setGradSchoolYear(e.target.value)}
                                     className='input' required={toClass==="graduates" ? true : false}>
                                        <option value=''>--Graduation Year--</option>
                                        {schoolYears.map((item) => {
                                            const { id, year } = item
                                            return <option key={id} value={id}>{year}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="form-div" style={{ display: "flex", alignItems: "flex-end" }}>
                                    <button type='submit' className='submit'>Move Students</button>
                                </div>

                            </div>

                        </fieldset>



                    </form>

                    <MigrationModal />

                </div>

            </div>

            {isUploading && <Loading />}
            <ResetPassword user={admin} />
            <CropModal src={imgSrc} type={cropType} canvasRef={canvasRef} />
            <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
        </div>
    )
}
