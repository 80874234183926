import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../AppContext'
import { SERVER_URL } from '../../App'
import AlertDialog from '../utils/AlertDialog'
import Loading from '../Loading'
import axios from 'axios'
import { toast } from 'react-toastify'
import { attachmentTypes, schoolTerms2 } from '../../functions'

export default function AddFeeRecord() {
    const { appState:{activeStudent, adminInfo}} = useGlobalContext() //very IMPORTANT!!
    const [student] = useState(activeStudent)
    const [inputs, setInputs] = useState({})
    const [attachment, setAttachment] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [open, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [classes, setClasses] = useState([])
    const [schoolYears, setSchoolYears] = useState([])
    const url = SERVER_URL+"fees.php"

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const fileChange = (e) => {
        setAttachment(null)
        let file = e.target.files[0] 
        if (attachmentTypes.indexOf(file.type) === -1) {
            toast.info("Only images and pdf files can be uploaded", {
                theme: "colored"
            })
            return;
        }
        if (file.size > 3000000) {//3MB
            toast.info(`${file.name} should be less than 3MB`, {
                theme: "colored"
            })
            return;
        }
        setAttachment(file)
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if(option){
            //show loading spinner
            setIsUploading(true)
            const formData = new FormData()
            formData.append("addRecord", student.id);
            formData.append("admin", adminInfo.username);
            for ( var key in inputs ) {
                formData.append(key, inputs[key]);
            }
            if(attachment !== null) formData.append("attachment",attachment, attachment.name)
        
            axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if(response.data.response){
                    toast.success(response.data.message,{
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }else{
                    toast.error(response.data.message,{
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }
                
            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });
        }
    };

    const submit = (e) => {
        e.preventDefault()
        if(student.id === "") return
    
        //validate all inputs
        for ( var key in inputs ) {
            if(inputs[key] === ""){
                toast.error(key + " cannot be empty",{
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }
        //if default student class is maintained in dropdown
        if(inputs['class'] === undefined) inputs['class'] = student.class_id

        if(isNaN(inputs.amount)){
            toast.error("Invalid amount value",{
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }
    
        //confirm preceding class
        setAlertTitle("Save Payment Record")
        setAlertMessage(`You are making a record of payment for the above student.
        If there is an existing record, this will overwrite it. Do you want to proceed?`)
        setAlertOpen(true);
        //submit in alert handleclose
        
    
    }

    useEffect(() => {
        let today = new Date()
        document.getElementById("date").max = today.toISOString().split("T")[0]

        const fetchClasses = () => {
            const url = SERVER_URL+"fees.php"
            axios.get(url, {
                params: {
                loadClasses: "loadClasses",
                loadSchoolYears: "loadSchoolYears"
                }
            })
            .then(function (response) {
                
                if(response.data.response){ 
                    let classes = JSON.parse(response.data.classes)
                    let schoolYears = JSON.parse(response.data.schoolYears)
                    setClasses([...classes])
                    setSchoolYears(schoolYears)
                }else{ 
                    
                }
                
            })
            .catch(function (error) {
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve classes",{
                    hideProgressBar: true,
                    theme: "colored"
                })
            });
            
        }
        fetchClasses()
    }, []);
    // console.log(activeStudent,student)

  return (
    <div>
        <h1>{student.firstname ? `${student.firstname} ${student.lastname}` : "Add Fee Record"}</h1>
        <h3 className='grey'>Add fee payment record</h3>
        <div className="divider"></div>
      
        <div className="content">
            <form id='form' action="" onSubmit={submit}>
                <fieldset>
                    <legend>Add Payment Record</legend>
                    <div className="grid grid3">
                        <div className="form-div">
                            <label htmlFor="class">For Class?</label>
                            <select id='class' name='class' value={inputs.class || student.class_id} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Class--</option>
                                {classes.map((item) =>{
                                const {id, name} = item
                                return <option key={id} value={id}>{name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="school_year">School Year</label>
                            <select id='school_year' name='school_year' value={inputs.school_year || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select School Year--</option>
                                {schoolYears.map((item) =>{
                                const {id, year} = item
                                return <option key={id} value={id}>{year}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="term">Term</label>
                            <select id='term' name='term' value={inputs.term || ""} onChange={handleInputChange} className='input' required>
                                <option value=''>--Select Term--</option>
                                {schoolTerms2.map((item) =>{
                                    return <option key={item} value={item}>{item}</option>
                                })}
                                
                            </select>
                        </div>
                        <div className="form-div">
                            <label htmlFor="amount">Amount Paid</label>
                            <input id='amount' type="number" name='amount' value={inputs.amount || ""} onChange={handleInputChange} className='input' minLength={1} maxLength={10} required
                            placeholder='Amount Paid' />
                        </div>
                        <div className="form-div">
                            <label htmlFor="date">Date Paid</label>
                            <input id='date' type="date" name='date' value={inputs.date || ""} onChange={handleInputChange} className='input' required
                            placeholder='Date Paid' />
                        </div>
                        <div className="form-div">
                            <label htmlFor="attachment">Attachment <span className="grey smaller_ft">(not required)</span></label>
                            <input id='attachment' type="file" name='attachment' accept='.jpg,.jpeg,.png,.pdf' onChange={fileChange} className='input'
                            placeholder='Date Paid' />
                        </div>
                        
                        
                    </div>
    
                </fieldset>
            

                <div className="form-div center">
                    <button type='submit' className='submit'>Save</button>
                </div>
            </form>
        </div>
      
        <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage}/>
        {isUploading && <Loading/>}

    </div>
  )
}
