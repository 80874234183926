import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import MiniLoading from '../utils/MiniLoading'
import { getProcessedDate, getAge } from '../../functions'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { SERVER_URL, APP_NAME, IMAGE_URL, ID_URL } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'

export default function ViewStaff() {
    const { id, isarchive } = useParams()
    if (id === undefined) window.history.back()

    const [isLoading, setIsLoading] = useState(true)
    const [staff, setStaff] = useState({})

    useEffect(() => {
        const url = SERVER_URL + "staff.php"
        const fetchStaff = () => {
            //if its archived staff, set param to true else nothing
            const isArchive = isarchive === undefined ? "" : "true"
            axios.get(url, {
                params: {
                    loadStaffInfo: id,
                    isArchive
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let staff = JSON.parse(response.data.staff)
                        // console.log(staff) 
                        if (staff.length === 0) {
                            setStaff({})
                            toast.info("No staff in database", {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                            return
                        }
                        setStaff(staff[0])
                        document.title = `${APP_NAME} | ${staff[0].firstname} ${staff[0].lastname}`
                        // console.log(Object.keys(staff).length)

                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve staff", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchStaff()
    }, [id, isarchive]);

    return (
        <div>
            <h1>{staff.firstname ? `${staff.firstname} ${staff.lastname}` : "View Staff"}</h1>
            <div className="divider"></div>

            <div className="content">

                {isLoading && <MiniLoading />}

                {staff.firstname &&
                    <div className="user_page">

                        <div className="user_board">

                            <div className="user_info_section">
                                <div>
                                    <img alt='staff' className='user_info_image' src={IMAGE_URL + staff.image} />
                                </div>
                                <div className='info_area'>
                                    <div className='user_info_name'>{staff.firstname} {staff.lastname}</div>
                                    <div className='user_info_age'>
                                        <span className='grey'>Age: {getAge(staff.dob)} yrs | </span>
                                        <span className='light_blue'>{staff.gender}</span>
                                    </div>
                                    <div className='smaller_ft'>
                                        <b>{staff.role}</b>
                                    </div>
                                    <div className='smaller_ft'>
                                        <span className='grey'>Date of Birth:</span> {getProcessedDate(staff.dob)}
                                    </div>
                                    <div className='smaller_ft'>
                                        <span className='grey'>Date Employed:</span> {getProcessedDate(staff.date_employed)}
                                    </div>
                                    {isarchive !== undefined &&
                                        <div className='smaller_ft'>
                                            <span className='grey'>Date Archived:</span> {getProcessedDate(staff.date_archived)}
                                        </div>
                                    }
                                    {isarchive === undefined &&
                                        <>
                                            <div>
                                                <span>
                                                    <Link to={`/staff/edit/${staff.id}`}>
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </Link>
                                                </span>
                                                <span> <FontAwesomeIcon icon={faTrashCan} /></span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className='info_area sub_area'>

                                <div>

                                    <p>
                                        <FontAwesomeIcon icon={faLocationDot} />  <span className='bolder'>Address</span>
                                    </p>
                                    <p>
                                        <span> {staff.address}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <FontAwesomeIcon icon={faEnvelope} /> <span className='bolder'>Email</span>
                                    </p>
                                    <p>
                                        <span> {staff.email}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /> <span className='bolder'>Phone</span>
                                    </p>
                                    <p>
                                        <span> {staff.phone}</span>
                                    </p>
                                </div>

                            </div>

                            <div className="divider"></div>

                            <div className="info_area">
                                <div><span className='bolder'>ID</span></div>
                                <div>
                                    <img alt='identification' className='user_id_image' src={ID_URL + staff.id_image} />
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>ID Number:</span> {staff.id_number}
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>Date of Issue:</span> {getProcessedDate(staff.id_issued)}
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>Date of Expiry:</span> {getProcessedDate(staff.id_expiry)}
                                </div>
                            </div>


                        </div>

                    </div>
                }


            </div>

            <ToastContainer />

        </div>
    )
}
