import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import MiniLoading from './utils/MiniLoading'
import axios from 'axios' 
import { ToastContainer, toast } from 'react-toastify';
import Class from './Class';
import ClassTeacherModal from './utils/ClassTeacherModal';
import {SERVER_URL, APP_NAME} from '../App'

export default function Classes() {
  document.title = APP_NAME+" | Classes"

  const [classId, setClassId] = useState("") //for editing class teacher
  const [className, setClassName] = useState("") //for editing class teacher
  const [updated, setUpdated] = useState(false) //for editing class teacher
  const [query, setQuery] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [classes, setClasses] = useState([])
  const [foundClasses, setFoundClasses] = useState([])

  const searchOnChange = (text) => {
     setQuery(text)
     search(text)
  }

  const search = (text) => {

    if(text === "" || classes.length === 0){// no need to search
      setFoundClasses(classes)
      return
    }

    const foundClasses = classes.filter((classItem)=>{
      const name = classItem.name
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setFoundClasses(foundClasses)
  }

  const submit = (e) => {
    e.preventDefault()
    search(query)
  }

  useEffect(() => {
    const url = SERVER_URL+"classes.php"
    const fetchClasses = () => {
      axios.get(url, {
          params: {
            loadClasses: "loadClasses"
          }
        })
        .then(function (response) {
          setIsLoading(false)
          if(response.data.response){ 
            let classes = JSON.parse(response.data.classes)
            // console.log(classes) 
            if(classes.length === 0){
              setClasses([])
              setFoundClasses([])
              toast.info("No classes in database",{
                hideProgressBar: true,
                theme: "colored"
              })
              return
            }
            setClasses([...classes])
            setFoundClasses([...classes])
            
          }else{ 
              
          }
          
        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve classes",{
              hideProgressBar: true,
              theme: "colored"
          })
        });
     
    }

    fetchClasses()
  }, [updated]);

  const openEditor = (id, name) => {
    setClassId(id)
    setClassName(name)
    document.getElementById("teacherModal").style.display = "block"
  }

  return (
    <div>

      <h1>Classes</h1>
      <div className="divider"></div>

      <div className="top">

        <form action="" className="" onSubmit={submit}>
          <input type="search" className='input' placeholder='Search' value={query} onChange={e => searchOnChange(e.target.value)} />
          <button type='submit' className='submit'>Search</button>
        </form>

        <Link to="new-class">
          <button type='button' className='button'>Add New Class</button>
        </Link>
      </div>

      <div className="content">
        
        <div className="user_list">

          {classes.length > 0 && <h4 className='grey'>{classes.length} Total {classes.length === 1 ? "Class" : "Classes"}</h4>}

          <table className='user_list_table'>
            <tbody>
              {foundClasses.length > 0 && 
              <tr>
                <th>Class Name</th> 
                <th>Class Teacher</th> 
                <th>Preceding Class</th> 
                <th className='center'>Actions</th> 
              </tr>
              } 
              
              {foundClasses.map((classItem) => {
                return <Class key={classItem.id} classItem={classItem} openEditor={openEditor}/>
              })}
            </tbody>
            
          </table>
        </div>

          {isLoading && <MiniLoading/>}

      </div>

      <ClassTeacherModal class_id={classId} class_name={className} setUpdated={setUpdated}/>
      <ToastContainer />

    </div>
  )
}
