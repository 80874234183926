import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { SERVER_URL } from '../../App'

export default function ResetPassword({ user }) {
    const { id, firstname, lastname } = user
    const name = firstname + " " + lastname

    const [isUploading, setIsUploading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirm, setConfirm] = useState("")

    const closeModal = () => {
        document.getElementById("resetModal").style.display = "none"
    }

    const submit = (e) => {
        e.preventDefault()
        if (!password || !id || !confirm) return

        if (password !== confirm) {
            toast.error("Passwords do not match", {
                theme: "colored"
            })
            return
        }

        setIsUploading(true)

        const url = SERVER_URL + "settings.php"
        const formData = new FormData()
        formData.append("resetPassword", id)
        formData.append("password", password)

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    toast.success(response.data.message, {
                        theme: "colored"
                    })
                    closeModal()
                } else {
                    toast.error(response.data.message, {
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    theme: "colored"
                })
                console.log(error);
            });
    }

    return (
        <div>
            <div id="resetModal" className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <span onClick={closeModal} className="close">&times;</span>
                        <h3 className='center'>Reset Password for {name}</h3>
                    </div>

                    <div className="">
                        <form action="" onSubmit={submit}>
                            <div className="form-div">
                                <label htmlFor="resetpassword">Password</label>
                                <input id='resetpassword' type="password" name='resetpassword' className='input' placeholder='Password' onChange={e => setPassword(e.target.value)} value={password} minLength={4} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="confirm">Confirm Password</label>
                                <input id='confirm' type="password" name='confirm' className='input' placeholder='Confirm Password' onChange={e => setConfirm(e.target.value)} value={confirm} minLength={4} required />
                            </div>

                            <div className="form-div center flex_row">
                                <button type='submit' className='button' disabled={isUploading}>Reset Password</button>

                                <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                                    <div className="form_loading"></div>
                                </div>
                            </div>
                        </form>


                    </div>

                </div>

            </div>
        </div>
    )
}
