import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '../../css/form.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { isValidPhoneNumber, imageTypes, isCanvasBlank } from '../../functions'
import Loading from '../Loading'
import CropModal from '../utils/CropModal';
import { SERVER_URL, CROP_TYPE, IMAGE_URL, ID_URL } from '../../App'

export default function NewStudent() {

    let editing = false
    const { id } = useParams()// for editing student
    if (id && id !== "") editing = true

    const [inputs, setInputs] = useState({})
    const [files, setFiles] = useState({})
    const [classes, setClasses] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [showCanvas, setShowCanvas] = useState({ id_photo: false, profile_photo: false })
    const [imgSrc, setImgSrc] = useState(null)
    const [cropType, setCropType] = useState(CROP_TYPE.ID)
    const idCanvasRef = useRef()
    const profileCanvasRef = useRef()
    const canvasRef = useRef()

    const [isEditing, setIsEditing] = useState(editing) //whether editing or new student
    const [studentId] = useState(id)
    const [student, setStudent] = useState({})
    const [title, setTitle] = useState("New Student")

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }))
        // console.log(name, value)
    }

    const handleFileChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        //validate image type
        if (imageTypes.indexOf(file.type) === -1) {
            toast.error("Only png and jpeg image files can be uploaded", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }
        //validate image size
        if (file.size > 1000000) {
            toast.error("Image size should be less than 1MB", {
                hideProgressBar: true,
                theme: "colored"
            })
            return;
        }

        // readImage(value, e.target.getAttribute('data-id'))

        if (e.target.id === "id_photo") {
            setCropType(CROP_TYPE.ID)
            canvasRef.current = idCanvasRef.current
        } else {
            setCropType(CROP_TYPE.PROFILE)
            canvasRef.current = profileCanvasRef.current
        }
        var reader = new FileReader();
        reader.addEventListener("load", function (e) {

            //read image to check dimensions
            var image = new Image();
            image.onload = function () {
                // console.log(this.width, this.height)
                if (this.width < 200 || this.height < 200) {
                    toast.error("Image width and height should be at least 200px", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    //show corresponding image canvas
                    setShowCanvas(values => ({ ...values, [name]: true }))
                    //only add to state after passed all eval
                    setFiles(values => ({ ...values, [name]: file }))
                    setImgSrc(reader.result)
                    document.getElementById("cropModal").style.display = "block";
                }

            }
            image.src = reader.result;

        })
        reader.readAsDataURL(file);
        // console.log(e.target.getAttribute('data-id'))
        // console.log(name, value)
    }

    const idUpload = () => {
        document.getElementById("id_photo").click()
    }

    const profileUpload = () => {
        document.getElementById("profile_photo").click()
    }

    const submit = (e) => {
        e.preventDefault()

        if (!isValidPhoneNumber(inputs.father_phone)) {
            //fathers phone number is invalid
            toast.error("Father's phone number is invalid", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (!isValidPhoneNumber(inputs.mother_phone)) {
            //mothers phone number is invalid
            toast.error("Mother's phone number is invalid", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        if (!inputs.hasOwnProperty('gender')) {
            //no gender selected
            toast.error("Select a gender", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //validate all inputs
        for (var key in inputs) {
            if (inputs[key] === "") {
                toast.error(key + " cannot be empty", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }

        //only if not editing
        if (!editing) {
            if (Object.keys(files).length < 2 ||
                (isCanvasBlank(idCanvasRef.current) || isCanvasBlank(profileCanvasRef.current))) {
                toast.error("Select all required images", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                return
            }
        }


        //show loading spinner
        setIsUploading(true)
        const formData = new FormData()
        for (key in inputs) {
            formData.append(key, inputs[key]);
        }

        //get canvas image data
        if (!isCanvasBlank(idCanvasRef.current)) {
            const idBase64Data = idCanvasRef.current.toDataURL()
            formData.append("idBase64Data", idBase64Data)
        }
        if (!isCanvasBlank(profileCanvasRef.current)) {
            const profileBase64Data = profileCanvasRef.current.toDataURL()
            formData.append("profileBase64Data", profileBase64Data)
        }

        let url = SERVER_URL + "new-student.php"
        if (editing) formData.append("isEditing", studentId) //VERY IMPORTANT !!

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    if (!editing) reset()

                    let message = editing ? "Student Info Updated" : "New Student Saved"
                    toast.success(message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

                // console.log(response.data);
            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });

        // console.log("add student")
    }

    const reset = () => {
        setInputs({})
        setFiles({})
        document.getElementById('form').reset()
        clearCanvas()
        //hide canvas
        setShowCanvas({ id_photo: false, profile_photo: false })
    }

    const clearCanvas = () => {
        let canvas = idCanvasRef.current
        let ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        canvas = profileCanvasRef.current
        ctx = canvas.getContext("2d")
        ctx.clearRect(0, 0, canvas.width, canvas.height)
    }

    useEffect(() => {
        setIsEditing(editing) //VERY IMPORTANT

        let today = new Date()
        let eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(today.getFullYear() - 18)

        document.getElementById("dob").max = today.toISOString().split("T")[0]
        document.getElementById("date_enrolled").max = today.toISOString().split("T")[0]
        document.getElementById("id_issued").max = today.toISOString().split("T")[0]
        document.getElementById("id_expiry").min = today.toISOString().split("T")[0]
        //=============editing
        if (isEditing) {
            setIsUploading(true) //just to show the loading view
            const url = SERVER_URL + "students.php"

            const fetchStudents = () => {
                axios.get(url, {
                    params: {
                        loadStudentInfo: studentId,
                        studentType: "student"
                    }
                })
                    .then(function (response) {
                        setIsUploading(false)
                        if (response.data.response) {
                            let students = JSON.parse(response.data.students)
                            // console.log(students) 
                            if (students.length === 0) {
                                setStudent({})
                                toast.info("No student in database", {
                                    hideProgressBar: true,
                                    theme: "colored"
                                })
                                return
                            }
                            setStudent(students[0])
                            setStudentFields(students[0])


                        } else {
                            toast.info(response.data.message, {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                        }

                    })
                    .catch(function (error) {
                        setIsUploading(false)
                        console.log(error)
                        toast.error("An Error Occured. Couldn't retrieve student info", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    });

            }

            fetchStudents()
        }
    }, [isEditing, studentId, editing]);

    //if canvas state changes, clear image from editing
    useEffect(() => {
        if (showCanvas.id_photo) {
            document.getElementById("id_display").className = " none"
        }
        if (showCanvas.profile_photo) {
            document.getElementById("profile_display").className = " none"
        }

    }, [showCanvas]);

    //fetch classes
    useEffect(() => {
        const fetchClasses = () => {
            const url = SERVER_URL + "new-class.php"
            axios.get(url, {
                params: {
                    loadClasses: "loadClasses"
                }
            })
                .then(function (response) {

                    if (response.data.response) {
                        let classes = JSON.parse(response.data.classes)
                        setClasses([...classes])

                    } else {
                        toast.error("An Error Occured. Couldn't retrieve classes", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve classes", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }
        fetchClasses()
    }, []);

    //if student info is loaded and classes is loaded, set class input
    useEffect(() => {
        if (student.hasOwnProperty("class_id")) {
            const classItem = classes.find(classItem => {
                return classItem.id === student.class_id
            })
            if (classItem !== undefined)
                setInputs(values => ({ ...values, "class": classItem.id }))
        }

    }, [student, classes]);

    //function for editing a user. Populate fields with current user info
    const setStudentFields = (student) => {
        setTitle(student.firstname + " " + student.lastname)

        const studentInfo = {
            firstname: student.firstname,
            lastname: student.lastname,
            dob: student.dob,
            address: student.address,
            gender: student.gender,
            date_enrolled: student.date_enrolled,
            father_firstname: student.father_firstname,
            father_lastname: student.father_lastname,
            father_phone: student.father_phone,
            father_email: student.father_email,
            mother_firstname: student.mother_firstname,
            mother_lastname: student.mother_lastname,
            mother_phone: student.mother_phone,
            mother_email: student.mother_email,
            id_number: student.id_number,
            id_issued: student.id_issued,
            id_expiry: student.id_expiry,
        }
        // setInputs(studentInfo)
        //in case class is set before this, don't want class removed
        setInputs(values => ({ ...values, ...studentInfo }))

    }

    return (
        <div>
            <h1>{title}</h1>
            <div className="divider"></div>

            <div className="content">
                <form id='form' action="" onSubmit={submit}>
                    <h3>All fields required unless stated otherwise</h3>
                    <fieldset>
                        <legend>Personal Information</legend>
                        <div className="grid">
                            <div className="form-div">
                                <label htmlFor="firstname">First Name </label>
                                <input id='firstname' type="text" name='firstname' value={inputs.firstname || ""} onChange={handleInputChange} className='input' placeholder='First Name' minLength={2} maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="lastname">Last Name </label>
                                <input id='lastname' type="text" name='lastname' value={inputs.lastname || ""} onChange={handleInputChange} className='input' placeholder='Last Name' minLength={2} maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="dob">Date of Birth </label>
                                <input id='dob' type="date" name='dob' value={inputs.dob || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="address">Home Address</label>
                                <textarea id='address' type="address" name='address' value={inputs.address || ""} onChange={handleInputChange} className='input' rows={1} placeholder='Home Address' minLength={2} maxLength={200} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="gender">Gender</label>
                                <select id='gender' name='gender' value={inputs.gender || ""} onChange={handleInputChange} className='input' required={true}>
                                    <option value="">--Select Gender--</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="form-div">
                                <label htmlFor="date_enrolled">Date Enrolled in School </label>
                                <input id='date_enrolled' type="date" name='date_enrolled' value={inputs.date_enrolled || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="class">Student Class</label>
                                <select id='class' name='class' value={inputs.class || ""} onChange={handleInputChange} className='input' required={true}>
                                    <option value="">--Select Class--</option>
                                    {classes.map((item) => {
                                        const { id, name } = item
                                        return <option key={id} value={id}>{name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                    </fieldset>

                    <fieldset>
                        <legend>Parent(s) / Guardian(s)</legend>
                        <div className="grid grid4">
                            <div className="form-div">
                                <label htmlFor="father_firstname">Father's First Name </label>
                                <input id='father_firstname' type="text" name='father_firstname' value={inputs.father_firstname || ""} onChange={handleInputChange} className='input' placeholder="Father's First Name + middlenames" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="father_lastname">Father's Last Name </label>
                                <input id='father_lastname' type="text" name='father_lastname' value={inputs.father_lastname || ""} onChange={handleInputChange} className='input' placeholder="Father's Last Name" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="father_phone">Father Phone Number </label>
                                <input id='father_phone' type="phone" name='father_phone' value={inputs.father_phone || ""} onChange={handleInputChange} className='input' placeholder="Father's Phone Number" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="father_email">Father Email <span className="grey smaller_ft">(not required)</span> </label>
                                <input id='father_email' type="email" name='father_email' value={inputs.father_email || ""} onChange={handleInputChange} className='input' placeholder="Father's Email" />
                            </div>

                            <div className="form-div">
                                <label htmlFor="mother_firstname">Mother's First Name </label>
                                <input id='mother_firstname' type="text" name='mother_firstname' value={inputs.mother_firstname || ""} onChange={handleInputChange} className='input' placeholder="Mother's First Name + middlenames" />
                            </div>
                            <div className="form-div">
                                <label htmlFor="mother_lastname">Mother's Last Name </label>
                                <input id='mother_lastname' type="text" name='mother_lastname' value={inputs.mother_lastname || ""} onChange={handleInputChange} className='input' placeholder="Mother's Last Name" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="mother_phone">Mother's Phone Number </label>
                                <input id='mother_phone' type="phone" name='mother_phone' value={inputs.mother_phone || ""} onChange={handleInputChange} className='input' placeholder="Mother's Phone Number" required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="mother_email">Mother's Email <span className="grey smaller_ft">(not required)</span> </label>
                                <input id='mother_email' type="email" name='mother_email' value={inputs.mother_email || ""} onChange={handleInputChange} className='input' placeholder="Mother's Email" required />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>ID Card</legend>
                        <div className="grid">
                            <div className="form-div">
                                <label htmlFor="id_number">ID Number</label>
                                <input id='id_number' type="text" name='id_number' value={inputs.id_number || ""} onChange={handleInputChange} className='input' placeholder="ID Number" minLength={2} maxLength={25} required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_issued">Date of Issue</label>
                                <input id='id_issued' type="date" name='id_issued' value={inputs.id_issued || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_expiry">Date of Expiry</label>
                                <input id='id_expiry' type="date" name='id_expiry' value={inputs.id_expiry || ""} onChange={handleInputChange} className='input' required />
                            </div>
                            <div className="form-div">
                                <label htmlFor="id_photo">Upload ID Photo</label>
                                <input id='id_photo' type="file" name='id_photo' onChange={handleFileChange} className='none' data-id="id_display" accept='.png, .jpg, .jpeg' />
                                <button type="button" className="file_button button" onClick={idUpload}>Click to Upload</button>
                                <div id='id_display' className='img_preview'>
                                    {student.id_image && <img src={ID_URL + student.id_image} alt="" />}
                                </div>
                                <canvas ref={idCanvasRef} className={showCanvas.id_photo ? '' : 'none'}></canvas>
                            </div>
                            <div className="form-div">
                                <label htmlFor="profile_photo">Passport Size Photo</label>
                                <input id='profile_photo' type="file" name='profile_photo' onChange={handleFileChange} className='none' data-id="profile_display" accept='.png, .jpg, .jpeg' />
                                <button type='button' className="file_button button" onClick={profileUpload}>Click to Upload</button>
                                <div id='profile_display' className='img_preview'>
                                    {student.image && <img src={IMAGE_URL + student.image} alt="" />}
                                </div>
                                <canvas ref={profileCanvasRef} className={showCanvas.profile_photo ? '' : 'none'}></canvas>
                            </div>
                        </div>
                    </fieldset>

                    <div className="form-div center">
                        <button type='submit' className='submit'>{editing ? "Save" : "Add Student"}</button>
                    </div>
                </form>
            </div>

            <ToastContainer />
            {isUploading && <Loading />}
            <CropModal src={imgSrc} type={cropType} canvasRef={canvasRef} />
        </div>
    )
}
