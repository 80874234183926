import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { useGlobalContext } from '../AppContext'

export default function Class({ classItem, openEditor }) {

  const { setClassInfo } = useGlobalContext() //very IMPORTANT!!

  const teacher = classItem.teacher ? classItem.teacher : "Not Assigned"

  const changeClassInfo = () => {
    setClassInfo(classItem)
  }

  return (
    <tr className='user_item'>

      <td>
        <span className='bolder'>{classItem.name}</span>
      </td>
      <td>
        <span>{teacher}</span>
      </td>
      <td>
        <span>{classItem.preceding_class}</span>
      </td>

      <td>
        <div className="flex_space">
          <span>
            <Link to={classItem.id}>
              <button className='action_btn button' onClick={changeClassInfo}>View</button>
            </Link>
          </span>
          <span onClick={() => openEditor(classItem.id, classItem.name)}>
            <FontAwesomeIcon icon={faPenToSquare} size="lg" />
          </span>
          <span className='none'><FontAwesomeIcon icon={faTrashCan} size="lg" /></span>
        </div>

      </td>
    </tr>
  )
}
