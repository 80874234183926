import React from 'react'

export default function Footer() {
    const style = {
        backgroundColor: "white",
        padding: "20px"
    }
    return (
        <footer className='center' style={style}>
            <span>CopyRight &#169; {new Date().getFullYear()} </span>
            <a href="https://heliosgh.com/" target={"_blank"} rel="noreferrer">
                Helios Software Technologies.
            </a>
            <span> All Rights Reserved</span>
        </footer>
    )
}
