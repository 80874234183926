export var imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
export var attachmentTypes = ['image/png', 'image/jpeg', 'image/jpg','application/pdf'];
export var excelTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
export var schoolTerms = [
  'Term 1',
  'Term 2',
  'Term 3',
  'All Terms'
];
export var schoolTerms2 = [
  'Term 1',
  'Term 2',
  'Term 3'
];
const month2 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const post_fix_exclusions = [1, 2, 3, 31];
const post_fix = { 1: "st", 2: "nd", 3: "rd", 31: "st" };

const isValidPhoneNumber = (number) => {
  if (isNaN(number)) return false
  if (number.length !== 10) return false
  return true
}

const convertPhoneNumber = (number) => {
  number = parseInt(number).toString()//strip first 0
  // if(number.length === 10){
  //   num = number.substring(1)
  // }
  number = "233"+number
  // console.log(number)
  return number
}

export function uniqid(num) {
  let domain = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"; 
  let length = domain.length
  let string = ""

  for (let i = 0; i < num; i++) {
    let index = Math.floor(Math.random() * length)
    string += domain.charAt(index)
  }
  return string
}

// Create our number formatter.
export const formatter = new Intl.NumberFormat('en-GH', {
  style: 'currency',
  currency: 'GHS',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const readImage = (file, displayId) => {
  var reader = new FileReader();
  reader.addEventListener("load", function (e) {

    var image = new Image();
    image.onload = function () {
      let img = document.createElement('img');
      img.className = "display_img"
      //  img.src = this.src
      document.getElementById(displayId).replaceChildren();
      document.getElementById(displayId).appendChild(img);
    }
    image.src = reader.result;

  })
  reader.readAsDataURL(file);
}

export function isCanvasBlank(canvas) {
  const context = canvas.getContext('2d');

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );

  return !pixelBuffer.some(color => color !== 0);
}

export function getProcessedDate(date) {
  const d = new Date(date)
  let pf = "th"
  if (post_fix_exclusions.indexOf(d.getDate()) !== -1) {
    pf = post_fix[d.getDate()]
  }
  return d.getDate() + pf + ' ' + month[d.getUTCMonth()] + ', ' + d.getFullYear()
}

export function getProcessedDate2(date) {
  const d = new Date(date)
  let pf = "th"
  if (post_fix_exclusions.indexOf(d.getDate()) !== -1) {
    pf = post_fix[d.getDate()]
  }
  return d.getDate() + pf + ' ' + month2[d.getUTCMonth()] + ', ' + d.getFullYear()
}

export function getDate() {
  const d = new Date()
  let pf = "th"
  if (post_fix_exclusions.indexOf(d.getDate()) !== -1) {
    pf = post_fix[d.getDate()]
  }
  return d.getDate() + pf + ' ' + month[d.getUTCMonth()] + ', ' + d.getFullYear()
}

export function getAge(date) {
  const dob = new Date(date)
  //calculate month difference from current date in time  
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format  
  var age_dt = new Date(month_diff);

  //extract year from date      
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user  
  var age = Math.abs(year - 1970);
  return age
}

// A few JavaScript Functions for Images and Files
// Author: Justin Mitchel
// Source: https://kirr.co/ndywes

// Convert a Base64-encoded string to a File object
export function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// Download a Base64-encoded file

export function downloadBase64File(base64Data, filename) {
  var element = document.createElement('a')
  element.setAttribute('href', base64Data)
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
}

// Base64 Image to Canvas with a Crop
export function image64toCanvasRef(canvasRef, image64, pixelCrop, percentCrop) {
  //scale down canvas dimens
  const newDimens = scaleCanvas(pixelCrop.width, pixelCrop.height)

  const canvas = canvasRef // document.createElement('canvas');
  canvas.width = newDimens.width
  canvas.height = newDimens.height
  const ctx = canvas.getContext('2d')
  const image = new Image()
  image.src = image64
  image.onload = function () {
    // console.log(image.width, image.height)
    const startX = Math.floor((percentCrop.x / 100) * image.width)
    const startY = Math.floor((percentCrop.y / 100) * image.height)
    const widthToCrop = Math.floor((percentCrop.width / 100) * image.width)
    const heightToCrop = Math.floor((percentCrop.height / 100) * image.height)
    ctx.drawImage(
      image,
      startX,
      startY,
      widthToCrop,
      heightToCrop,
      0,
      0,
      newDimens.width,
      newDimens.height
    )
  }
}

function scaleCanvas(w, h) {
  const maxWidth = 200, maxHeight = 200
  const ratio = w / h
  let newWidth = w, newHeight = h
  if (w > maxWidth) {// max width 300px
    newWidth = maxWidth
    newHeight = newWidth / ratio
  }
  if (h > maxHeight) {// max height 300px
    newHeight = maxHeight
    newWidth = newHeight * ratio
  }
  return { width: newWidth, height: newHeight }
}
export { isValidPhoneNumber, readImage, convertPhoneNumber }