import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import MiniLoading from '../utils/MiniLoading'
import { getProcessedDate, getAge } from '../../functions'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { SERVER_URL, APP_NAME, IMAGE_URL, ID_URL } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPerson, faPersonDress } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { useGlobalContext } from '../../AppContext'

export default function ViewStudent() {

    const { id, isarchive } = useParams()
    if (id === undefined) window.history.back()

    const { appState, setStudentType } = useGlobalContext() //very IMPORTANT!!


    const [isLoading, setIsLoading] = useState(true)
    const [student, setStudent] = useState({})
    const [typeRun, setTypeRun] = useState(false)// this is all to beat react warning of useeffect dependency

    const isArchive = appState.studentType !== "student" ? true : false

    let dateText = ""
    if (isArchive) {
        dateText = appState.studentType === "archive" ? "Archived" : "Graduated"
    }

    useEffect(() => {
        if (isarchive !== undefined && !typeRun) {
            setTypeRun(true)// this is all to beat react warning of useeffect dependency
            if (isarchive === "a") {
                setStudentType("archive")
            } else
                setStudentType("graduate")
        }

        // console.log(appState.studentType)
        const url = SERVER_URL + "students.php"
        const fetchStudents = () => {
            //if its archived student, set param to true else nothing

            axios.get(url, {
                params: {
                    loadStudentInfo: id,
                    studentType: appState.studentType
                }
            })
                .then(function (response) {
                    setIsLoading(false)
                    if (response.data.response) {
                        let students = JSON.parse(response.data.students)
                        // console.log(students) 
                        if (students.length === 0) {
                            setStudent({})
                            toast.info("No student in database", {
                                hideProgressBar: true,
                                theme: "colored"
                            })
                            return
                        }
                        setStudent(students[0])
                        document.title = `${APP_NAME} | ${students[0].firstname} ${students[0].lastname}`
                        // console.log(Object.keys(student).length)

                    } else {
                        toast.info(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.log(error)
                    toast.error("An Error Occured. Couldn't retrieve student", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                });

        }

        fetchStudents()
    }, [id, appState.studentType, isarchive, setStudentType, typeRun]);

    return (
        <div>
            <h1>{student.firstname ? `${student.firstname} ${student.lastname}` : "View Student"}</h1>
            <div className="divider"></div>

            <div className="content">

                {isLoading && <MiniLoading />}

                {student.firstname &&
                    <div className="user_page">

                        <div className="user_board">

                            <div className="user_info_section">
                                <div>
                                    <img alt='student' className='user_info_image' src={IMAGE_URL + student.image} />
                                </div>
                                <div className='info_area'>
                                    <div className='user_info_name'>{student.firstname} {student.lastname}</div>
                                    <div className='user_info_age'>
                                        <span className='grey'>Age: {getAge(student.dob)} yrs | </span>
                                        <span className='light_blue'>{student.gender}</span>
                                    </div>
                                    <div className='smaller_ft'>
                                        <b>{student.class}</b>
                                    </div>
                                    <div className='smaller_ft'>
                                        <span className='grey'>Date of Birth:</span> {getProcessedDate(student.dob)}
                                    </div>
                                    <div className='smaller_ft'>
                                        <span className='grey'>Date of Enrolled:</span> {getProcessedDate(student.date_enrolled)}
                                    </div>
                                    {isArchive &&
                                        <div className='smaller_ft'>
                                            <span className='grey'>Date {dateText}:</span> {getProcessedDate(student.date_archived)}
                                        </div>
                                    }
                                    {!isArchive ?
                                        <>
                                            <div>
                                                <span>
                                                    <Link to={`/students/edit/${student.id}`}>
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </Link>
                                                </span>
                                                <span className='none'> <FontAwesomeIcon icon={faTrashCan} /></span>
                                            </div>
                                            <div className="flex_space">
                                                <span>
                                                    <Link to={`/add-record/${student.id}/${student.class_id}`}>
                                                        <button className='action_btn button'>Add Record</button>
                                                    </Link>
                                                </span>
                                                <span>
                                                    <Link to={`/view-record/${student.id}`}>
                                                        <button className='action_btn button'>View Records</button>
                                                    </Link>
                                                </span>

                                            </div>
                                        </>
                                        :
                                        <div className="">
                                            <span>
                                                <Link to={`/view-record/${student.id}`}>
                                                    <button className='action_btn button'>View Records</button>
                                                </Link>
                                            </span>
                                        </div>
                                    }


                                </div>
                            </div>

                            <div className='info_area sub_area'>

                                <div>

                                    <p>
                                        <FontAwesomeIcon icon={faLocationDot} /> <span className='bolder'>Address</span>
                                    </p>
                                    <p>
                                        <span> {student.address}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <FontAwesomeIcon icon={faPerson} /> <span className='bolder'>Father</span>
                                    </p>
                                    <p>
                                        {student.father_firstname} {student.father_lastname} | {student.father_phone} | {student.father_email}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <FontAwesomeIcon icon={faPersonDress} /> <span className='bolder'>Mother</span>
                                    </p>
                                    <p>
                                        {student.mother_firstname} {student.mother_lastname} | {student.mother_phone} | {student.mother_email}
                                    </p>
                                </div>

                            </div>

                            <div className="divider"></div>

                            <div className="info_area">
                                <div><span className='bolder'>ID</span></div>
                                <div>
                                    <img alt='identification' className='user_id_image' src={ID_URL + student.id_image} />
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>ID Number:</span> {student.id_number}
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>Date of Issue:</span> {getProcessedDate(student.id_issued)}
                                </div>
                                <div className='smaller_ft'>
                                    <span className='grey'>Date of Expiry:</span> {getProcessedDate(student.id_expiry)}
                                </div>
                            </div>


                        </div>

                    </div>
                }


            </div>

            <ToastContainer />
        </div>
    )
}
