import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../AppContext'
import { SERVER_URL } from '../App';
import Loading from './Loading';
import AlertDialog from './utils/AlertDialog';
import SingleStaff from './SingleStaff';
import { BsDownload } from 'react-icons/bs';
import { getProcessedDate2 } from '../functions';

export default function StaffAttendance() {
    const { option } = useParams()

    const { appState } = useGlobalContext() //very IMPORTANT!!
    const [staff, setStaff] = useState([])
    const [inputs, setInputs] = useState({})
    const [date, setDate] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [open, setAlertOpen] = React.useState(false);
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [foundStaff, setFoundStaff] = useState([])// secondary staff array, to help with search
    const [query, setQuery] = useState("")

    const searchOnChange = (text) => {
        setQuery(text)
        search(text)
    }

    const search = (text) => {

        if (text === "" || staff.length === 0) {// no need to search
            setFoundStaff(staff)
            return
        }

        const foundStaff = staff.filter((student) => {
            const name = student.firstname + " " + student.lastname
            return name.toLowerCase().includes(text.toLowerCase())
        })
        setFoundStaff(foundStaff)
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setInputs(values => ({ ...values, [name]: value }))
        if (!value) document.getElementById("select_all").checked = false

    }

    const selectAllStaff = useCallback(() => {
        let inputs = {}
        try {
            let checkedState = document.getElementById("select_all").checked
            staff.forEach((student) => {
                inputs = { ...inputs, [student.id]: checkedState }
            })
            setInputs(inputs)

            var checkboxes = document.getElementsByClassName("checkbox");
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = checkedState;
            }
        } catch (error) {
            // console.log(error)
        }


    }, [staff])

    const submit = (e) => {
        e.preventDefault()
        if (staff.length === 0) return

        if (!date) {
            toast.error("Please select the attendance date", {
                hideProgressBar: true,
                theme: "colored"
            })
            return
        }

        //confirm
        setAlertTitle("Confirm Staff Attendance and Date")
        setAlertMessage("Are you sure you checked all the right staff and selected the right date? This action cannot be undone")
        setAlertOpen(true);
        //submit in alert handleclose


    }

    const viewSubmit = (e) => {
        e.preventDefault()
        if (!date) {
            return
        }

        setIsLoading(true)
        const url = SERVER_URL + "attendance.php"
        axios.get(url, {
            params: {
                getStaffAttendance: "",
                date: date
            }
        })
            .then(function (response) {
                setIsLoading(false)
                if (response.data.response) {
                    let staff = JSON.parse(response.data.staff)
                    if (staff.length === 0) {
                        toast.info("No staff match your search", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                        return
                    }
                    setStaff(staff)
                    setFoundStaff(staff)

                } else {
                    toast.info(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                setIsLoading(false)
                console.log(error)
                toast.error("An Error Occured. Couldn't retrieve staff", {
                    hideProgressBar: true,
                    theme: "colored"
                })
            });

    }

    const submitSearch = (e) => {
        e.preventDefault()
        search(query)
    }

    const handleClose = (option) => {
        //if there are multiple alert scenarios, will have to use another variable to determine action
        setAlertOpen(false);
        if (option) {
            setIsUploading(true)

            const formData = new FormData()
            formData.append("setStaffAttendance", "");
            formData.append("date", date);
            formData.append("staff", JSON.stringify(inputs));

            const url = SERVER_URL + "attendance.php"
            axios.post(url, formData)
                .then(function (response) {
                    //hide loading spinner
                    setIsUploading(false)
                    if (response.data.response) {
                        toast.success("Attendance Saved", {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    } else {
                        toast.error(response.data.message, {
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }

                })
                .catch(function (error) {
                    //hide loading spinner
                    setIsUploading(false)
                    toast.error("An Error Occured", {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                    console.log(error);
                });
        }
    };

    const dateChanged = (val) => {
        setDate(val)
        setStaff([])
        setFoundStaff([])
    }

    useEffect(() => {
        if (appState.staff.length === 0) {
            window.history.back()
            return
        }

        // let today = new Date()
        // setDate(today.toISOString().split("T")[0])

        if (appState.staff.length > 0 && option === 'set') {
            //will throw an error when selecting staff check boxes
            setStaff(appState.staff)
            setFoundStaff(appState.staff)
            selectAllStaff()
        }
    }, [appState, selectAllStaff, option]);


    if (option === 'set') {
        return (
            <div>
                <h1>Staff Attendance</h1>
                <div className="divider"></div>

                <form action="" id="form" onSubmit={submit}>
                    <div className="grid">
                        <div className="form-div">
                            <label htmlFor="date">Attendance Date</label>
                            <input id='date' type="date" name='date' value={date} onChange={e => setDate(e.target.value)} className='input' required />
                        </div>
                        <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                            <button type='submit' className='submit'>Submit Attendance</button>
                        </div>
                    </div>

                </form>

                <div className="content">
                    <div className="user_list">

                        <p>
                            Mark staff as present by ticking the checkbox beside them
                        </p>
                        {staff.length > 0 &&
                            <div>
                                <form action="" className="" onSubmit={submitSearch}>
                                    <input type="search" className='input' placeholder='Search Staff' value={query} onChange={e => searchOnChange(e.target.value)} />
                                    <button type='submit' className='submit'>Search</button>
                                </form>
                            </div>
                        }
                        <table className='user_list_table' style={{ width: "auto" }}>
                            <tbody>
                                {staff.length > 0 &&
                                    <tr>
                                        <th> <input id='select_all' type="checkbox" defaultChecked onChange={selectAllStaff} /> Select All</th>
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                    </tr>
                                }

                                {foundStaff.map((staffItem) => {

                                    return <SingleStaff key={staffItem.id} staff={staffItem} isAttendance={true} inputs={inputs}
                                        handleInputChange={handleInputChange} />
                                })}
                            </tbody>

                        </table>

                    </div>
                </div>

                {isUploading && <Loading />}
                <AlertDialog open={open} handleClose={handleClose} title={alertTitle} message={alertMessage} />
            </div>
        )
    }

    if (option === 'view') {
        return (
            <div>
                <h1>View Staff Attendance</h1>
                <div className="divider"></div>

                <form action="" id="form" onSubmit={viewSubmit}>
                    <div className="grid">
                        <div className="form-div">
                            <label htmlFor="date">Select Date</label>
                            <input id='date' type="date" name='date' value={date} onChange={e => dateChanged(e.target.value)} className='input' required />
                        </div>
                        <div className="form-div" style={{ display: "flex", alignItems: 'flex-end' }}>
                            <button type='submit' className='submit'>Search</button>
                        </div>
                    </div>

                </form>


                <div className="content">
                    <div className="user_list">

                        <p>
                            {date && <span>Attendance on <b>{getProcessedDate2(date)}</b></span>}
                        </p>
                        {staff.length > 0 &&
                            <div className='top'>
                                <form action="" className="" onSubmit={submitSearch}>
                                    <input type="search" className='input' placeholder='Search Staff' value={query} onChange={e => searchOnChange(e.target.value)} />
                                    <button type='submit' className='submit'>Search</button>
                                </form>

                                <a href={`http://localhost/api/reports/staff_attendance.php?date=${date}`} target={'_blank'} rel={'noreferrer'}>
                                    <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
                                </a>
                            </div>
                        }

                        <table className='user_list_table' style={{ width: "auto" }}>
                            <tbody>
                                {staff.length > 0 &&
                                    <tr>
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Present</th>

                                    </tr>
                                }

                                {foundStaff.map((student) => {
                                    return <SingleStaff key={student.id} staff={student} viewAttendance={true} />
                                })}
                            </tbody>

                        </table>

                    </div>
                </div>

                {isLoading && <Loading />}
            </div>
        )
    }

}
