import React, { useState, useEffect } from 'react'
import MiniLoading from './utils/MiniLoading'
import axios from 'axios'
import { toast } from 'react-toastify';
import { SERVER_URL, APP_NAME } from '../App'
import Student from './Student';
import SingleStaff from './SingleStaff';
import { useGlobalContext } from '../AppContext';
import { BsDownload } from 'react-icons/bs';

export default function Archive() {
  document.title = APP_NAME + " | Archive"

  const { setStudentType } = useGlobalContext() //very IMPORTANT!!

  const [tab, setTab] = useState(1) //tabs
  const [query, setQuery] = useState("")
  const [query_staff, setQueryStaff] = useState("")
  const [query_graduates, setQueryGraduates] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [students, setStudents] = useState([])
  const [staff, setStaff] = useState([])
  const [graduates, setGraduates] = useState([])
  const [schoolYears, setSchoolYears] = useState([]) 
  const [foundStudents, setfoundStudents] = useState([])// secondary students array, to help with search
  const [foundStaff, setfoundStaff] = useState([])//
  const [foundGraduates, setfoundGraduates] = useState([])//
  const [gradSchoolYear, setGradSchoolYear] = useState("") //year students are graduating

  const studentSearchOnChange = (text) => {
    setQuery(text)
    studentSearch(text)
  }

  const staffSearchOnChange = (text) => {
    setQueryStaff(text)
    staffSearch(text)
  }

  const gradSearchOnChange = (text) => {
    setQueryGraduates(text)
    gradSearch(text)
  }

  const studentSearch = (text) => {
    // console.log("search submit "+text)

    if (text === "" || students.length === 0) {// no need to search
      setfoundStudents(students.slice(0, 10)) // show 10 max
      return
    }

    const foundStudents = students.filter((student) => {
      const name = student.firstname + " " + student.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setfoundStudents(foundStudents)
  }

  const staffSearch = (text) => {

    if (text === "" || staff.length === 0) {// no need to search
      setfoundStaff(staff.slice(0, 10)) // show 10 max
      return
    }

    const foundStaff = staff.filter((staff) => {
      const name = staff.firstname + " " + staff.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setfoundStaff(foundStaff)
  }

  const gradSearch = (text) => {

    if (text === "" || graduates.length === 0) {// no need to search
      setfoundGraduates(graduates.slice(0, 10)) // show 10 max
      return
    }

    const foundGraduates = graduates.filter((graduate) => {
      const name = graduate.firstname + " " + graduate.lastname
      return name.toLowerCase().includes(text.toLowerCase())
    })
    setfoundGraduates(foundGraduates)
  }

  const studentSubmit = (e) => {
    e.preventDefault()
    studentSearch(query)
  }

  const staffSubmit = (e) => {
    e.preventDefault()
    staffSearch(query)
  }

  const gradSubmit = (e) => {
    e.preventDefault()
    gradSearch(query)
  }

  const gradSchoolFilterChange = (e) => {
    setGradSchoolYear(e.target.value)
    if (e.target.value === "" || graduates.length === 0) {// no need to search
      setfoundGraduates(graduates.slice(0, 10)) // show 10 max
      return
    }

    const foundGraduates = graduates.filter((graduate) => {
      return graduate.graduate_year === e.target.value
    })
    setfoundGraduates(foundGraduates)
  }

  useEffect(() => {
    // setStudentType("archive") // archive type by default

    const url = SERVER_URL + "archive.php"
    const fetchArchive = () => {
      axios.get(url, {
        params: {
          loadArchiveStudents: "loadArchiveStudents",
          loadArchiveStaff: "loadArchiveStaff",
          loadGraduates: "loadGraduates"
        }
      })
        .then(function (response) {
          setIsLoading(false)
          if (response.data.response) {
            let students = JSON.parse(response.data.students)
            let staff = JSON.parse(response.data.staff)
            let graduates = JSON.parse(response.data.graduates)
            let schoolYears = JSON.parse(response.data.schoolYears)
            // console.log(students) 
            if (students.length === 0) {
              setStudents([])
              setfoundStudents([])
              // toast.info("No students in archive", {
              //   hideProgressBar: true,
              //   theme: "colored"
              // })

            }
            if (staff.length === 0) {
              setStaff([])
              setfoundStaff([])
              // toast.info("No staff in archive", {
              //   hideProgressBar: true,
              //   theme: "colored"
              // })

            }
            setStudents(students)
            setfoundStudents(students.slice(0, 10))//only show 10 on load
            setStaff(staff)
            setfoundStaff(staff.slice(0, 10))//only show 10 on load
            setGraduates(graduates)
            setfoundGraduates(graduates.slice(0, 10))//only show 10 on load
            setSchoolYears(schoolYears)

          } else {

          }

        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          toast.error("An Error Occured. Couldn't retrieve data", {
            hideProgressBar: true,
            theme: "colored"
          })
        });

    }

    fetchArchive()
  }, []);

  const switchTab = (num) => {
    setTab(num)
    if (num === 3)
      setStudentType("graduate")
    else
      setStudentType("archive")
  }
  const tabContentStyle = {
    padding: "10px 0"
  }

  return (
    <div>
      <h1>Archive</h1>
      <div className="divider"></div>

      <div className="content">
        <div className="tabs">
          <button className={tab === 1 ? "tab active" : "tab"} onClick={() => switchTab(1)}>Students</button>
          <button className={tab === 2 ? "tab active" : "tab"} onClick={() => switchTab(2)}>Staff</button>
          <button className={tab === 3 ? "tab active" : "tab"} onClick={() => switchTab(3)}>Graduates</button>
        </div>

        <div className={tab === 1 ? "tab_content" : "tab_content none"} style={tabContentStyle}>

          <div className="top">

            <form action="" className="" onSubmit={studentSubmit}>
              <input type="search" className='input' placeholder='Search Students' value={query} onChange={e => studentSearchOnChange(e.target.value)} />
              <button type='submit' className='submit'>Search</button>
            </form>

            {students.length > 0 &&
              <a href="http://localhost/api/reports/students.php?archive">
                <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
              </a>
            }
          </div>


          <div className="user_list">

            {students.length > 0 && <h4 className='grey'>{students.length} {students.length === 1 ? "Student" : "Students"} in Archive</h4>}

            <table className='user_list_table'>
              <tbody>
                {students.length > 0 &&
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Last Class</th>
                    <th>Date Enrolled</th>
                    {/* <th>Operation</th>  */}
                    <th>Actions</th>
                  </tr>
                }

                {foundStudents.map((student) => {
                  return <Student key={student.id} student={student} isArchive={true} />
                })}
                {/* <Student/> */}
              </tbody>

            </table>
          </div>

        </div>

        <div className={tab === 2 ? "tab_content" : "tab_content none"} style={tabContentStyle}>

          <div className="top">
            <form action="" className="" onSubmit={staffSubmit}>
              <input type="search" className='input' placeholder='Search Staff' value={query_staff} onChange={e => staffSearchOnChange(e.target.value)} />
              <button type='submit' className='submit'>Search</button>
            </form>

            {staff.length > 0 &&
              <a href="http://localhost/api/reports/staff.php?archive">
                <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
              </a>
            }

          </div>


          <div className="user_list">

            {staff.length > 0 && <h4 className='grey'>{staff.length} Staff in Archive</h4>}

            <table className='user_list_table'>
              <tbody>
                {staff.length > 0 &&
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Age</th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Date Employed</th>
                    <th>Action</th>
                  </tr>
                }
                {foundStaff.map((single_staff) => {
                  return <SingleStaff key={single_staff.id} staff={single_staff} isArchive={true} />
                })}
              </tbody>

            </table>
          </div>
        </div>

        <div className={tab === 3 ? "tab_content" : "tab_content none"} style={tabContentStyle}>

          <div className="top">
            <form action="" className="" onSubmit={gradSubmit}>
              <input type="search" className='input' placeholder='Search Graduates' value={query_graduates} onChange={e => gradSearchOnChange(e.target.value)} />
              <button type='submit' className='submit'>Search</button>
              <div className='form-div'>
                <select id='grad_school_year' name='grad_school_year' value={gradSchoolYear} onChange={gradSchoolFilterChange}
                  className='input'>
                    <option value=''>--Filter by Graduation Year--</option>
                    {schoolYears.map((item) => {
                        const { id, year } = item
                        return <option key={id} value={id}>{year}</option>
                    })}
                </select>
              </div>
            </form>

            {graduates.length > 0 &&
              <a href="http://localhost/api/reports/students.php?grads">
                <button type='button' className='button exl_btn'><BsDownload /> Excel Download</button>
              </a>
            }
          </div>


          <div className="user_list">

            {graduates.length > 0 && <h4 className='grey'>
              {graduates.length} Total {graduates.length === 1 ? "Graduate" : "Graduates"}
              <span className="smaller_ft"> (showing {foundGraduates.length})</span>
              </h4>}

            <table className='user_list_table'>
              <tbody>
                {graduates.length > 0 &&
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Graduate (Year)</th>
                    <th>Date Enrolled</th>
                    <th>Actions</th>
                  </tr>
                }

                {foundGraduates.map((student) => {
                  return <Student key={student.id} student={student} isGraduate={true} />
                })}
                {/* <Student/> */}
              </tbody>

            </table>
          </div>

        </div>

        {isLoading && <MiniLoading />}

      </div>

    </div>
  )
}
