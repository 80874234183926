import React, {useState, useEffect} from 'react'
import '../css/dashboard.css'
// import { useGlobalContext } from '../AppContext'
import { toast } from 'react-toastify';
import axios from 'axios'
import {SERVER_URL, APP_NAME} from '../App'
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChildren, faChild, faChildDress, faPerson,
   faPersonDress, faSchool, faFileLines, faUserGraduate } from '@fortawesome/free-solid-svg-icons'

// var colors = [
//   '#16a085',
//   '#27ae60',
//   '#2c3e50',
//   '#f39c12',
//   '#e74c3c',
//   '#9b59b6',
//   '#FB6964',
//   '#342224',
//   '#472E32',
//   '#BDBB99',
//   '#77B1A9',
//   '#73A857'
// ];

// var colors = [
//   "#1abc9c",
//   "#2ecc71",
//   "#3498db",
//   "#9b59b6",
//   "#34495e",
//   "#e67e22",
//   "#e74c3c",
//   "#f39c12",
//   "#6F1E51",
//   "#ED4C67",
//   "#009432",
//   "#EE5A24",
//   "#EA2027",
//   "#A3CB38",
//   "#1289A7"
// ]

// var cl = [
//   {1:"#1abc9c",2:"#58c0ab"},
//   {1:"#2ecc71",2:"#74ca98"},
//   {1:"#3498db",2:"#7db5db"},
//   {1:"#9b59b6",2:"#c797da"},
//   {1:"#34495e",2:"#758ba1"},
//   {1:"#e67e22",2:"#e2a875"},
//   {1:"#e74c3c",2:"#ec958c"},
//   {1:"#f39c12",2:"#f0c684"},
//   {1:"#6F1E51",2:"#a05b87"},
//   {1:"#ED4C67",2:"#f1a0ad"},
//   {1:"#009432",2:"#3db364"},
//   {1:"#EE5A24",2:"#f7a182"},
//   {1:"#EA2027",2:"#f5898d"},
//   {1:"#A3CB38",2:"#b8cf78"},
//   {1:"#1289A7",2:"#87d1e4"},
// ]

var cl = [
  {1:"#3FCAAE",2:"#12846D"},
  {1:"#52D689",2:"#26A35A"},
  {1:"#59ABE2",2:"#2A7AAF"},
  {1:"#B783CB",2:"#7C4791"},
  {1:"#8A9DB0",2:"#34495e"},
  {1:"#EB964B",2:"#B8641C"},
  {1:"#F18A7F",2:"#e74c3c"},
  {1:"#F5AF3F",2:"#C27D0E"},
  {1:"#B977A1",2:"#6F1E51"},
  {1:"#F37F92",2:"#BE3D52"},
  {1:"#55C27A",2:"#009432"},
  {1:"#F59370",2:"#EE5A24"},
  {1:"#F68F92",2:"#EA2027"},
  {1:"#B5D65B",2:"#738F28"},
  {1:"#5EB4C9",2:"#0F6D85"},
]

// var gradientColor = "#c9c9da"

export default function Dashboard() {
  document.title = APP_NAME+" | Dashboard"

  // const {appState} = useGlobalContext()
  const [isLoading, setIsLoading] = useState(true)
  const [dashboard, setDashboard] = useState({})
  const [prevHeight, setPrevHeight] = useState(0)

  const setColors = () => {
    let prevColors = []
    var cards = document.getElementsByClassName("card");
    for (var i = 0; i < cards.length; i++) {
      let x = Math.floor(Math.random() * cl.length);
      //colors should be more than cards, else infinite loop
      while(prevColors.includes(x)){
        x = Math.floor(Math.random() * cl.length);
      }
      prevColors.push(x)
      // cards[i].style.backgroundColor = colors[x]
      // let gradient = `linear-gradient(to bottom right, ${colors[x]} 0%, ${colors[x]} 75%,  ${gradientColor} 100%)`
      let gradient = `linear-gradient(to bottom left,  ${cl[x][2]} 5%,  ${cl[x][1]} 85%)`
      cards[i].style.background = gradient
    } 
  }

  useEffect(() => {
    const sizeChange = () => {
      // console.log(window.innerWidth, window.innerHeight)
      // let i = Math.floor(Math.random() * colors.length);
      // let color = colors[i]
      // document.getElementById("dashboard").style.backgroundColor = color
      let height = window.innerHeight - 40
      // console.log(height, prevHeight)
      if(height > prevHeight){
        document.getElementById("dashboard").style.height = height+"px"
        setPrevHeight(height)
        return
      }
      // document.getElementById("dashboard").style.height = prevHeight+"px"
        
    }
    sizeChange()

    window.addEventListener("resize", sizeChange)
  
    return () =>{
      window.removeEventListener("resize", sizeChange)
    }
  }, [prevHeight]);

  useEffect(() => {
    setColors()
    

    const fetchDashboard = () => {
      const url = SERVER_URL+"dashboard.php"
      axios.get(url, {
        params: {
          loadDashboard: "loadDashboard"
        }
      })
      .then(function (response) {
        setIsLoading(false)
        if(response.data.response){
          let dashboard = JSON.parse(response.data.dashboard)
          setDashboard(dashboard)
          
        }else{ 
          
        }
        
      })
      .catch(function (error) {
        setIsLoading(false)
        console.log(error)
        toast.error("An Error Occured. Couldn't retrieve info",{
            hideProgressBar: true,
            theme: "colored"
        })
      });
    
    }
    fetchDashboard()

    
  }, []);
  // console.log(appState.adminInfo.id) 

  return (
    <>      
      {isLoading && <Loading/>}
      
      <div id='dashboard'>
        
        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.students || 0}</div>
              <div className='label'>Total Students</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faChildren} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.boys || 0}</div>
              <div className='label'>Boys</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faChild} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.girls || 0}</div>
              <div className='label'>Girls</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faChildDress} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.staff || 0}</div>
              <div className='label'>Total Staff</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faPerson} size="2xl"/> <FontAwesomeIcon icon={faPersonDress} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.male_staff || 0}</div>
              <div className='label'>Male Staff</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faPerson} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.female_staff || 0}</div>
              <div className='label'>Female Staff</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faPersonDress} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.classes || 0}</div>
              <div className='label'>Classes</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faSchool} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.subjects || 0}</div>
              <div className='label'>Subjects</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faFileLines} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

        <div>
          <div className="card">
            <div className='metric'>
              <div className='count'>{dashboard.graduates || 0}</div>
              <div className='label'>Graduates</div>
            </div>
            <div className='icons'>
              <div><FontAwesomeIcon icon={faUserGraduate} size="2xl"/> </div>
            </div>
          </div>
        </div>{/* Grid Cell */}

      </div>
    </>
  )
}
