import React from 'react'

export default function Admin({ admin, count, resetPass }) {
    return (
        <tr className='user_item'>
            <td>{count + 1}</td>
            <td>{admin.firstname} {admin.lastname}</td>
            <td className='grey'>{admin.username}</td>
            <td>
                <button className='action_btn button' onClick={() => resetPass(admin)}>Reset Password</button>
            </td>
        </tr>
    )
}
