import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../AppContext'
import logo from '../logos/black.png'
import '../css/form.css'
import axios from 'axios'
import { toast } from 'react-toastify';
import { SERVER_URL, APP_NAME } from '../App';

export default function Login() {
    const { setLoggedIn, setAdminInfo } = useGlobalContext()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [newpassword, setNewPassword] = useState("")
    const [confirm, setConfirm] = useState("")
    const [reset, setReset] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    const login = (e) => {
        e.preventDefault()
        if (!username || !password) return

        if ((reset) && (!newpassword || !confirm)) return

        if (newpassword !== confirm) {
            toast.error("Passwords do not match", {
                theme: "colored"
            })
            return
        }

        setIsUploading(true)
        const url = SERVER_URL + "login.php"
        const formData = new FormData()
        formData.append("username", username)
        formData.append("password", password)
        if (reset) {
            formData.append("newpassword", newpassword)
        }

        axios.post(url, formData)
            .then(function (response) {
                //hide loading spinner
                setIsUploading(false)
                if (response.data.response) {
                    let admin = JSON.parse(response.data.admin)
                    if (admin.reset === 'yes') {
                        setReset(true)
                        toast.info("Change Password", {
                            theme: "colored"
                        })
                    } else {
                        setAdminInfo(admin)
                        setLoggedIn(true)
                    }


                } else {
                    toast.error(response.data.message, {
                        hideProgressBar: true,
                        theme: "colored"
                    })
                }

            })
            .catch(function (error) {
                //hide loading spinner
                setIsUploading(false)
                toast.error("An Error Occured", {
                    hideProgressBar: true,
                    theme: "colored"
                })
                console.log(error);
            });


    }

    useEffect(() => {
        //inputs only required if password must be changed
        var inputs = document.getElementsByClassName("newpass");
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].required = reset
        }

    }, [reset]);

    return (
        <div className='flex-body'>

            <form action="" className='center-form' onSubmit={login}>
                <div style={{ textAlign: "center" }}>
                    <img src={logo} alt="" className="logo" />
                </div>
                <h3 className='center'>{APP_NAME} Application Login</h3>
                <div className="form-div">
                    <label htmlFor="username">Username </label>
                    <input id='username' type="text" value={username} onChange={(e) => setUsername(e.target.value)} className='input' placeholder='Username' required />
                </div>
                <div className="form-div">
                    <label htmlFor="password">Password </label>
                    <input id='password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='input' placeholder='Password' required />
                </div>
                {reset &&
                    <div id='reset_field'>
                        <div className="form-div">
                            <label htmlFor="newpassword">New Password </label>
                            <input id='newpassword' type="password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} className='input newpass' placeholder='New Password' />
                        </div>
                        <div className="form-div">
                            <label htmlFor="confirm">Confirm New Password </label>
                            <input id='confirm' type="password" value={confirm} onChange={(e) => setConfirm(e.target.value)} className='input newpass' placeholder='Confirm New Password' />
                        </div>
                    </div>
                }
                <div className="form-div center">
                    <button type='submit' className='submit'>Login</button>
                    <div className={`form_loader ${isUploading ? '' : 'none'}`}>
                        <div className="form_loading"></div>
                    </div>
                </div>
            </form>

            <div style={{ alignSelf: "end", textAlign: "center" }}>
                <code>
                    <span>Developed By </span>
                    <a href="https://heliosgh.com/" target={"_blank"} rel="noreferrer">
                        Helios Software Technologies
                    </a>
                </code>
            </div>

        </div>
    )
}
